import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import styled from "@mui/material/styles/styled";

import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";

import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import open from "../pictures/open.png";

import { FormProvider, useForm } from "react-hook-form";

import { SignUpCusTxtFld } from "./SignUpCusTxtFld";
import { addUpdCartItem } from "../store/zuCartActions";

import StyledButton from "../shared/StyledButton";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

import StraightSharpIcon from "@mui/icons-material/StraightSharp";
import LaunchSharpIcon from "@mui/icons-material/LaunchSharp";
// import SouthSharpIcon from "@mui/icons-material/SouthSharp";
// import NorthSharpIcon from "@mui/icons-material/NorthSharp";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import IconButton from "@mui/material/IconButton";
import { Image } from "./OverviewIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
function ItemCard(props) {
  const { addon, cart, index, scrollInViewFn, mode } = props;
  const [added, setAdded] = React.useState(false);

  console.log("ItemCard::cart:", cart);
  console.log("ItemCard::addon:", addon);
  console.log(
    "ItemCard::filter",
    cart.filter((field) => field.item_id === addon.item_id)
  );

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "onBlur",
  });

  const AccordionStyle = {
    m: 0,
    p: 0,
    bgcolor: "primary.main",
    borderRadius: "10px 10px 10px 10px",
  };
  const AccordionSumStyle = {
    mx: 0,
    my: 0,
    px: 0.2,
    py: 0,

    flexDirection: "row",
    "& .MuiAccordionSummary-content": {
      mx: 0,
      my: 0,
      px: 0.2,
      py: 0,
    },
  };
  const SumText = {
    typography: "h5",
    color: "textSecondary",
    fontWeight: "medium",
    textAlign: "left",
  };
  const AccordionDtlStyle = {
    m: 0,
    px: 0.2,
    py: 0,
  };
  const DtlText = {
    color: "textPrimary",
    typography: "h6",
    fontWeight: "light",
    textAlign: "left",
  };
  const menuIconSx = {
    m: 0,
    p: 0,
    color: "secondary.main",
    fontSize: "30px",
  };
  // console.log(addons);
  const [expanded, setExpanded] = React.useState(false);

  // const useScroll = () => {
  //   const aoRef = useRef(null);
  //   const executeScrollAO = () =>
  //     aoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

  //   return [executeScrollAO, aoRef];
  // };
  // // console.log(cart);

  // const [executeScrollAO, aoRef] = useScroll();
  // console.log("value:", value);
  const handleExpandClick = () => {
    if (expanded) {
      if (mode === "W") {
        scrollInViewFn();
      }
    }
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    // return <> {...other}</>;
  })(({ theme, expand }) => ({
    // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    // transform: !expand ? "`${...other}` +" : "`${...other}` -",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <Card
      variant="outlined"
      // elevation={0}
      key={index}
      sx={{
        // border: 1,
        bgcolor: "common.compbg",
        justifyContent: "center",
        alignItems: "center",
        m: 0,
        p: 0,
        width: "100%",
        // height: "100%",
        // width: { xs: "100%", sm: mode === "W" ? "100%" : "50%" },

        // border: "solid",
        // borderWidth: "0px",
        // borderColor: "primary.main",
        borderRadius: "20px 20px 20px 20px",
        boxShadow: 3,
      }}
    >
      {/* <Stack
        direction="column"
        sx={{
          m: 0,
          p: 0,
          m: 1,
        }} 
      >*/}
      <Stack
        direction={{
          xs: "column",
          sm: "column",
          md: mode === "W" ? "row" : "column",
        }}
        sx={{
          m: 0,
          mx: 0,

          // justifyContent: { xs: "center", md: "space-around" },
          // justifyContent: { xs: "center", sm: "center" },
          alignItems: "center",

          // border: 1,
        }}
      >
        {/* content with expand       */}
        <Stack
          // useFlexGap
          // direction={{
          //   xs: "column-reverse",
          //   sm: "row",
          // }}
          sx={{
            m: 0,
            width: {
              xs: "100%",
              sm: "100%",
              // md: mode === "W" ? "65%" : "100%",
              alignItems: "center",
            },
            // border: 1,
            // justifyContent: "space-between",
            // bgcolor: "common.compbg",
            // alignItems: "stretch",
            // display: "flex",
            borderRadius: "20px 20px 20px 20px",
            // boxShadow: 3,
          }}
        >
          {/* Service Image */}
          <Stack
            direction="column"
            sx={{
              m: 0,
              p: 0,
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "primary.main",
              width: "100%",
              height: "140px",
              // borderRadius: "50%",
              // border: 1,
              // borderRadius: "20px 20px 20px 20px",
            }}
          >
            <Image
              type={addon.ui_data.content.image}
              primaryColor={theme.palette.secondary.main}
              accentColor={theme.palette.common.secondary2}
              height="120px"
            />
          </Stack>
          {/* Service Heading */}
          <Stack
            direction="row"
            sx={{
              p: 0,
              m: 0,
              mt: 1,
              // border: 1,
              justifyContent: "center",
              height: "45px",
            }}
          >
            <Box
              sx={{
                p: 0,
                m: 0,
                typography: "h4",
                fontWeight: "bold",
                color: "textPrimary",
                textAlign: "center",
              }}
            >
              {addon.ui_data.introline}
            </Box>
          </Stack>
          {/* pricing */}
          <Stack
            direction="column"
            sx={{
              p: 0,
              mb: 0,
              width: {
                xs: "100%",
                sm: "100%",
                // md: mode === "W" ? "35%" : "100%",
              },
              height: { xs: "120px", sm: "120px" },
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px 20px 20px 20px",
              border: 0,
            }}
          >
            <Stack
              direction="row"
              sx={{
                p: 0,
                alignItems: "stretch",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Stack
                sx={{
                  // mb: 2,
                  // mt: -1,
                  py: 0.2,
                  px: 0,
                  my: 0,
                  // width: "60%",
                  // pb: 5,
                  // mx: 20,
                  // border: 1,
                  borderRadius: "20px 20px 20px 20px",
                  bgcolor: "primary.main",
                  justifyContent: "center",
                  alignItems: "center",
                  // display: "flex",
                }}
                direction="row"
              >
                {/* Label */}
                <Stack
                  sx={{
                    m: 0,
                    px: 1,
                    py: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {cart.length > 0 && (
                    <Box
                      sx={{
                        p: 0,
                        m: 0,
                        typography: "h5",
                        fontWeight: "medium",
                        color: "textSecondary",
                      }}
                    >
                      # of{" "}
                      {cart.filter((field) => field.item_id === addon.item_id)
                        .length > 0 ? (
                        <>
                          {
                            cart.filter(
                              (field) => field.item_id === addon.item_id
                            )[0].disp_props.unit
                          }
                        </>
                      ) : (
                        <></>
                      )}
                      {""}:{"  "}
                    </Box>
                  )}
                </Stack>
                <Stack
                  sx={{
                    m: 0,
                    p: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    // borderRadius: "20px 20px 20px 20px",
                  }}
                  direction="row"
                >
                  {!added ? (
                    <StyledButton
                      sx={{
                        borderRadius: "20px 20px 20px 20px",
                        p: 0,
                        m: 0,
                      }}
                      onClick={() => setAdded(true)}
                    >
                      {cart.length > 0 && (
                        <>
                          {cart.filter(
                            (field) => field.item_id === addon.item_id
                          )[0].quantity > 0 ? (
                            <>
                              {
                                cart.filter(
                                  (field) => field.item_id === addon.item_id
                                )[0].quantity
                              }
                            </>
                          ) : (
                            <>Add +</>
                          )}
                        </>
                      )}
                    </StyledButton>
                  ) : (
                    <>
                      {cart
                        .filter(
                          (formfield) => formfield.item_id === addon.item_id
                        )
                        .map((field, j) => (
                          <FormProvider {...methods}>
                            <form>
                              <Stack
                                sx={{
                                  // bgcolor: "common.compbg",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  // border: 1,
                                  py: 0.5,
                                  // width: "80%",
                                }}
                              >
                                <SignUpCusTxtFld
                                  required={
                                    field.ui_data.required == "Y" ? true : false
                                  }
                                  item_id={field.item_id}
                                  allprops={field.ui_data}
                                  value={field.quantity}
                                  validationType={field.ui_data.validationType}
                                  onfieldChg={(data) => {
                                    addUpdCartItem(field.item_id, data, false);
                                    // console.log("cart in SignupStep1:", cart);
                                  }}
                                />
                              </Stack>
                            </form>
                          </FormProvider>
                        ))}
                    </>
                  )}
                </Stack>
              </Stack>
              {mode !== "W" &&
                cart.length > 0 &&
                cart.filter((field) => field.item_id === addon.item_id)[0]
                  .quantity > 0 && (
                  // <Box
                  //   sx={{
                  //     typography: "h4",
                  //     position: "absolute",
                  //     // bottom: 0,
                  //     top: 0,
                  //     right: 0,
                  //     // width: "100%",
                  //     bgcolor: "primary.main",
                  //     color: "secondary.main",
                  //     padding: "0px",
                  //   }}
                  // >
                  <>
                    <Tooltip title="Assign To Users">
                      {/* <Box
                        sx={{
                          typography: "subtitle1",
                          // position: "absolute",
                          // // bottom: 0,
                          // top: 0,
                          // right: 0,
                          // // width: "100%",
                          m: 0.5,
                          bgcolor: "primary.main",
                          color: "secondary.main",
                          // padding: "0px",
                        }}
                      >
                        Assign To Users
                      </Box> */}
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          navigate("/dashboard/svc2usrassign", {
                            state: {
                              title: "Assign Svc -> Users",
                              cart: cart,
                              rowInfo: addon,
                            },
                          });
                        }}
                      >
                        <LaunchSharpIcon
                          // <NorthSharpIcon
                          // color="secondary.main"
                          // fontSize="large"
                          sx={{
                            ...menuIconSx,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
            </Stack>
            <Stack
              direction="column"
              sx={{
                m: 0,
                p: 0,
                pr: 1,
                alignItems: "center",
                // border: 1,
              }}
            >
              {cart
                .filter((cartItem) => cartItem.item_id === addon.item_id)
                .map((cartRow) => (
                  <>
                    {/* <div>{cartRow.quantity}</div> */}
                    <Box
                      sx={{
                        typography: "subtitle1",
                        color: "textSecondary",
                        mx: 0,
                        fontWeight: "medium",
                        textAlign: "center",
                      }}
                    >
                      {cartRow.price_curr}/{cartRow.disp_props.unit}/
                      {cartRow.disp_props.freq}
                      {cartRow.base_price > 0 && (
                        <>
                          {" "}
                          + {cartRow.base_price_curr} Base Fee/
                          {cartRow.disp_props.freq}
                        </>
                      )}
                    </Box>
                    {cartRow.setup_fee > 0 && (
                      <Box
                        sx={{
                          typography: "subtitle1",
                          color: "textSecondary",
                          mx: 0,
                          fontWeight: "medium",
                          textAlign: "center",
                        }}
                      >
                        {cartRow.setup_fee_curr} Setup Fee /{" "}
                        {cartRow.disp_props.unit}/ Onetime
                      </Box>
                    )}

                    {cartRow.subsc_type && (
                      <Box
                        sx={{
                          typography: "caption",
                          color: "textSecondary",
                          mx: 0,
                          fontWeight: "medium",
                          textAlign: "center",
                        }}
                      >
                        ({cartRow.subsc_type})
                      </Box>
                    )}
                  </>
                ))}
            </Stack>
          </Stack>
          {/* Service Summary */}
          <Stack
            direction="column"
            sx={{
              m: 1,
              p: 0,
              alignItems: "center",
              height: mode == "W" ? "100%" : "100px",
              // border: 1,
            }}
          >
            <ExpandMore
              expand={expanded}
              onClick={() => handleExpandClick()}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{
                p: 0,
                m: 0,
                borderRadius: 0,
                // bgcolor: "common.compbg",
                ":hover": {
                  bgcolor: "common.compbg",
                },
                ".MuiTouchRipple-ripple .MuiTouchRipple-child": {
                  borderRadius: 0,
                  bgcolor: "common.compbg",
                },
              }}
            >
              <Stack
                direction="row"
                sx={{
                  m: 0,
                  p: 0,
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    typography: "h5",
                    p: 0,
                    m: 0,
                    color: "textSecondary",
                    textAlign: "center",
                    // bgcolor: "common.compbg",
                    fontWeight: "bold",
                  }}
                >
                  {addon.ui_data.content.desc}
                </Box>

                {expanded ? (
                  <RemoveOutlinedIcon
                    // <NorthSharpIcon
                    color="testPrimary"
                    fontSize="large"
                    sx={{
                      p: 0,
                      m: 0,
                    }}
                  />
                ) : (
                  <AddOutlinedIcon
                    // <SouthSharpIcon
                    color="textPrimary"
                    fontSize="large"
                    sx={{
                      p: 0,
                      m: 0,
                    }}
                  />
                )}
              </Stack>
            </ExpandMore>
          </Stack>
          {/* </Stack> */}
        </Stack>
        {/* <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: { xs: "none", sm: mode === "W" ? "block" : "none" },
            my: 0,
            mx: 1,
            bgcolor: "secondary.main",
          }}
        /> */}
        {/* pricing */}
      </Stack>
      {/* </Stack> */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent key={index}>
          {addon.ui_data.expansion.map((par, i2) => (
            <Stack key={i2} direction="column" sx={{ px: 0, pb: 0, m: 0 }}>
              <>
                {par.sections.map((sec, k2) => (
                  <Stack
                    key={i2 * 100 + k2}
                    direction="column"
                    sx={{ px: 0, pb: 0, m: 0.2 }}
                  >
                    <Accordion
                      square="false"
                      sx={{
                        ...AccordionStyle,
                      }}
                      key={i2 * 100 + k2}
                      elevation={0}
                      disableGutters
                      TransitionProps={{
                        unmountOnExit: true,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowDropDownCircleOutlinedIcon color="secondary" />
                        }
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        sx={{
                          ...AccordionSumStyle,
                        }}
                      >
                        <Box
                          sx={{
                            ...SumText,
                          }}
                        >
                          {sec.sec_name}
                        </Box>
                      </AccordionSummary>
                      <List
                        key={i2 * 100 + k2}
                        sx={{
                          // listStyleType: "disc",
                          pl: 1.5,
                          pr: 0,
                          py: 0,
                          m: 0,
                        }}
                      >
                        {sec.sec_dtl.map((sec_dtl, l) => (
                          <AccordionDetails
                            key={i2 * 100 + k2 * 10 + l}
                            sx={{
                              ...AccordionDtlStyle,
                            }}
                          >
                            <Stack
                              direction="row"
                              key={i2 * 100 + k2 * 10 + l}
                              sx={{
                                p: 0,
                                m: 0,
                                my: 1,
                                // bgcolor: "primary.main",
                              }}
                            >
                              <CheckCircleOutlineIcon
                                sx={{
                                  color: "textSecondary",
                                  p: 0,
                                  mt: 0.2,
                                  // border: 1,
                                }}
                              />
                              <ListItem
                                key={i2 * 100 + k2 * 10 + l}
                                sx={{
                                  ...DtlText,
                                  p: 0,
                                  ml: 1,
                                  my: 0,
                                  display: "list-item",
                                }}
                              >
                                {sec_dtl.line}
                              </ListItem>
                            </Stack>
                          </AccordionDetails>
                        ))}
                      </List>
                    </Accordion>
                  </Stack>
                ))}
              </>
            </Stack>
          ))}

          <Stack
            direction="row"
            sx={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              my: 0,
              p: 0,
            }}
          >
            <ExpandMore
              expand={expanded}
              onClick={() => handleExpandClick()}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{
                p: 0,
                m: 0,
                borderRadius: 0,
                // bgcolor: "common.compbg",
                ":hover": {
                  bgcolor: "common.compbg",
                },
                ".MuiTouchRipple-ripple .MuiTouchRipple-child": {
                  borderRadius: 0,
                  bgcolor: "common.compbg",
                },
              }}
            >
              {expanded ? (
                <RemoveOutlinedIcon
                  // <NorthSharpIcon
                  color="textPrimary"
                  fontSize="large"
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                />
              ) : (
                <AddOutlinedIcon
                  // <SouthSharpIcon
                  color="textPrimary"
                  fontSize="large"
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                />
              )}
            </ExpandMore>
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}

ItemCard.propTypes = {
  //   classes: PropTypes.any.isRequired,
};

export default ItemCard;
