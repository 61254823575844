import React, { Suspense, lazy } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { useMemo, useCallback, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { unflatten, flatten } from "flat";
//checks for prop changes to avoid unnecessary rerendering. Does not solve runtime error, only functions as checker.
// import { BrowserRouter } from "react-router-dom";
import DashBdDynForm from "./components/dashboard/pages/DashBdDynForm";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  Navigate,
  Outlet,
  defer,
  useLocation,
} from "react-router-dom";

import { menuListLoader } from "./components/store/crudActions";

import Assignment from "./components/dashboard/pages/Assignment";
//mui
//import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useColorScheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

//theme file to use with themeprovider component - themeprovider will wrap entire react fragment with consistent theme
// import theme from "./styling/theme";
// import { ColorModeContext, useMode } from "./styling/theme";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { themeSettings } from "./styling/theme";
// import GlobalStyles from "./styling/GlobalStyles";

//const Main = lazy(() => import("./components/Main"));
import myAxios from "./components/MyAxios";
const Main = React.lazy(() => import("./components/Main"));
import { NavBar } from "./components/NavBar";

import { deleteAssetType } from "./components/store/crudActions";

//loaders
import { NavBarLoader } from "./components/NavBar";
import { OverviewLoader } from "./components/home/Overview";
// import RecTabs from "./home/RecTabs";
// const About = React.lazy(()=> import("./components/About"));
const HomeTabs = React.lazy(() => import("./components/home/HomeTabs"));
import HomeTabsRouter from "./components/home/HomeTabsRouter";
// const Terms = React.lazy(()=> import("./components/TermPage"));
// const Privacy = React.lazy(()=> import("./components/Privacy"));
// const LoginPage = lazy(()=> import("./components/register_login/LoginPage"));
import { LoginPage } from "./components/register_login/LoginPage";

// const Cart = React.lazy(()=> import("./components/dashboard/Cart"));
// const Payments = React.lazy(()=> import("./components/dashboard/Payments"));
// const PassRecovery = React.lazy(()=> import("./components/register_login/PassRecovery"));
const ErrorPage = React.lazy(() => import("./ErrorPage"));
const Layout = React.lazy(() => import("./Layout"));
// const Overview = React.lazy(()=> import("./components/home/Overview"));
import { Overview } from "./components/home/Overview";
const Howitworks = React.lazy(() => import("./components/home/Howitworks"));
import { SignUp } from "./components/home/SignUp";
// const SignUp = React.lazy(()=> import("./components/home/SignUp"));
const SummaryDash = React.lazy(() =>
  import("./components/dashboard/SummaryDash")
);
// import { useOverviewStore } from "./components/store/zustandOverviewStore";
import { UseTokenStore } from "./components/store/zuTokenStore";
// <Route path="/rec" element={<RecTabs />} />) */}

// <Route path="/recovery" element={<PassRecovery />} />)
// <Route path="/dashboard/cart" element={<Cart />} />)
// <Route path="/dashboard/payments" element={<Payments />} />)
import { PassRecovery } from "./components/register_login/PassRecovery";
import MuiDBLayoutClipped from "./components/dashboard/MuiDBLayoutClipped";
import MuiDashboardLayout from "./components/dashboard/MuiDashboardLayout";
import { ConfirmProvider } from "material-ui-confirm";

const DashboardLayout = React.lazy(() =>
  import("./components/dashboard/DashboardLayout")
);
const MissionControl = React.lazy(() =>
  import("./components/dashboard/pages/MissionControl")
);
const Services = React.lazy(() =>
  import("./components/dashboard/pages/services/services-mrt")
);
// import Team from "./components/dashboard/pages/Team/team";
const MrtList = React.lazy(() =>
  import("./components/dashboard/pages/MrtList")
);
const Assign = React.lazy(() =>
  import("./components/dashboard/pages/MrtList2")
);
const DashBdOneStepForm = React.lazy(() =>
  import("./components/dashboard/pages/DashBdOneStepForm")
);
const DashBdMultiStepForm = React.lazy(() =>
  import("./components/dashboard/pages/DashBdMultiStepForm")
);
import Receipt from "./components/home/Receipt";
// import Receipts from "./components/dashboard/pages/receipts/transactions";
import { useCustStore } from "./components/store/zuCustStore";
import { updUser } from "./components/store/zuCustActions";
import * as jwtencoder from "jwt-encode";
import { refreshCust } from "./components/store/zuCustActions";
import {
  addAssetType,
  editAssetType,
  callUICrud,
} from "./components/store/crudActions";

import DashBdCartEdit from "./components/dashboard/pages/DashBdCartEdit";
// import { useOverviewStore } from "./components/store/zustandOverviewStore";
// import { useCartStore } from "./components/store/zuCartStore";
// import { useFieldArray } from "react-hook-form";

function App(props) {
  //local state definitions with corresponding setter metods.
  // const { classes } = props;
  // const [selectedTab, setSelectedTab] = useState(null);
  // const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  // const [blogPosts, setBlogPosts] = useState([]);
  // const [dialogOpen, setDialogOpen] = useState(null);
  // const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false);
  // const [theme, colorMode] = useMode();
  const ColorMode = UseTokenStore((state) => state.colorMode);
  const SetColorMode = UseTokenStore(
    useCallback((state) => state.setColorMode)
  );
  const SetVpWidth = UseTokenStore(useCallback((state) => state.setVpWidth));
  // console.log("colorMode0", ColorMode);

  const cust = useCustStore((state) => state.currentUser);
  const custReset = useCustStore((state) => state.reset);
  // const slides = useOverviewStore((state) => state.slides);
  // console.log("slides in app", slides);

  const getCustInfo = async () => {
    console.log("getcust input", cust);
    // const usrdata = await myAxios.post(`/ui/getuser`, cust);
    const usrdata = await myAxios.post(`/assets/handleCrud`, {
      data: cust.cust_id,
      crud_json: { obj_type: "users", crud_action: "R" },
    });
    return usrdata;
  };

  // const controller = new AbortController();

  const validatedUser = useCustStore((state) => state.validatedUser);
  const ProtectedRoute = ({ user, redirectPath, children }) => {
    var validRoute;
    let location = useLocation();
    console.log("ProtectedRoute::location:", location);
    console.log(
      "useCustStore.getState().valroutes",
      useCustStore.getState().valroutes
    );

    validRoute =
      useCustStore
        .getState()
        .valroutes.filter((routes) => routes.route === location.pathname)
        .length > 0;

    console.log("validRoute :: ", validRoute);
    console.log("in routename if condition", validatedUser, validRoute);

    if (
      !validatedUser ||
      !useCustStore
        .getState()
        .valroutes.filter((routes) => location.pathname.includes(routes.route))
        .length > 0
    ) {
      return <Navigate to={redirectPath} replace />;
    }
    return children;
  };

  // const getData5 = async (data2) => {
  // console.log("data2", data2);
  const getData5 = async (data2) => {
    // console.log("getData5 :: filteredData2 ::: ", filteredData2);

    const ljson = data2.map(async (ffield, j) => {
      var data = {};
      if (ffield.ui_data.menulist_name) {
        const temp = await callUICrud(ffield.ui_data.menulist_name, "R");
        // console.log("getData5:: temp", temp);
        // const tempjson = { [ffield.ui_data.menulist_name]: temp };
        // console.log("getData5:: tempjson", tempjson);
        data = { ...data, [ffield.ui_data.menulist_name]: temp };
        console.log("getData5::  data within loop", JSON.stringify(data));
        return data;
      }
    });
    const finallist = await Promise.all(ljson);
    console.log("getData5:: finallist", JSON.stringify(finallist));
    return finallist;
  };

  const getSubFFs = async () => {};

  const getFFs = async (ent, pg) => {
    // console.log('getcust input', cust);
    // const usrdata = await myAxios.post(`/ui/getuser`, cust);
    // console.log('userdata', usrdata);
    const ffdata = await myAxios.get(`/ui/getFF`, {
      params: {
        //lknlnl
        ientity: ent,
        pgnum: pg,
      },
    });
    // console.log("ffdata", ffdata)
    // const resultset = {userdata : usrdata, ffdata: ffdata}
    return ffdata;
  };
  // getCustInfo();
  // if (!ColorMode) {
  //   const WinColorMode = window?.matchMedia("(prefers-color-scheme: dark)")
  //     .matches
  //     ? "dark"
  //     : "light";
  //   console.log("WinColorMode", WinColorMode);
  //   SetColorMode(WinColorMode);
  // }

  const myrouter = createBrowserRouter([
    {
      path: "/",
      element: (
        // <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
        <Main />
      ),
      // </Suspense>
      loader: () => {
        const genToken = (tokendata, secret) => {
          const token1 = jwtencoder(tokendata, secret, {
            expiresIn: "7d",
          });

          return token1;
        };

        const getDate = () => {
          const date = new Date();
          return date.toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
        };

        const tokenPayload = {
          pondu: "Pondu Rocks",
          Date: getDate(),
        };

        const dailyToken = () => {
          const secret = process.env.REACT_APP_JWT_SECRET + "-" + getDate();
          const finaltoken = genToken(tokenPayload, secret);
          UseTokenStore.setState({ token: finaltoken });
        };
        dailyToken();
        console.log("token declared!", UseTokenStore.getState().token);
        return true;
      },
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          id: "beast",
          element: <NavBar />,
          errorElement: <ErrorPage />,
          loader: async () => {
            // const res1 = await myAxios.post("/ui/invoice");
            // console.log(res1)
            const data1 = await NavBarLoader();
            const [data2, data3] = await OverviewLoader();
            if (data1 && data2 && data3) {
              return { data1, data2, data3 };
            } else if (!data1 && data2 && data3) {
              return { data2, data3 };
            } else return true;
          },

          children: [
            {
              index: true,
              path: "/",
              element: <Overview />,

              // async lazy() {
              //   let { Overview } = await import("./components/home/Overview");
              //   return { Component: Overview  };
              // },
              // loader : async () =>{

              // }
            },
            {
              path: "overview",
              // loader : async () =>{
              //   const [data2,data3] = await OverviewLoader();
              //   return defer({data2 , data3});
              // },
              element: <Overview />,

              // async lazy() {
              //   // Multiple routes in lazy file
              //   let { Overview } = await import("./components/home/Overview");
              //   return { Component: Overview  };
              // },
            },
            {
              path: "howitworks",
              async lazy() {
                let { HowItWorks, HIWLoader } = await import(
                  "./components/home/Howitworks"
                );
                return { loader: HIWLoader, Component: HowItWorks };
              },
            },
            {
              path: "signUp",
              element: <SignUp />,
              loader: async () => {
                try {
                  const data2 = await getFFs("cust", { i_page: "C" });
                  console.log("b", data2);
                  return { data2 };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },
              // element:
              // <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
              //   <SignUp />
              // </Suspense>
              // async lazy(){
              //   let { SignUp } = await import("./components/home/SignUp")
              //   return { Component : SignUp};
              // }
            },
            // ],
            // },
            // {
            //   path: "",
            //   element: <Overview />,
            // },
            // {
            //   path: "overview",
            //   element: <Overview />,
            // },
            // {
            //   path: "howitworks",
            //   element: <Howitworks />,
            // },

            // {
            //   path: "dashboard",
            //   element: <MyDashboard />,
            //   children: [
            //     {
            //       path: "",
            //       element: <SummaryDash />,
            //     },
            //     {
            //       path: "cart",
            //       element: <Cart />,
            //     },
            //     {
            //       path: "payments",
            //       element: <Payments />,
            //     },
            //   ],
            // },
            // {
            //   path: "login",
            //   element: <LoginPage />,
            // },
            {
              path: "about",
              async lazy() {
                let { About } = await import("./components/About");
                return { Component: About };
              },
              // element:
              // <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
              //   <About />
              // </Suspense>
            },
            {
              path: "terms",
              // element:
              // <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
              //   <Terms />
              // </Suspense>
              // ,
              async lazy() {
                let { TermsConditions } = await import("./components/TermPage");
                return { Component: TermsConditions };
              },
            },
            {
              path: "privacy",
              // element:
              // <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
              //   <Privacy />
              // </Suspense>
              async lazy() {
                let { Privacy } = await import("./components/Privacy");
                return { Component: Privacy };
              },
            },
          ],
        },
        {
          path: "login",
          element: (
            <Suspense
              fallback={<CircularProgress size={25} color="secondary" />}
            >
              <LoginPage />
            </Suspense>
          ),
          // async lazy() {
          //   let { LoginPage } = await import(
          //     "./components/register_login/LoginPage"
          //   );
          //   return { Component: LoginPage };
          // },
        },
        {
          path: "logout",
          element: (
            <Suspense
              fallback={<CircularProgress size={25} color="secondary" />}
            >
              <LoginPage />
            </Suspense>
          ),
          // async lazy(){
          //   let { LoginPage } = await import("./components/register_login/LoginPage")
          //   return {Component : LoginPage}
          // }
          // loader: async () => {
          //   console.log("in logout loader");
          //   custReset();
          //   // useCustStore.setState({ validatedUser : null })
          //   console.log("after cust store reset", validatedUser);
          //   return true;
          // },
          // action: async ({ request }) => {
          //   console.log("in action");
          //   <Navigate to={0} />;
          //   // console.log("request::", request);
          //   // return redirect("/login");
          // },
        },
        {
          path: "forgotpwd",
          // async lazy() {
          //   let { PassRecovery } = await import(
          //     "./components/register_login/PassRecovery"
          //   );
          //   return { Component: PassRecovery };
          // },
          loader: async () => {
            try {
              return { data1: { loc: "forgotpwd" } };
            } catch (e) {
              console.log(e);
              // throw e;
            }
          },
          element: (
            <Suspense
              fallback={<CircularProgress size={25} color="secondary" />}
            >
              <PassRecovery />
            </Suspense>
          ),
        },
        {
          path: "changepwd",
          // async lazy() {
          //   let { PassRecovery } = await import(
          //     "./components/register_login/PassRecovery"
          //   );
          //   return { Component: PassRecovery };
          // },
          loader: async () => {
            try {
              return { data1: { loc: "changepwd" } };
            } catch (e) {
              console.log(e);
              // throw e;
            }
          },
          element: (
            <Suspense
              fallback={<CircularProgress size={25} color="secondary" />}
            >
              <PassRecovery />
            </Suspense>
          ),
        },
        {
          path: "dashboard",
          element: (
            <Suspense
              fallback={<CircularProgress size={25} color="secondary" />}
            >
              <ProtectedRoute user={validatedUser} redirectPath="/login">
                <DashboardLayout />
              </ProtectedRoute>
            </Suspense>
          ),
          loader: async () => {
            try {
              const data1 = await callUICrud("db_nav_list", "R", {
                usr_id: useCustStore.getState().currentUser.id,
                // list_type: "F",
              });
              console.log("db_nav_list:", data1);

              const routesflattened = await callUICrud("db_nav_list", "R", {
                usr_id: useCustStore.getState().currentUser.id,
                list_type: "F",
              });

              useCustStore.setState({ valroutes: routesflattened });

              console.log("useCuststore.getstate :: ", useCustStore.getState());

              return {
                data1: { data: data1 },
                data3: {
                  add: true,
                  edit: true,
                  delete: true,
                  title: "Dashboard",
                },
              };
            } catch (e) {
              console.log("e", e);
              return <Navigate to="dashboard" />;
              // throw e;
            }
          },
          // loader: async () => {
          //   console.log("in dashloader");
          //   const cart = await myAxios.post(
          //     "/ui/getcartul",
          //     useCustStore.getState().currentUser
          //   );
          //   useCartStore.setState({ cart: cart.data });
          //   return true;
          // },
          // element: <MuiDashboardLayout />,
          // element: <MuiDBLayoutClipped />,
          // element: <SummaryDash />,

          children: [
            {
              path: "",
              // index: true,
              element: <MissionControl />,
              // element: <Receipt />,
              // element: <Services />,
              // children: [
              // ],
            },
            {
              path: "subscription",
              element: (
                // <ProtectedRoute user={validatedUser} redirectPath="/overview">
                <ProtectedRoute user={validatedUser} redirectPath="/dashboard">
                  <Services />
                </ProtectedRoute>
              ),
              loader: async () => {
                try {
                  // const locdata = useLocation();
                  // console.log("locdata in loader", locdata);
                  console.log(
                    "cust id",
                    useCustStore.getState().currentUser.login
                  );
                  // const data1 = await myAxios.post(
                  //   "/ui/getcartul",
                  //   useCustStore.getState().currentUser
                  // );
                  // console.log("a", data1.data);
                  // const data2 = await getFFs("users", "L");
                  // console.log("b", data2);
                  return { data3: { add: true } };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },
              // element: <MissionControl />,
              // loader: async () => (await myAxios.get("/services")).data.reverse(),
            },
            {
              path: "subscription/edit",
              element: (
                <ProtectedRoute user={validatedUser} redirectPath="/dashboard">
                  <DashBdCartEdit />
                </ProtectedRoute>
              ),

              loader: async ({ params }) => {
                try {
                  // const locdata = useLocation();
                  // console.log("locdata in loader", locdata);
                  console.log(
                    "cust id",
                    useCustStore.getState().currentUser.login
                  );

                  const [data2, data4] = await OverviewLoader();

                  // console.log("a", data1);
                  // const data2 = await getFFs("users", "L");
                  // console.log("b", data2);
                  return { data2: data4, data3: { add: true } };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },
            },
            {
              path: "assignment",
              element: (
                <ProtectedRoute user={validatedUser} redirectPath="/nigga">
                  <Assignment />
                </ProtectedRoute>
              ),
              loader: async () => {
                try {
                  const data1 = await getCustInfo();
                  console.log("a", data1);
                  return { data1, data3: { add: true } };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                }
              },
            },
            {
              path: "cust",
              element: (
                <ProtectedRoute user={validatedUser} redirectPath="/login">
                  <MrtList />
                </ProtectedRoute>
              ),
              loader: async () => {
                try {
                  console.log(
                    "id",
                    useCustStore.getState().currentUser.cust_id
                  );
                  const cust = useCustStore.getState().currentUser;
                  console.log("cust", cust);
                  const data1 = await myAxios.post(
                    "/ui/getcustinformation",
                    cust
                  );
                  console.log("a", data1);
                  const data2 = await getFFs("cust", { i_page: "L" });
                  console.log("b", data2);
                  // var data3 = [];

                  // data2.data.map((row) => {
                  //   data3 = [...data3, row.ui_data];
                  // });

                  // console.log(data3);
                  return {
                    data1: { data: [data1.data[0]] },
                    data2,
                    data3: {
                      add: false,
                      edit: true,
                      delete: false,
                      title: "Customer",
                    },
                  };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },

              // element: <MissionControl />,
              // loader: async () => (await myAxios.get("/services")).data.reverse(),
            },
            {
              path: "cust/edit/:id",
              element: <DashBdOneStepForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log("formData", Object.fromEntries(formData));
                const sendCust = {
                  ...Object.fromEntries(formData),
                  location: "nsignupconf",
                };
                console.log("sendCust", sendCust);
                await refreshCust(sendCust);
                console.log("updated");
                return true;

                // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
              },
              loader: async ({ params }) => {
                // console.log('a', data1)
                console.log("params", params);
                const data1 = await myAxios.post(
                  "/ui/getcustinformation",
                  cust
                );
                console.log("data1:  ", data1);
                const data2 = await getFFs("cust", { i_page: "E" });
                // console.log('b',data2)
                return {
                  data1: data1.data,
                  data2,
                  data3: { title: "Customer" },
                };
              },
            },
            {
              path: "team",
              element: (
                // <ProtectedRoute user={validatedUser} redirectPath="/login">
                <MrtList />
              ),
              action: async ({ request }) => {
                console.log("in Delete Action");
                const formData = await request.formData();
                const res = await myAxios.post(`/assets/handleCrud`, {
                  data: Object.fromEntries(formData),
                  crud_json: { obj_type: "users", crud_action: "D" },
                });
                return res.data;
                // }
                // console.log("x", x);
                // return x;
                // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
              },
              // </ProtectedRoute>
              loader: async () => {
                try {
                  const data1 = await getCustInfo();
                  console.log("a", data1);
                  const data2 = await getFFs("users", { i_page: "L" });
                  console.log("b", data2);
                  return {
                    data1,
                    data2,
                    data3: {
                      add: true,
                      edit: true,
                      delete: true,
                      title: "Team",
                    },
                  };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },

              // element: <MissionControl />,
              // loader: async () => (await myAxios.get("/services")).data.reverse(),
            },
            {
              path: "svc2usrassign",
              element: (
                // <ProtectedRoute user={validatedUser} redirectPath="/login">
                <Assign />
              ),
              // </ProtectedRoute>
              loader: async () => {
                try {
                  const data1 = await getCustInfo();
                  console.log("a", data1);
                  const data2 = await getFFs("users", { i_page: "L" });
                  console.log("b", data2);
                  return {
                    data1,
                    data2,
                    data3: {
                      add: true,
                      edit: true,
                      delete: true,
                      title: "Team",
                    },
                  };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="overview" />;
                  // throw e;
                }
              },

              // element: <MissionControl />,
              // loader: async () => (await myAxios.get("/services")).data.reverse(),
            },
            {
              path: "team/create",
              // element: <DashBdMultiStepForm />,
              element: <DashBdOneStepForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log("formData", Object.fromEntries(formData));
                var nFormData;
                if (Object.fromEntries(formData).swemail) {
                  const mailer = await myAxios.post(`/mailer/welcome`, {
                    Email: Object.fromEntries(formData).login,
                    Subject: "BREazy Order Confirmation",
                    // Variables: {
                    //   name: Object.fromEntries(formData).fname,
                    //   service1:
                    //     useCartStore.getState().cart[0].ui_data.introline,
                    //   qty1: useCartStore.getState().cart[0].quantity,
                    //   service2:
                    //     useCartStore.getState().cart[1].ui_data.introline,
                    //   qty2: useCartStore.getState().cart[1].quantity,
                    //   service3:
                    //     useCartStore.getState().cart[2].ui_data.introline,
                    //   qty3: useCartStore.getState().cart[2].quantity,
                    //   quickbooksinvoice1: "www.quickbooks.com",
                    //   cart: useCartStore.getState().cart,
                    //   // total:
                    // },
                  });
                  if (mailer.status === 200) {
                    console.log(Date());
                    nFormData = {
                      ...Object.fromEntries(formData),
                      wemailsenddate: Date(),
                    };

                    // console.log("refreshCart-res.data:" + JSON.stringify(data));
                  } else {
                    nFormData = Object.fromEntries(formData);
                  }
                }

                // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
                const res = await myAxios.post(`/assets/handleCrud`, {
                  data: nFormData,
                  crud_json: { obj_type: "users", crud_action: "C" },
                });
                return res;
              },
              loader: async () => {
                // console.log('a', data1)
                const data2 = await getFFs("users", { i_page: "C" });
                // console.log('b',data2)
                const menuListValues = await callUICrud("user_role", "R", {
                  userrole: useCustStore.getState().currentUser.role,
                });
                console.log("menulistval", menuListValues);
                return {
                  data1: null,
                  data2,
                  data3: { title: "Add User" },
                  menuListValues: { user_role: menuListValues },
                };
              },
            },
            {
              path: "team/edit/:id",
              // element: <DashBdMultiStepForm />,
              element: <DashBdOneStepForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log("formData", Object.fromEntries(formData));
                var nFormData;
                if (Object.fromEntries(formData).swemail) {
                  const mailer = await myAxios.post(`/mailer/welcome`, {
                    Email: Object.fromEntries(formData).login,
                    Subject: "BREazy Order Confirmation",
                    // Variables: {
                    //   name: Object.fromEntries(formData).fname,
                    //   service1:
                    //     useCartStore.getState().cart[0].ui_data.introline,
                    //   qty1: useCartStore.getState().cart[0].quantity,
                    //   service2:
                    //     useCartStore.getState().cart[1].ui_data.introline,
                    //   qty2: useCartStore.getState().cart[1].quantity,
                    //   service3:
                    //     useCartStore.getState().cart[2].ui_data.introline,
                    //   qty3: useCartStore.getState().cart[2].quantity,
                    //   quickbooksinvoice1: "www.quickbooks.com",
                    //   cart: useCartStore.getState().cart,
                    //   // total:
                    // },
                  });
                  if (mailer.status === 200) {
                    console.log(Date());
                    nFormData = {
                      ...Object.fromEntries(formData),
                      wemailsenddate: Date(),
                    };

                    // console.log("refreshCart-res.data:" + JSON.stringify(data));
                  } else {
                    nFormData = Object.fromEntries(formData);
                  }
                }

                const res = await myAxios.post(`/assets/handleCrud`, {
                  data: {
                    user_id: Object.fromEntries(formData).id,
                    user_email: Object.fromEntries(formData).login,
                    ...nFormData,
                  },
                  crud_json: { obj_type: "users", crud_action: "U" },
                });

                console.log("updated");
                return res.data;
                // await myAxios.post(`/ui/upduser`, {
                //   user_id: Object.fromEntries(formData).id,
                //   user_email: Object.fromEntries(formData).login,
                //   ...Object.fromEntries(formData),
                // });
                // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
              },
              loader: async ({ params }) => {
                // console.log('a', data1)
                console.log("params", params);
                const data1 = await getCustInfo();

                var data2 = await getFFs("users", { i_page: "E" });
                data2 = { ...data2, pwdChg: true };
                // console.log('b',data2)
                const menuListValues = await callUICrud("user_role", "R", {
                  userrole: useCustStore.getState().currentUser.role,
                });
                console.log(
                  "currentUser",
                  useCustStore.getState().currentUser.role
                );
                return {
                  menuListValues: { user_role: menuListValues },
                  data1: data1.data.filter((user) => user.id == params.id),
                  data2,
                  data3: { title: "Edit User" },
                };
              },
            },
            {
              path: "team/profile/:id",
              // element: <DashBdMultiStepForm />,
              element: <DashBdOneStepForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log("formData", Object.fromEntries(formData));
                await myAxios.post(`/ui/upduser`, {
                  user_email: Object.fromEntries(formData).login,
                  ...Object.fromEntries(formData),
                });
                console.log("updated");

                // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
              },
              loader: async ({ params }) => {
                // console.log('a', data1)
                console.log("params", params);
                const data1 = await getCustInfo();

                const data2 = await getFFs("users", { i_page: "C" });
                // console.log('b',data2)
                return { data1, data2, data3: { title: "Manage Profile" } };
              },
            },
            {
              path: "asset_type",
              element: (
                // <ProtectedRoute user={validatedUser} redirectPath="/login">
                <MrtList />
              ),
              action: async ({ request }) => {
                console.log("in Delete Action");
                const formData = await request.formData();
                const res = await myAxios.post(`/assets/handleCrud`, {
                  data: Object.fromEntries(formData),
                  crud_json: { obj_type: "asset_type", crud_action: "D" },
                });
                return res.data;
                // }

                // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
              },
              // </ProtectedRoute>
              loader: async () => {
                try {
                  const data1 = await callUICrud("asset_type", "R");
                  console.log("a", data1);
                  const data2 = await getFFs("asset_type", { i_page: "L" });
                  console.log("b", data2);
                  return {
                    data1: { data: data1 },
                    data2,
                    data3: {
                      add: useCustStore.getState().currentUser.role > 2,
                      edit: true,
                      delete: true,
                      title: "Asset Types",
                    },
                  };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="dashboard" />;
                  // throw e;
                }
              },

              // element: <MissionControl />,
              // loader: async () => (await myAxios.get("/services")).data.reverse(),
            },

            {
              path: "asset_type/create",
              // element: <DashBdMultiStepForm />,
              element: <DashBdOneStepForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log("formData", Object.fromEntries(formData));
                const x = await callUICrud(
                  "asset_type",
                  "C",
                  Object.fromEntries(formData)
                );
                console.log("x", x);
                return x;
              },
              loader: async () => {
                const data2 = await getFFs("asset_type", { i_page: "C" });
                const menuListValues = await menuListLoader(
                  data2.data.filter((ffrow) => ffrow.ui_data.menulist_name)
                );
                console.log(
                  "ssetType/create::menuListValues:",
                  JSON.stringify(menuListValues)
                );

                return {
                  data2,
                  data3: { title: "Add Asset Type" },
                  menuListValues,
                };

                // return {
                //   data1: { data: null },
                //   data2,
                //   data3: { title: "Add Asset Type" },
                // };
              },
            },
            {
              path: "asset_type/edit/:id",
              // element: <DashBdMultiStepForm />,
              element: <DashBdDynForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log("formData", Object.fromEntries(formData));
                const x = await callUICrud(
                  "asset_type",
                  "U",
                  Object.fromEntries(formData)
                );
                console.log("updated");
                return x;
              },
              loader: async ({ params }) => {
                console.log("params", params);
                const data1 = await callUICrud("asset_type", "R", {
                  id: params.id,
                });
                console.log(
                  "data1",
                  data1.filter((field) => field.id === params.id)
                );
                var data2 = await getFFs("asset_type", { i_page: "E" });

                const menuListValues = await menuListLoader(
                  data2.data.filter((ffrow) => ffrow.ui_data.menulist_name)
                );
                console.log(
                  "assetType/edit::menuListValues:",
                  JSON.stringify(menuListValues)
                );

                return {
                  data1: data1.filter((field) => field.id == params.id),
                  data2,
                  data3: { title: "Edit Asset Type" },
                  menuListValues,
                };
              },
            },
            {
              path: "cust_assets",
              element: (
                // <ProtectedRoute user={validatedUser} redirectPath="/login">
                // <ProtectedRoute
                //   user={validatedUser}
                //   redirectPath="/dashboard"
                //   routeName={"/dashboard/cust_assets"}
                // >
                <MrtList />
                // </ProtectedRoute>
              ),
              action: async ({ request }) => {
                console.log("asset::in Delete Action");
                const formData = await request.formData();
                console.log("asset::Object.fromEntries.formData::", formData);
                var x;
                // if (Object.fromEntries(formData).length > 0) {
                //   Object.fromEntries(formData).map(async (row) => {
                //     x = await deleteAssetType(row);
                //   });
                // } else {
                // x = await deleteAssetType(Object.fromEntries(formData));
                // }
                console.log("x", x);
                return x;
              },
              // </ProtectedRoute>
              loader: async () => {
                try {
                  const data1 = await callUICrud("cust_asset", "R", {
                    // list: 1,
                    cust_id: useCustStore.getState().currentUser.cust_id,
                  });
                  console.log("a", data1);
                  const data2 = await getFFs("usr_assets", { i_page: "L" });
                  console.log("b", data2);
                  return {
                    data1: { data: data1 },
                    data2,
                    data3: {
                      add: true,
                      edit: true,
                      delete: false,
                      delete1: true,
                      title: "Assets",
                    },
                  };
                } catch (e) {
                  console.log("e", e);
                  return <Navigate to="dashboard" />;
                  // throw e;
                }
              },

              // element: <MissionControl />,
              // loader: async () => (await myAxios.get("/services")).data.reverse(),
            },
            {
              path: "cust_assets/edit/:id",
              element: <DashBdDynForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log(
                  "cust_assets:formData:",
                  Object.fromEntries(formData)
                );
                console.log(
                  "cust_assets:formData: unflatten",
                  unflatten(Object.fromEntries(formData), { delimiter: "-" })
                );

                let xy = unflatten(Object.fromEntries(formData), {
                  delimiter: "-",
                });
                delete xy["test"];

                console.log(
                  "cust_assets:formData: unflatten + delete :: ",

                  unflatten(xy, { delimiter: "-" })
                );
                const x = await callUICrud("cust_asset", "U", xy);
                console.log("updated");
                return x;
              },
              loader: async ({ params }) => {
                const data1 = await callUICrud("cust_asset", "R", {
                  id: params.id,
                });
                console.log("data1 edit route loader :: ", data1);
                var data2 = await getFFs("usr_assets", { i_page: "E" });
                var data4 = [];
                var subtype_name = "";
                var subtype_label = "";

                for (let i = 0; i < data2.data.length; ++i) {
                  if (data2.data[i].ui_data?.subtype_name) {
                    subtype_name = data2.data[i].ui_data.subtype_name;
                    subtype_label = data2.data[i].ui_data.subtype_label;

                    var data2app = await getFFs("asset_props", {
                      i_page: "E",
                      i_entity_id: data1[0]?.[data2.data[i].ui_data.colname],
                      // i_entity_id: data2.data[i].ui_data.colname,
                    });
                    console.log("data2app", data2app);
                    for (var j = 0; j < data2app.data.length; ++j) {
                      console.log("j".j);
                      data4 = [...data4, data2app.data[j]];
                    }
                  }
                }

                const menuListValues = await menuListLoader(
                  data2.data.filter((ffrow) => ffrow.ui_data.menulist_name)
                );

                var data6;
                for (var i = 0; i < data2.data.length; ++i) {
                  if (
                    data2.data[i].ui_data.type === "subtype" &&
                    data2.data[i].ui_data?.subtype_trigger_field === ""
                  ) {
                    console.log(data2.data[i].ui_data);
                    const res = await getFFs(
                      data2.data[i].ui_data.subtype_name,
                      {
                        i_page: "E",
                        // i_entity_id: input,
                      }
                    );
                    data6 = res.data;
                    console.log("data6", data6);
                  }
                  console.log("data2 loop", data2.data[i]);
                }

                const flattened = flatten(data1[0], { delimiter: "-" });
                return {
                  data1: [flattened],
                  data2,
                  data3: { title: "Edit usr_assets", cmode: "edit" },
                  data4: {
                    data: data4,
                    subtype_name: subtype_name,
                    subtype_label: subtype_label,
                  },
                  menuListValues,
                  data6,
                };
              },
            },
            {
              path: "cust_assets/create",
              element: <DashBdDynForm />,
              action: async ({ request }) => {
                const formData = await request.formData();
                console.log(
                  "usr_assets:formData:",
                  Object.fromEntries(formData)
                );
                console.log(
                  "usr_assets:formData: unflatten",
                  unflatten(Object.fromEntries(formData), { delimiter: "-" })
                );

                let xy = unflatten(Object.fromEntries(formData), {
                  delimiter: "-",
                });
                delete xy["test"];

                console.log(
                  "usr_assets:formData: unflatten + delete :: ",

                  unflatten(xy, { delimiter: "-" })
                );
                const x = await callUICrud("cust_asset", "C", xy);
                console.log("x", x);
                return x;
                // return true;
              },
              loader: async ({ params }) => {
                console.log("usr_assets:params:", params);
                var data2 = await getFFs("usr_assets", { i_page: "C" });
                // var data2 = await callUICrud("formfield", "R");
                console.log("data2 in add user assets :: ", data2);

                // console.log("fields", fields);

                const menuListValues = await menuListLoader(
                  data2.data.filter((ffrow) => ffrow.ui_data.menulist_name)
                );
                console.log(
                  "data5 after getDate5 call",
                  JSON.stringify(menuListValues)
                );
                var data6 = {};
                for (var i = 0; i < data2.data.length; ++i) {
                  var arraydata = [];

                  if (
                    (data2.data[i].ui_data.type === "subtype" ||
                      data2.data[i].ui_data.type === "subtype_list") &&
                    data2.data[i].ui_data?.subtype_trigger_field === ""
                  ) {
                    console.log("data2.data[i].ui_data", data2.data[i].ui_data);
                    const res = await getFFs(
                      data2.data[i].ui_data.subtype_name,
                      {
                        i_page: "E",
                        // i_entity_id: input,
                      }
                    );
                    console.log("res in loop", res);

                    for (var j = 0; j < res.data.length; ++j) {
                      if (data2.data[i].ui_data.type === "subtype_list") {
                        arraydata = [
                          ...arraydata,
                          {
                            ...res.data[j],
                            ui_data: {
                              ...res.data[j].ui_data,
                              colname: res.data[j].ui_data.colname,
                            },
                          },
                        ];
                      } else {
                        arraydata = [
                          ...arraydata,
                          {
                            ...res.data[j],
                            ui_data: {
                              ...res.data[j].ui_data,
                              colname: `${data2.data[i].ui_data.colname}-${res.data[j].ui_data.colname}`,
                            },
                          },
                        ];
                      }

                      data6 = {
                        ...data6,
                        [data2.data[i].ui_data.subtype_name]: arraydata,
                      };
                    }
                    console.log("data6", data6);
                  }
                  console.log("data2 loop", data2.data[i]);
                }
                var data2new = data2.data;
                for (var i = 0; i < data2.data.length; i++) {
                  console.log("data6 in loop", data6);
                  // console.log("fields in loop2", fields);
                  if (
                    data6 &&
                    (data2.data[i].ui_data.type === "subtype" ||
                      data2.data[i].ui_data.type === "subtype_list") &&
                    data2.data[i].ui_data?.subtype_trigger_field === ""
                  ) {
                    console.log("index of field with subtypes loop", i);
                    data2new[i] = {
                      ...data2new[i],
                      subtypeFields: data6[data2.data[i].ui_data.subtype_name],
                    };
                    console.log("fields in loop2", data2.data);
                  }
                }
                const data1 = await myAxios.post(`/assets/handleCrud`, {
                  data: cust.cust_id,
                  crud_json: { obj_type: "users", crud_action: "R" },
                });
                console.log("data1", data1);
                return {
                  data1: data1.data,
                  data2: { data: data2new },
                  data3: {
                    add: true,
                    edit: true,
                    delete: false,
                    delete1: true,
                    title: "Assets",
                    cmode: "add",
                  },
                  menuListValues,
                  data6,
                };
              },
            },
            // {
            //   path: "asset/edit/:id",
            //   element: <DashBdOneStepForm />,
            //   action: async ({ request }) => {
            //     const formData = await request.formData();
            //     console.log("formData", Object.fromEntries(formData));
            //     const sendCust = {
            //       ...Object.fromEntries(formData),
            //       location: "nsignupconf",
            //     };
            //     console.log("sendCust", sendCust);
            //     await refreshCust(sendCust);
            //     console.log("updated");

            //     // myAxios.post(`/ui/addnuser`, Object.fromEntries(formData));
            //   },
            //   loader: async ({ params }) => {
            //     // console.log('a', data1)
            //     console.log("params", params);
            //     const data2 = await getFFs("asset", "E");
            //     const data4 = await getSubFFs(params.id);
            //     // console.log('b',data2)
            //     return { data1, data2, data3: { title: "Customer" } };
            //   },
            // },
            // {
            //   path: "team/create",
            //   // element: <DashBdMultiStepForm />,
            //   element: <DashBdOneStepForm />,
            //   loader: async () => {
            //     // console.log('a', data1)
            //     const data2 = await getFFs("users", "C");
            //     // console.log('b',data2)
            //     return data2;
            //   },
            // },
            // {
            //   path: "cart",
            //   element: <Cart />,
            // },
            // {
            //   path: "payments",
            //   element: <Payments />,
            // },
          ],
        },
        // {
        //   path: "about",
        //   element: <About />,
        // },
        // {
        //   path: "terms",
        //   element: <Terms />,
        // },
        // {
        //   path: "privacy",
        //   element: <Privacy />,
        // },

        // {
        //   path: "recovery",
        //   element: <PassRecovery />,
        // },

        // {
        //   path: "dashboard/cart",
        //   element: <Cart />,
        // },
        // {
        //   path: "dashboard/payments",
        //   element: <Payments />,
        // },
      ],
    },
    // {
    //   path: "dashboard",
    //   element: <DashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       // index: true,
    //       element: <MissionControl />,
    //       // element: <Services />,
    //       // element: <SummaryDash />,
    //       // children: [
    //       // ],
    //     },
    //     {
    //       path: "services",
    //       element: <Services />,
    //       // element: <MissionControl />,
    //       // loader: async () => (await myAxios.get("/services")).data.reverse(),
    //     },
    //     {
    //       path: "team",
    //       element: <Team />,
    //       // element: <MissionControl />,
    //       // loader: async () => (await myAxios.get("/services")).data.reverse(),
    //     },
    //     // {
    //     //   path: "cart",
    //     //   element: <Cart />,
    //     // },
    //     // {
    //     //   path: "payments",
    //     //   element: <Payments />,
    //     // },
    //   ],
    // },
  ]);

  // console.log("colorMode1", ColorMode);
  const newColorMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  // console.log("WinColorMode", WinColorMode);
  useEffect(() => {
    SetColorMode(newColorMode);
    // console.log("newColorMode", newColorMode);
    //     window.localStorage.setItem("colorMode", colorMode);
  }, [newColorMode]);
  // console.log("colorMode3", ColorMode);

  useEffect(() => {
    // console.log("width of page", siteWidth);

    function handleResize() {
      // setWidth(window.innerWidth);
      // setWidth(Math.min(window.innerWidth, 1080));
      SetVpWidth(Math.min(window.innerWidth, 1080));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  // function getCurrentColorScheme() {
  //   const QUERIES = {};

  //   return (getCurrentColorScheme = function () {
  //     for (let scheme of COLOR_SCHEMES) {
  //       const query = QUERIES.hasOwnProperty(scheme)
  //         ? QUERIES[scheme]
  //         : (QUERIES[scheme] = matchMedia(`(prefers-color-scheme: ${scheme})`));

  //       if (query.matches) return { query, scheme };
  //     }
  //   })();
  // }
  // const colorScheme = useRef();

  // useEffect(() => {
  //   const { query } = colorScheme.current;

  //   query.addEventListener("change", schemeChangeHandler);
  //   isMounted.current = true;

  //   function schemeChangeHandler(evt) {
  //     if (!evt.matches) {
  //       this.removeEventListener("change", schemeChangeHandler);
  //       const { query, scheme } = (colorScheme.current =
  //         getCurrentColorScheme());

  //       isMounted.current && setColorScheme(scheme);
  //       query.addEventListener("change", schemeChangeHandler);
  //     }
  //   }
  //   return () => {
  //     const { query } = colorScheme.current;
  //     query.removeEventListener("change", schemeChangeHandler);
  //     isMounted.current = false;
  //   };
  // }, []);

  const theme = useMemo(() =>
    responsiveFontSizes(createTheme(themeSettings(ColorMode)), [ColorMode])
  );
  return (
    <React.Fragment>
      {/* <BrowserRouter> */}
      <StyledEngineProvider injectFirst>
        {/* console.log ("app1") */}
        {/* theme provider component will wrap the entire child component with a theme file which will persist throughout entire subcomponent */}
        {/* <ColorModeContext.Provider value={colorMode}> */}
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            {/* provides style basis for entire program */}
            <CssBaseline>
              {/* <GlobalStyles /> */}
              {/* essentially takes a quick second to pause and integrate data and help run asynchronous application more smoothly */}
              {/* <Suspense> */}
              <RouterProvider router={myrouter} />
              {/* <Main /> */}
              {/* </Suspense> */}
            </CssBaseline>
          </ConfirmProvider>
        </ThemeProvider>
        {/* </ColorModeContext.Provider>{" "} */}
      </StyledEngineProvider>
      {/* </BrowserRouter> */}
    </React.Fragment>
  );
}

//makes sure all props passed to the app.js object are of the correct type
App.propTypes = {};

export default App;
