import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

function StyledHeader(props) {
  const { text } = props;

  return (
    <>
      <Stack
        sx={{
          mx: 0,
          my: 0,
          // py: 0.5,
          px: 0,
          mb: 0.5,
          justifyContent: "center",
          alignItems: "center",

          bgcolor: "common.compbg",
          border: "0.0px",
          borderStyle: "solid",
          borderRadius: "20px 20px 20px 20px",
          borderColor: "common.secondary5",
        }}
      >
        <Box
          sx={{
            mx: 1,
            typography: "h4",
            color: "textSecondary",
            // color: "common.black",
            fontWeight: "medium",
            textAlign: "center",
            // textTransform: "uppercase",
            fontFamily: "default",
          }}
        >
          {props.text}
        </Box>
      </Stack>
    </>
  );
}

StyledHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default StyledHeader;
