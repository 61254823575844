import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import VisibilityPasswordTextField from "../shared/VisibilityPasswordTextField";
import NumberInput2 from "material-ui-numeric-input";
import { ButtonGroup } from "@mui/material";
import StyledButton from "../shared/StyledButton";
import { addUpdCartItem } from "../store/zuCartActions";
import { useIMask } from "use-imask";
// import { useIMask } from "react-imask";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useCustStore } from "../store/zuCustStore";
import { useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link as RouterLink } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";

import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";

import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Badge from "@mui/material/Badge";
import { Tooltip } from "@mui/material";
import { validationStore } from "../store/validationStore";

export const SignUpCusTxtFld = ({ ...props }) => {
  const { control } = useFormContext();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const methods = useFormContext();
  const emailChk = useCustStore((state) => state.emailChk);
  const emailChk2 = useCustStore((state) => state.emailChk2);

  const getSchema = (yuptype, validations) => {
    var schema = {};
    console.log("rules props", props.allprops);
    if (props.allprops.userules === "true") {
      if (validations) {
        for (var i = 0; i < validations.length; ++i) {
          var type = validations[i].type;
          var subschema = {
            [type]: {
              value: validations[i].params[0],
              message:
                validations[i].params.length > 1
                  ? validations[i].params[1]
                  : validations[i].params[0],
            },
          };
          schema = { ...schema, ...subschema };
        }
      } else if (yuptype) {
        console.log("rulesyuptype", yuptype);
        console.log("rulesvalidationpacket", validationStore[yuptype]);
        var yupValRule = validationStore[yuptype];
        if (!yupValRule) {
          console.log("no rules found for yuptype: ", yuptype);
          return schema;
        }
        console.log("rules validation breakdown ", yupValRule);
        for (var i = 0; i < yupValRule.length; ++i) {
          var type = "";
          if (yupValRule[i].type === "test") {
            type = "validate";
          } else {
            type = yupValRule[i].type;
          }

          var subschema = {};
          if (type === "validate") {
            subschema = {
              validate:
                yupValRule[i].params.length > 1
                  ? yupValRule[i].params[1]
                  : yupValRule[i].params[0],
            };
          } else {
            subschema = {
              [type]: {
                value:
                  yupValRule[i].params.length > 1
                    ? yupValRule[i].params[1]
                    : yupValRule[i].params[0],
                message: yupValRule[i].params[0],
              },
            };
          }

          // console.log("rules subschema", subschema);
          schema = { ...schema, ...subschema };
        }
      }
    }
    console.log("rules schema", schema);
    return schema;
  };

  // var rules = {};
  var rules = getSchema(props.allprops.yupVType, props.allprops.validations);
  console.log("rules  :: ", rules);

  const [checked, setChecked] = useState(true);
  // console.log("allprops", props.allprops);
  useEffect(() => {
    // console.log("emailChk in useeffect in signupCusTextField:", emailChk);
    // if (emailChk.verifyStatus === 3) {
    // console.log(
    //   " in verifyStatus === 3 in useeffect in signup:",
    //   emailChk.verifyStatus
    // );
    methods.setValue("everify", "");
    // }
    return () => null;
  }, [emailChk]);

  useEffect(() => {
    // console.log("emailChk2 in useeffect in signupCusTextField:", emailChk2);
    // if (emailChk2.verifyStatus === 3) {
    // console.log(
    //   " in verifyStatus === 3 in useeffect in signup:",
    //   emailChk.verifyStatus
    // );
    methods.setValue("everify0", "");
    // }
    return () => null;
  }, [emailChk]);
  // }, [emailChk2]);
  const fieldStyle = {
    "& .MuiInputLabel-root": {
      color: "secondary.main",
      fontSize: "medium",
    },
    // "& .MuiFormHelperText-root": {
    //   //helpertext/error
    //   fontSize: "medium",
    //   // wordWrap: "break-word",
    //   // whiteSpace: "pre-wrap",
    //   width: "inherit",
    // },
    // "&  .MuiFormHelperText-root.Mui-error": {
    //   //<--- here
    //   // backgroundColor: "red",
    //   fontSize: "medium",
    //   m: 0,
    //   p: 0,
    //   width: "inherit",
    // },
    "& .MuiOutlinedInput-root": {
      //outline color
      borderColor: "secondary.main",
      "& input": {
        color: "textPrimary",
        p: 0,
        m: 1,
      },

      "& > fieldset": {
        borderColor: "secondary.main",
        // borderRadius: 0,
        color: "secondary.main",
      },
      "&:hover fieldset": {
        borderColor: "common.secondary5",
      },
      "&.Mui-focused fieldset": {
        borderColor: "secondary.main",
      },
    },
  };

  const selectOptions = {
    // "& .MuiListItem-root": {
    border: 1,
    borderColor: "secondary.main",
    // borderRadius: 8,

    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
    backgroundColor: "common.compbg",
    "& .MuiCheckbox-root": {
      color: "green",
    },
    "& .MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: "orange",
      },
    },
  };

  const [maskRef] = useIMask(
    {
      mask: props.allprops?.mask ?? "",
      // placeholderChar: ?? "",
      // mask: IMask.MaskedRange,
      // from: 1,
      // to: 1000,
      // // maxLength is optional parameter to set the length of mask. To input smaller values pad zeros at start
      // maxLength: 3,
      // autofix: false,
    },
    {
      onAccept: (e) => {
        // null
        // console.log("e in UseImask/OnAccept", e.target);
        // mask.updateValue();
        // setValue(e.target.value);
        // null;
      },
      onComplete: (e) => {
        console.log("e in UseImask/onComplete", e);
        // setValue(e.target.value);
        // null;
      },
    }
  );
  // const fieldMask = useIMask(
  //   {
  //     mask: props.allprops?.mask ?? "",
  //     // placeholderChar: ?? "",
  //     // mask: IMask.MaskedRange,
  //     // from: 1,
  //     // to: 1000,
  //     // // maxLength is optional parameter to set the length of mask. To input smaller values pad zeros at start
  //     // maxLength: 3,
  //     // autofix: false,
  //   }
  //   // {
  //   //   onAccept: (e) => {
  //   //     // null
  //   //     // console.log("e in UseImask/OnAccept", e);
  //   //     // setValue(e.target.value);
  //   //     // null;
  //   //   },
  //   //   onComplete: (e) => {
  //   //     // console.log("e in UseImask/onComplete", e);
  //   //     // setValue(e.target.value);
  //   //     // null;
  //   //   },
  //   // }
  // );
  console.log("props.allprops.colname::", props.allprops);

  // console.log("props.allprops?.mask::", props.allprops?.mask);
  // // console.log("maskRef::", fieldMask.maskRef);
  // if (props.allprops?.mask !== "") {
  //   console.log("maskRef::", fieldMask.ref);
  //   console.log("fieldMask.value::", fieldMask.value);
  //   console.log("fieldMask.typedValue::", fieldMask.typedValue);
  //   console.log("fieldMask.unmaskedValue::", fieldMask.unmaskedValue);
  // }

  // console.log("maskRef.current::", maskRef.current);
  // console.log("maskRef.current.value::", maskRef.current.value);
  // console.log("props::", props);
  // console.log("methods::", methods);
  // console.log(
  //   "methods.getFieldState(`${props.name}`).error",
  //   methods.getFieldState(`${props.name}`).error
  // );
  return (
    <>
      {props.allprops.type === "password" ? (
        <>
          <Controller
            name={props.allprops.colname}
            control={control}
            defaultValue={props.value}
            rules={{ ...rules }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <VisibilityPasswordTextField
                fullWidth
                required={props.allprops.required == "Y" ? true : false}
                // type is controlled in VisibilityPasswordTextField
                // type={props.type}
                label={props.allprops.collabel}
                value={value ?? ""}
                helperText={error ? error.message : props.allprops.helpertext}
                error={invalid}
                onChange={(e) => {
                  console.log(
                    "onChange:props.allprops.colname",
                    props.allprops.colname
                  );
                  console.log("onChange:value", value);
                  console.log("onChange:e.target.value", e.target.value);
                  console.log("onChange:isTouched", isTouched);
                  console.log("onChange:isDirty", isDirty);
                  // console.log("error", error);
                  // if (e.target.value !== value && !isTouched) {
                  if (e.target.value !== value) {
                    // console.log("e.target.value in onChange", e.target.value);
                    methods.setValue(
                      `${props.allprops.colname}`,
                      e.target.value,
                      {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      }
                    );
                    props.onfieldChg(e.target.value);
                  } else {
                    onChange(e);
                  }
                }}
                onBlur={(e) => {
                  onBlur();
                  if (!error) {
                    props.onfieldChg(e.target.value);
                  }
                }}
                color="secondary"
                size="small"
                margin="none"
                variant="outlined"
                onVisibilityChange={setIsPasswordVisible}
                isVisible={isPasswordVisible}
                sx={{
                  ...fieldStyle,
                }}
              />
            )}
          />
        </>
      ) : props.allprops.type === "int" ? (
        <>
          {/* <Badge
            color="primary"
            // variant="dot"
            // anchorOrigin={ vertical:'bottom', horizontal: 'right' }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={
              <Tooltip followCursor title={props.allprops.helpertext}>
                <span>i</span>
              </Tooltip>
            }
            // overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                m: -0,
                p: 0,
                color: "secondary.main",
                fontWeight: "bold",
                borderColor: "secondary.main",
                border: 0.2,
              },
            }}
          > */}

          <Controller
            name={props.allprops.colname}
            control={control}
            defaultValue={props.value}
            rules={{ ...rules }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error },
              formState,
            }) => (
              <>
                <Stack
                  direction="column"
                  sx={{
                    m: 0,
                    p: 0,
                    // border: 1,
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      m: 0,
                      p: 0,
                      // border: 1,
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "100%",
                    }}
                  >
                    <IconButton
                      aria-label="Minus 1"
                      color="secondary"
                      size="medium"
                      sx={{ m: -0.3, p: 0, border: 0 }}
                      onClick={() => {
                        methods.setValue(
                          `${props.allprops.colname}`,
                          Math.max(
                            parseInt(value ?? props.allprops.minval) - 1,
                            props.allprops.minval
                          ),
                          {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                          }
                        );
                        console.log(
                          "subtracted 1 new value ",
                          Math.max(parseInt(value) - 1, props.allprops.minval)
                        );
                        // console.log("New value ", e.target.value);
                        props.onfieldChg(
                          Math.max(parseInt(value) - 1, props.allprops.minval)
                        );
                        // addUpdCartItem(
                        //   props.item_id,
                        //   Math.max(parseInt(value) - 1, props.allprops.minval)
                        // );
                      }}
                    >
                      <RemoveCircleSharpIcon
                        // RemoveCircleIcon
                        // RemoveCircleTwoToneIcon
                        // RemoveCircleOutlineIcon
                        fontSize="large"
                        // sx={{ m: 0, p: 0, color: "secondary.main" }}
                      />
                    </IconButton>
                    <Tooltip followCursor title={props.allprops.helpertext}>
                      <TextField
                        fullWidth
                        inputRef={props.allprops?.mask ? maskRef : ref}
                        // label={props.label}
                        label=""
                        value={value ?? 0}
                        // helperText={
                        //   error ? error.message : props.allprops?.helpertext
                        // }

                        placeholder={props.allprops.placeholder ?? ""}
                        color="secondary"
                        size="small"
                        margin="none"
                        variant="outlined"
                        // size="small"
                        // margin="none"
                        error={invalid}
                        // onChange={onChange}
                        onChange={(e) => {
                          console.log("value in onChange", value);
                          console.log(
                            "e.target.value in onChange",
                            e.target.value
                          );
                          // console.log("isTouched", isTouched);
                          // console.log("isDirty", isDirty);
                          console.log("error in onChange", error);
                          // if (e.target.value !== value && !isTouched) {
                          const newValue = e.target.value
                            ? e.target.value
                            : props.allprops.minval;
                          console.log("newValue in onChange", newValue);

                          // if (e.target.value !== value) {
                          if (newValue !== value) {
                            console.log("e.target.value in onChange", newValue);

                            methods.setValue(
                              `${props.allprops.colname}`,
                              // e.target.value,
                              Math.max(
                                Math.min(
                                  parseInt(newValue),
                                  props.allprops.maxval
                                ),
                                props.allprops.minval
                              ),
                              {
                                shouldValidate: true,
                                shouldTouch: true,
                                shouldDirty: true,
                              }
                            );
                            props.onfieldChg(newValue);
                          } else {
                            onChange(e);
                          }
                        }}
                        onBlur={(e) => {
                          // console.log("onBlur", onBlur);
                          onBlur();
                          console.log("onBlur error", error, `${props.name}`);
                          console.log("onBlur e.target.value", e.target.value);
                          if (!error) {
                            if (e.target.value) {
                              // console.log("onBlur e.target.value2", e.target.value);
                              props.onfieldChg(e.target.value);
                              // console.log("onBlur e.target.value3", e.target.value);
                            }
                          } else {
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            // m: 0,
                            // p: 0,
                            width: props.inputWidth ?? 65,
                            "& input": {
                              textAlign: "center",
                              justifyContent: "center",
                              color: "textPrimary",
                              m: 0,
                              px: 0.4,
                              // px: 0,
                              // mx: 0,
                              // my: 0,
                              py: 0.4,
                              // pb: 0.4,
                              border: 1,
                              // borderTop: "2px",
                              // borderTopStyle: "solid",
                              // borderBottom: "2px",
                              // borderBottomStyle: "solid",
                              // borderRadius: 10,
                              borderRadius: "20px 20px 20px 20px",
                              borderColor: !error
                                ? "secondary.main"
                                : "error.main",
                            },
                          },
                          // "& .MuiTextField-root": {
                          //   mr: 0.2,
                          //   borderRadius: 0,
                          // },
                          "& .MuiInputLabel-root": {
                            color: "common.compbg",
                            fontSize: "medium",
                          }, //helpertext/error
                          "& .MuiFormHelperText-root": {
                            fontSize: "medium",
                          }, //outline color
                          "& .MuiOutlinedInput-root": {
                            m: 0,
                            p: 0,
                            "& > fieldset": {
                              // mr: 0.25,
                              // ml: 0.1,

                              border: 0,
                              // borderColor: "secondary.main",
                              borderRadius: 0,
                            },
                            "&:hover fieldset": {
                              // borderColor: "primary.main",
                              border: 0,
                              borderRadius: 0,
                              // borderColor: "secondary.main",
                            },
                            "&.Mui-focused fieldset": {
                              border: 0,
                              borderRadius: 0,
                              // borderColor: "primary.main",
                              // borderColor: "secondary.main",
                            },
                          },
                        }}
                      />
                    </Tooltip>
                    <IconButton
                      aria-label="plus 1"
                      color="secondary"
                      size="large"
                      sx={{ m: -0.3, p: 0, border: 0 }}
                      onClick={() => {
                        methods.setValue(
                          `${props.allprops.colname}`,
                          Math.min(
                            parseInt(value ?? 0) + 1,
                            props.allprops.maxval
                          ),
                          {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                          }
                        );
                        console.log("IconButton:value", value);
                        props.onfieldChg(
                          Math.min(
                            parseInt(value ?? 0) + 1,
                            props.allprops.maxval
                          )
                        );
                        // addUpdCartItem(
                        //   props.item_id,
                        //   Math.min(parseInt(value) + 1, props.allprops.maxval)
                        // );
                      }}
                    >
                      <AddCircleSharpIcon
                        // AddCircleIcon
                        // AddCircleTwoToneIcon
                        // AddCircleOutlineIcon
                        fontSize="large"
                      />
                    </IconButton>
                  </Stack>
                  <Stack
                    // direction="row"
                    display={
                      methods.getFieldState(`${props.allprops.colname}`).error
                        ? ""
                        : "none"
                    }
                    sx={{
                      // mt: -1.5,
                      // mb: -1.5,
                      p: 0,
                      m: 0,
                      // border: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "error.main",
                      fontSize: "small",
                    }}
                  >
                    {methods.getFieldState(`${props.allprops.colname}`).error
                      ? methods.getFieldState(`${props.allprops.colname}`).error
                          ?.message
                      : ""}
                    {/* Error */}
                    {/* <ErrorMessage
              errors={methods.formState.errors}
              name={props.name}
              as="p"
            /> */}
                  </Stack>{" "}
                </Stack>
              </>
            )}
          />

          {/* </Badge> */}
        </>
      ) : // </Stack>

      // </>
      props.allprops.type === "zip" ||
        props.allprops.type === "tel" ||
        props.allprops.type === "text" ? (
        <>
          <Controller
            name={props.allprops.colname}
            control={control}
            rules={{ ...rules }}
            defaultValue={props.value}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                disabled={props.disabled}
                fullWidth
                required={props.allprops.required == "Y" ? true : false}
                label={props.allprops.collabel}
                value={value ?? ""}
                helperText={
                  error
                    ? error.message
                    : props.allprops.colname === "everify" &&
                      useCustStore.getState().emailChk.helperText
                    ? useCustStore.getState().emailChk.helperText
                    : props.allprops?.helpertext
                }
                inputRef={props.allprops?.mask ? maskRef : ref}
                // ref={maskRef}
                placeholder={props.allprops.placeholder ?? ""}
                color="secondary"
                size="small"
                margin="none"
                variant="outlined"
                error={invalid}
                // onChange={onChange}
                onChange={(e) => {
                  // console.log(
                  //   "onChange:props.allprops.colname",
                  //   props.allprops.colname
                  // );
                  // console.log("onChange:vale", value);
                  // console.log("onChange:e.target.value", e.target.value);
                  // console.log("error in onChange", error);
                  // if (e.target.value !== value && !isTouched) {
                  console.log("maskRef::", maskRef);
                  console.log("fieldMask.value::", maskRef.value);
                  // console.log("fieldMask.typedValue::", fieldMask.typedValue);
                  console.log(
                    "fieldMask.unmaskedValue::",
                    maskRef.unmaskedValue
                  );
                  if (e.target.value !== value) {
                    // console.log("e.target.value in onChange2", e.target.value);
                    methods.setValue(
                      `${props.allprops.colname}`,
                      e.target.value,
                      // fieldMask.value,
                      {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true,
                      }
                    );
                    // fieldMask.setValue(e.target.value);
                    // fieldMask.updateValue();
                    // onBlur();
                    props.onfieldChg(e.target.value);
                  } else {
                    onChange(e);
                  }
                }}
                onBlur={(e) => {
                  // console.log("onBlur", onBlur);
                  onBlur();
                  // console.log("onBlur error", error, `${props.name}`);
                  console.log("onBlur e.target.value", e.target.value);
                  if (!error) {
                    if (e.target.value) {
                      // console.log("onBlur e.target.value2", e.target.value);
                      props.onfieldChg(e.target.value);
                      // console.log("onBlur e.target.value3", e.target.value);
                    }
                  }
                }}
                // FormHelperTextProps={{
                //   style: {
                //     // backgroundColor: "transparent",
                //     margin: 0,
                //     padding: 0,
                //     alignItems: "flex-start",
                //     fontSize: "medium",
                //     width: "inherit",
                //     overflow: "hidden",
                //     textOverflow: "ellipsis",
                //   },
                // }}
                sx={{
                  ...fieldStyle,
                }}
              />
            )}
          />
        </>
      ) : props.allprops.type === "number" ? (
        //use type int for plus/minus field
        <>
          <Controller
            name={props.allprops.colname}
            control={control}
            defaultValue={props.value}
            rules={{ ...rules }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error },
              formState,
              onfieldChg,
            }) => (
              <>
                <NumberInput2
                  // name="example"
                  value={value ?? ""}
                  precision={0}
                  decimalChar=" "
                  thousandChar=" "
                  label={props.allprops.colabel}
                  onChange={onChange}
                  color="secondary"
                  size="small"
                  margin="none"
                  variant="outlined"
                  onBlur={(e) => {
                    onBlur();
                    if (!error) {
                      if (e.target.value) {
                        props.onfieldChg(e.target.value);
                      }
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  sx={{
                    ...fieldStyle,
                  }}
                />
              </>
            )}
          />
        </>
      ) : props.allprops.type === "menu" ? (
        <>
          <Controller
            name={props.allprops.colname}
            control={control}
            defaultValue={props.value}
            rules={{ ...rules }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, error },
              formState,
            }) => (
              <>
                {/* <InputLabel id="demo-simple-select-label">Role</InputLabel> */}
                {/* <Select
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //  color="secondary"
                  //  value="Role"
                  //  label="Beast"
                  onChange={(e) => props.onfieldChg(e.target.value)}
                  onBlur={(e) => {
                    onBlur();
                    if (!error) {
                      if (e.target.value) {
                        props.onfieldChg(e.target.value);
                      }
                    }
                  }}
                  sx={{
                    ...selectOptions,
                  }}
                  //  onChange={handleChange}
                > */}
                <TextField
                  disabled={props.disabled}
                  fullWidth
                  required={props.allprops.required == "Y" ? true : false}
                  inputRef={props.allprops?.mask ? maskRef : ref}
                  label={props.allprops.collabel}
                  value={value ?? ""}
                  helperText={
                    error ? error.message : props.allprops?.helpertext
                  }
                  placeholder={props.allprops.placeholder ?? ""}
                  select
                  error={invalid}
                  onChange={(e) => {
                    if (props.menuClickFunc) {
                      props.menuClickFunc(e.target.value);
                    }
                    console.log("onchange e", e.target.value);
                    methods.setValue(
                      `${props.allprops.colname}`,
                      e.target.value
                    );
                  }}
                  onBlur={(e) => {
                    onBlur();
                    if (!error) {
                      if (e.target.value) {
                        props.onfieldChg(e.target.value);
                      }
                    }
                  }}
                  color="secondary"
                  size="small"
                  margin="none"
                  variant="outlined"
                  sx={{
                    ...fieldStyle,
                  }}
                  // SelectProps={{
                  //   renderValue: (value) => {
                  //     console.log("renderValue", value);
                  //     return props.menuprops.filter(
                  //       (item) => item.menulist_cd === value
                  //     )[0].text;
                  //   },
                  // }}
                >
                  {props.menuprops.map((item, j) => (
                    <MenuItem
                      onClick={(e) => {
                        console.log("e.target.value", e.target.value);
                        // if (props.menuClickFunc) {
                        //   props.menuClickFunc(e.target.value);
                        // }
                      }}
                      value={item.menulist_cd}
                    >
                      {item.menulist_value}
                    </MenuItem>
                  ))}
                </TextField>
                {/* </Select> */}
              </>
            )}
          />
        </>
      ) : props.allprops.type === "checkbox" ? (
        <>
          <Stack
            direction="row"
            sx={{ border: 0, alignItems: "center", p: 0.5, m: 0 }}
          >
            <Box
              sx={{
                typography: "h7",
                color: "secondary.main",
                // fontWeight: "bold",
              }}
            >
              {props.allprops.introline}
            </Box>

            <Controller
              control={control}
              name={props.allprops.colname}
              defaultValue={checked}
              rules={{ ...rules }}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  sx={{
                    py: 0.2,
                    color: "secondary.main",
                    "&.Mui-checked": {
                      color: "secondary.main",
                    },
                  }}
                  checked={checked}
                  value={value}
                  onChange={(e) => {
                    setChecked(!checked);
                    methods.setValue(`${props.allprops.colname}`, !checked);
                  }}
                />
              )}
            />
          </Stack>
        </>
      ) : props.allprops.type === "date" ? (
        <>
          {/* <Stack
            sx={{
              border: 1,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              p: 1,
            }}
          > */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name={props.allprops.colname}
              rules={{ ...rules }}
              // defaultValue={Date.now()}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label={props.allprops.introline}
                  value={value}
                  color="secondary"
                  onChange={onChange}
                  sx={{
                    ...fieldStyle,
                    width: "100%",
                  }}
                  slotProps={{
                    textField: {
                      color: "textSecondary",
                      //     InputProps: {
                      //       endAdornment: (
                      //         <InputAdornment
                      //           sx={{
                      //             color: "secondary.main",
                      //             // p: 0,
                      //             // m: 0,
                      //             // // border: 1,
                      //           }}
                      //           // position="end"
                      //         >
                      //           <CalendarIcon sx={{ p: 0 }} />
                      //         </InputAdornment>
                      //       ),
                      //     },
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {/* </Stack> */}
        </>
      ) : (
        // default, will be used if no type specified.
        <>
          <Controller
            name={props.allprops.colname}
            control={control}
            // rules={
            //   props.allprops.subfieldType
            //     ? { required: { value: true, message: "hello world" } }
            //     : {}
            // }
            defaultValue={props.value}
            rules={{ ...rules }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                // disabled={props.disabled}
                fullWidth
                required={props.allprops.required == "Y" ? true : false}
                inputRef={props.allprops?.mask ? maskRef : ref}
                label={props.allprops.collabel}
                value={value ?? ""}
                helperText={error ? error.message : props.allprops?.helpertext}
                placeholder={props.allprops.placeholder ?? ""}
                // name={props.name}
                // control={control}
                // defaultValue={props.value}
                // render={({
                //   field: { onChange, onBlur, value, name, ref },
                //   fieldState: { invalid, error },
                //   formState,
                // }) => (
                //   <>
                //     <TextField
                //       required={props.required}
                //       type={props.type}
                //       label={props.label}
                //       value={value ?? ""}
                //       helperText={error ? error.message : props.helpertext}
                error={invalid}
                onChange={onChange}
                onBlur={(e) => {
                  onBlur();

                  if (!error) {
                    if (e.target.value) {
                      props.onfieldChg(e.target.value);
                    }
                  }
                }}
                color="secondary"
                size="small"
                margin="none"
                variant="outlined"
                sx={{
                  ...fieldStyle,
                }}
              />
            )}
          />
        </>
      )}
    </>
  );
};
