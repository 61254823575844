import { create } from "zustand";
// import { persist, createJSONStorage } from "zustand/middleware";
const initialState = {
  cart: [],
  accepted: false,
  ipjson: [],
  filterParam: "",
  coreItemId: "3",
};
const useCartStore = create(
  // persist(
  (get, set) => ({
    ...initialState,
    reset: () => {
      set((state) => ({ cart: [] }));
    },
    // cart?: [],
    // accepted: false,
  })
  // { name: "zu_cart", storage: createJSONStorage(() => localStorage) }
  // )
);

export { useCartStore };
