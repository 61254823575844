import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import StyledHeader from "./StyledHeader";
import Divider from "@mui/material/Divider";
import LogoBr from "./LogoBR";
import OutlinedDiv from "./OutlinedDiv";
function StyledSection(props) {
  const {
    children,
    bgcolor,
    to,
    validated,
    secVm,
    rounded,
    appendSx,
    logo,
    ...otherProps
  } = props;

  return (
    <>
      {props.logo && (
        <Stack>
          <LogoBr />
        </Stack>
      )}
      <Stack
        // component="fieldset"
        sx={{
          // "& fieldset": { border: "none" },
          // "& legend": {
          //   fontSize: "0.75rem",
          //   fontWeight: 500,
          //   letterSpacing: "0.6px",
          //   lineHeight: "20px",
          // },
          mb: props.secVm ?? 4,
          // mx: 0.5,
          p: 1,
          bgcolor: bgcolor ?? "common.compbg",
          // borderColor: "secondary.main",
          borderColor: "common.compbg",
          borderRadius: rounded === "N" ? "0x 0x 0x 0x" : "10px 10px 10px 10px",
          ...appendSx,
        }}
      >
        {/* <OutlinedDiv label={props.headerText}> */}
        {/* <legend>{props.headerText}</legend> */}
        {props.headerText && (
          <>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                // alignItems: "flex-start",
                // justifyContent: "space-between",
                mx: { xs: 2, sm: 2 },
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  // mx: 1,
                  typography: "h4",
                  color: "textSecondary",
                  // bgcolor: "common.compbg",
                  fontWeight: "bold",
                  // textAlign: "center",
                  // borderRadius: "20px 20px 20px 20px",
                  fontFamily: "default",
                  textAlign: "center",
                }}
              >
                {props.headerText}
              </Box>
              {/* <Box
                sx={{
                  // width: "100%",
                  // mx: 1,
                  typography: "h4",
                  color: "secondary.main",
                  bgcolor: "common.compbg",
                  fontWeight: "bold",
                  // textAlign: "center",
                  // borderRadius: "20px 20px 20px 20px",
                  fontFamily: "default",
                }}
              >
                {props.headerText2}
              </Box> */}
            </Stack>
            <Divider
              sx={{
                // color: "common.secondary7",
                color: "primary.main",
                bgcolor: "primary.main",
                border: 2,
                // width: { xs: "80%", sm: "50%" },
                // ml: 2,
                mx: { xs: 1, sm: 1 },
                mb: 2,
                // alignItems: "flex-start",
                // justifyContent: "flex-start",
              }}
            />
          </>
        )}
        <Stack
          sx={{
            p: 0,
          }}
        >
          {children}
        </Stack>
        {/* </OutlinedDiv> */}
      </Stack>
    </>
  );
}

// StyledSection.propTypes = {
//   headertext: PropTypes.string.isRequired,
// };

export default StyledSection;
