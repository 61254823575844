import React, { useState, useRef, Suspense } from "react";
import useTheme from "@mui/material/styles/useTheme";

// import Card from "@mui/material/Card";
import { Card, CardMedia } from "@mui/material";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";

// import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

// import ForwardIcon from "@mui/icons-material/Forward";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
// import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";

import StraightSharpIcon from "@mui/icons-material/StraightSharp";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import GppMaybeIcon from "@mui/icons-material/GppMaybe";

// import styled from "@mui/material/styles/styled";
// import Accordion from "@mui/material/Accordion";
import { Accordion } from "@mui/material";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails } from "@mui/material";
import { AccordionSummary } from "@mui/material";

// import Collapse from "@mui/material/Collapse";
import { Collapse } from "@mui/material";

import { Image } from "./OverviewIcon";

import CardContent from "@mui/material/CardContent";

// import { useOverviewStore } from "../store/zustandOverviewStore";
import { useCartStore } from "../store/zuCartStore";

import { UseTokenStore } from "../store/zuTokenStore";

import { SignUpCusTxtFld } from "./SignUpCusTxtFld";
import { addUpdCartItem } from "../store/zuCartActions";
import { FormProvider, useForm } from "react-hook-form";

import StyledButton from "../shared/StyledButton";
import "./Overview.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import StyledSection from "../shared/StyledSection";

import { useRouteLoaderData, useLocation } from "react-router-dom";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import LaunchSharpIcon from "@mui/icons-material/LaunchSharp";
import Tooltip from "@mui/material/Tooltip";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
  // return <> {...other}</>;
})(({ theme, expand }) => ({
  // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // transform: !expand ? "`${...other}` +" : "`${...other}` -",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AccordionStyle = {
  m: 0,
  p: 0,
  borderRadius: "10px 10px 10px 10px",
  // bgcolor: "primary.main",
};

const AccordionSumStyle = {
  mx: 0,
  my: 0,
  px: 0.2,
  py: 0,
  border: "0px",
  bgcolor: "primary.main",
  flexDirection: "row",
  "& .MuiAccordionSummary-content": {
    mx: 0,
    my: 0,
    px: 0.2,
    py: 0,
  },
};

const SumText = {
  typography: "h5",
  color: "textSecondary",
  fontWeight: "medium",
  textAlign: "left",
};
const AccordionDtlStyle = {
  m: 0,
  px: 0,
  py: 0,
  bgcolor: "primary.main",
  // bgcolor: "common.secondary2",
  // borderTop: "1px",
  // borderTopStyle: "solid",
  // borderTopColor: "common.secondary5",
};
const DtlText = {
  typography: "h6",
  fontWeight: "light",
  textAlign: "left",
  color: "textPrimary",
};
const SlideStack = {
  position: "relative",
  bgcolor: "common.compbg",
  mx: 0,
  my: 0,
  p: 2.5,
  // border: 1,
  // borderRadius: "10px 10px 10px 10px",
};
const KeenSliderSlide = {
  bgcolor: "common.compbg",
  // bgcolor: "primary.main",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  m: 0,
  px: 0,
  py: 0,
  mx: 0,
  // pt: 1,
  // border: "0.0px",
  // borderStyle: "solid",
  // borderRadius: "10px 10px 10px 10px",
};
const menuIconSx = {
  m: 0,
  p: 0,
  color: "secondary.main",
  fontSize: "30px",
};
export function OverviewCard(props) {
  const { slides, cart, mode } = props;
  const methods = useForm({
    shouldUnregister: false,
    mode: "onBlur",
  });

  const [added, setAdded] = useState(false);
  const theme = useTheme();
  // const cart = useCartStore((state) => state.cart);

  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [loaded2, setLoaded2] = useState(false);
  const [sliderRef2, instanceRef2] = useKeenSlider(
    {
      initial: 0,
      loop: false,
      slideChanged(slider) {
        setCurrentSlide2(slider.track.details.rel);
      },
      created() {
        setLoaded2(true);
      },
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: "1", spacing: 6 },
        },
        "(min-width: 400px)": {
          slides: { perView: "1", spacing: 6 },
        },
        "(min-width: 820px)": {
          slides: { perView: "2", spacing: 6 },
        },
        "(min-width: 1000px)": {
          slides: { perView: "2", spacing: 6 },
        },
      },
      slides: { perView: "2", spacing: 6 },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            // slider.next();
          }, 6000);
        }
        const observer = new ResizeObserver(function () {
          slider.update();
        });
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });

        slider.on("created", () => {
          observer.observe(slider.container);
        });
        slider.on("destroyed", () => {
          observer.unobserve(slider.container);
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  const [expanded, setExpanded] = React.useState(false);
  // use following code if need to expand one at a time
  // const handleExpandClick = (i) => {
  //   setExpanded(expanded === i ? -1 : i);
  // };
  // expand all option
  const useScroll = () => {
    const csRef = useRef(null);
    const executeScrollCS = () =>
      csRef.current.scrollIntoView(
        // true,
        {
          behavior: "smooth",
          block: "center",
        }
      );

    return [executeScrollCS, csRef];
  };
  const handleExpandClick = () => {
    if (expanded) {
      executeScrollCS();
    }
    setExpanded(!expanded);
  };

  // const { data2, data3 } = useRouteLoaderData("beast");

  const coreITcart = cart.filter((cartItem) => cartItem.item_type === "P");
  const includes = slides.filter(
    (included) => included.ui_data.pricetype.toLowerCase() == "included"
  );

  // const tiles2 = concatTiles(tiles);
  // console.log("slides:", slides);
  // console.log("instanceRef2:", instanceRef2);
  // console.log("sliderRef2:", sliderRef2);
  // console.log("instanceRef2:", instanceRef2.current?.options?.slides.perView);
  // const {res, res2, res3} = useLoaderData();
  // console.log("res", res)
  // console.log(data2);
  const indSize = 25;
  const [executeScrollCS, csRef] = useScroll();

  return (
    <Suspense fallback={<CircularProgress size={25} color="secondary" />}>
      <Stack
        direction="column"
        sx={{
          // width: "100%",
          // border: 1,
          display: includes.length > 0 ? "block" : "none",
          // borderColor: "common.black",
          // m: 0,
          // py: 0,
          // px: 0,
        }}
      >
        <StyledSection
          headerText="Core Services Package"
          // bgcolor="common.compbg"
          id="cs-top"
          secVm={props.secVm}
          // secVm="0"
          // appendSx={{ mb: 0, pb: 0 }}
        >
          <Stack
            direction="column"
            sx={{
              // border: 1,
              mt: -2.5,
              mb: 1,
              p: 0,
              // width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                typography: "subtitle1",
                color: "textSecondary",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Includes all modern essentials
            </Box>
          </Stack>
          <div ref={csRef}> </div>
          <Stack
            sx={{
              p: 0,
              m: 0,
              // width: "100%",
              // border: 1,
            }}
          >
            {/* {data3.data && data3.data.length > 0 ? ( */}
            {includes && includes.length > 0 ? (
              <Stack
                sx={{
                  ...SlideStack,
                  // width: "100%",
                  // border: 1,
                }}
              >
                <Stack
                  sx={{
                    bgcolor: "primary.main",
                    p: 0,
                    m: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "100%",
                    // border: 1,
                    // border: 1,
                    // border: 4,
                    // borderRadius: "20px 20px 20px 20px",
                    // borderColor: "primary.main",
                  }}
                >
                  <Stack
                    ref={sliderRef2}
                    className="keen-slider"
                    direction="row"
                    sx={{
                      bgcolor: "common.compbg",
                      // width: "100%",
                      // border: 1,
                      // border: 1,
                      // justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    {includes.map((dscrpt, j2) => (
                      <Stack
                        // className="keen-slider__slide"
                        sx={{
                          bgcolor: "common.compbg",
                          p: 0,
                          m: 0,
                          p: 0,
                          my: 0.2,
                          justifyContent: "center",
                          alignItems: "center",
                          // width: "100%",
                          // border: 1,
                          // border: 1,
                        }}
                      >
                        <Card
                          variant="outlined"
                          id="#addon"
                          elevation={0}
                          key={j2}
                          className="keen-slider__slide"
                          sx={{
                            ...KeenSliderSlide,
                            // border: "solid",
                            // borderWidth: "2px",
                            // borderColor: "primary.main",
                            borderRadius: "20px 20px 20px 20px",
                            boxShadow: 3,
                            justifyContent: "center",
                            alignItems: "center",
                            // bgcolor: "primary.main",
                            // mx: 2,
                            // border: 1,
                            // width: "100%",
                            // border: 1,
                          }}
                        >
                          <CardMedia>
                            <Stack
                              direction="row"
                              sx={{
                                mb: 0,
                                p: 0,
                                alignItems: "center",
                                bgcolor: "primary.main",
                                // width: "100%",
                                height: "180px",
                                // background: `
                                //     radial-gradient(27.67px at 50% calc(100% - 38.25px),hsl(43, 74%, 49%,1) 99%,#0000 101%) calc(50% - 30px) 0/60px 100%,
                                //     radial-gradient(27.67px at 50% calc(100% + 23.25px),#0000 99%,hsl(43, 74%, 49%,1) 101%) 50% calc(100% - 15px)/60px 100% repeat-x;
                                //   // -webkit-mask: var(--mask);
                                //   //         mask: var(--mask);
                                // `,
                              }}
                            >
                              <Image
                                type={dscrpt.ui_data.content.image}
                                primaryColor={theme.palette.secondary.main}
                                accentColor={theme.palette.common.secondary2}
                                height="130px"
                              />
                            </Stack>
                            {/* {coreITcart[0] && coreITcart[0].quantity > 0 && (
                              <Box
                                sx={{
                                  typography: "h4",
                                  position: "absolute",
                                  // bottom: 0,
                                  top: 0,
                                  right: 0,
                                  // width: "100%",
                                  bgcolor: "primary.main",
                                  color: "secondary.main",
                                  padding: "0px",
                                }}
                              >
                                <IconButton
                                  aria-label="close"
                                  onClick={() => {
                                    navigate(`${route}`, {
                                      state: { title: "Assign Svc -> Users" },
                                    });
                                  }}
                                >
                                  <MoreVertIcon
                                    // <NorthSharpIcon
                                    color="textPrimary"
                                    fontSize="large"
                                    sx={{
                                      p: 0,
                                      m: 0,
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            )} */}
                          </CardMedia>
                          {/* <Stack
                            sx={{
                              bgcolor: "common.compbb",
                              border: 1,
                            }}
                          > */}
                          {/* <CardContent
                            sx={{
                              bgcolor: "common.compbg",
                              // border: 1,
                              m: 0,
                              p: 0,
                            }}
                          > */}
                          <Stack
                            direction="column"
                            sx={{
                              m: 0,
                              p: 0,
                              alignItems: "center",

                              // border: "solid",
                              // borderWidth: "5px",
                              // borderColor: "primary.main",
                              // borderRadius: "20px 20px 20px 20px",
                              // border: 1,
                              // bgcolor: "common.compbg",
                              // justifyContent: "space-around",
                            }}
                          >
                            {/* Service Image */}
                            {/* <Stack
                              direction="row"
                              sx={{
                                m: 0,
                                py: 0,
                                my: 0,
                                alignItems: "center",
                                // mx: 0,
                                // my: 2,
                                // shapeOutside: circle(),
                                bgcolor: "primary.main",
                                // width: "55%",
                                width: "100%",
                                height: "140px",
                                // borderRadius: "50%",
                                // borderRadius: "10px 10px 10px 10px",
                              }}
                            >
                              <Image
                                type={dscrpt.ui_data.content.image}
                                primaryColor={theme.palette.secondary.main}
                                accentColor={theme.palette.common.secondary2}
                                height="140px"
                              />
                            </Stack> */}
                            {/* Service Heading */}
                            <Stack
                              direction="row"
                              sx={{
                                p: 0,
                                m: 0.25,
                                mt: 2,
                                // justifyContent: "flex-start",
                                // border: 1,
                                justifyContent: "center",
                                height: "75px",
                              }}
                            >
                              <Box
                                sx={{
                                  p: 0,
                                  m: 0,
                                  typography: "h4",
                                  fontWeight: "bold",
                                  // color: "common.black",
                                  color: "textPrimary",

                                  // textAlign: "left",
                                }}
                              >
                                {j2 + 1} - {dscrpt.ui_data.introline}
                              </Box>
                            </Stack>

                            {/* Service Summary */}
                            <Stack
                              direction="column"
                              sx={{
                                m: 0,
                                p: 0,
                                alignItems: "center",
                                // mx: 0,
                                mt: 1,
                                height: "100%",
                                // bgcolor: "common.compbg",
                                // border: 1,
                                // shapeOutside: circle(),
                                // border: 1,
                                // height: "140px",
                                // height: { xs: "110px", sm: "130px", md: "160px" },
                              }}
                            >
                              <ExpandMore
                                // expand={expanded === j}
                                expand={expanded}
                                // onClick={() => handleExpandClick(j2)}
                                onClick={() => handleExpandClick()}
                                // onMouseOver={() => handleExpandClick()}
                                // onMouseOut={() => handleExpandClick()}
                                // aria-expanded={expanded === j}
                                aria-expanded={expanded}
                                aria-label="show more"
                                sx={{
                                  p: 0,
                                  m: 0,
                                  borderRadius: 0,
                                  // bgcolor: "common.compbg",
                                  ":hover": {
                                    bgcolor: "common.compbg",
                                  },
                                  ".MuiTouchRipple-ripple .MuiTouchRipple-child":
                                    {
                                      borderRadius: 0,
                                      bgcolor: "common.compbg",
                                    },
                                }}
                              >
                                <Stack
                                  direction="row"
                                  sx={{
                                    p: 0,
                                    m: 0,
                                    mx: 1,
                                    // bgcolor: "common.compbg",
                                    // border: 1,
                                    // justifyContent: "row",
                                  }}
                                >
                                  <Stack
                                    // direction="column"
                                    sx={{
                                      p: 0,
                                      m: 0,
                                      // border: 1,
                                      justifyContent: "flex-start",
                                      // bgcolor: "common.compbg",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        typography: "h5",
                                        p: 0,
                                        m: 0,
                                        // minHeight: 65,
                                        // bgcolor: "common.compbg",
                                        color: "textSecondary",
                                        fontWeight: "bold",
                                        // justifyContent: "center",
                                        // textAlign: "left",
                                      }}
                                    >
                                      {dscrpt.ui_data.content.desc}
                                    </Box>
                                  </Stack>
                                  <Stack
                                    sx={{
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      m: 0,
                                      p: 0,
                                      // border: 1,
                                      // pl: 1,
                                      // bgcolor: "common.compbg",
                                    }}
                                  >
                                    {expanded ? (
                                      <RemoveOutlinedIcon
                                        // <NorthSharpIcon
                                        color="textPrimary"
                                        fontSize="large"
                                        sx={{
                                          p: 0,
                                          m: 0,
                                        }}
                                      />
                                    ) : (
                                      <AddOutlinedIcon
                                        // <SouthSharpIcon
                                        color="textPrimary"
                                        fontSize="large"
                                        sx={{
                                          p: 0,
                                          m: 0,
                                        }}
                                      />
                                    )}
                                  </Stack>
                                </Stack>
                                {/* </IconButton> */}
                              </ExpandMore>
                            </Stack>
                          </Stack>
                          {/* Service Details with dropdowns */}
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent key={j2}>
                              {dscrpt.ui_data.expansion.map((par, i2) => (
                                <Stack
                                  key={i2}
                                  direction="column"
                                  sx={{
                                    px: 0,
                                    pb: 0,
                                    m: 0,
                                    borderRadius: "20px 20px 20px 20px",
                                    // border: 1,
                                  }}
                                >
                                  <>
                                    {par.sections.map((sec, k2) => (
                                      <Stack
                                        key={i2 * 100 + k2}
                                        direction="column"
                                        sx={{
                                          px: 0,
                                          pb: 0,
                                          m: 0.2,
                                        }}
                                      >
                                        <Accordion
                                          square="false"
                                          sx={{
                                            ...AccordionStyle,
                                          }}
                                          key={i2 * 100 + k2}
                                          elevation={0}
                                          // square
                                          disableGutters
                                          TransitionProps={{
                                            unmountOnExit: true,
                                          }}
                                        >
                                          <AccordionSummary
                                            expandIcon={
                                              <ArrowDropDownCircleOutlinedIcon
                                                // color="secondary"
                                                sx={
                                                  {
                                                    // bgcolor: "common.black",
                                                    // borderRadius: "50%",
                                                    // bgcolor: "primary.main",
                                                  }
                                                }
                                              />
                                            }
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                            sx={{
                                              ...AccordionSumStyle,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                ...SumText,
                                              }}
                                            >
                                              {sec.sec_name}
                                            </Box>
                                          </AccordionSummary>
                                          <List
                                            sx={{
                                              // listStyleType: "disc",
                                              pl: 1.5,
                                              pr: 0,
                                              py: 0,
                                              m: 0,
                                              bgcolor: "primary.main",
                                            }}
                                          >
                                            {sec.sec_dtl.map((sec_dtl, l) => (
                                              <AccordionDetails
                                                key={i2 * 100 + k2 * 10 + l}
                                                sx={{
                                                  ...AccordionDtlStyle,
                                                  // border: 1,
                                                }}
                                              >
                                                <Stack
                                                  direction="row"
                                                  key={i2 * 100 + k2 * 10 + l}
                                                  sx={{
                                                    p: 0,
                                                    m: 0,
                                                    my: 1,
                                                    alignItems: "flex-start",
                                                    justifyContent:
                                                      "flex-start",
                                                    // border: 1,
                                                    // bgcolor: "primary.main",
                                                  }}
                                                >
                                                  <CheckCircleOutlineIcon
                                                    sx={{
                                                      color: "textSecondary",
                                                      p: 0,
                                                      mt: 0.2,
                                                      // border: 1,
                                                    }}
                                                  />
                                                  <ListItem
                                                    key={i2 * 100 + k2 * 10 + l}
                                                    sx={{
                                                      ...DtlText,
                                                      // typography: "h6",
                                                      // fontWeight: "small",
                                                      alignItems: "flex-start",
                                                      justifyContent:
                                                        "flex-start",
                                                      alignText: "top",
                                                      p: 0,
                                                      ml: 1.5,
                                                      my: 0,
                                                      // border: 1,
                                                      // display: "list-item",
                                                    }}
                                                  >
                                                    {sec_dtl.line}
                                                  </ListItem>
                                                </Stack>
                                              </AccordionDetails>
                                            ))}
                                          </List>
                                        </Accordion>
                                      </Stack>
                                    ))}
                                  </>
                                </Stack>
                              ))}

                              <Stack
                                direction="row"
                                sx={{
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  // bgcolor: "common.compbg",
                                  my: 0,
                                  p: 0,
                                }}
                              >
                                {/* <ScrollTo selector="#addon"> */}
                                <ExpandMore
                                  expand={expanded}
                                  onClick={() => handleExpandClick()}
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                  sx={{
                                    p: 0,
                                    m: 0,
                                    ":hover": {
                                      bgcolor: "common.compbg",
                                    },
                                    "&:clicked": {
                                      bgcolor: "common.compbg",
                                    },
                                  }}
                                >
                                  {expanded ? (
                                    <RemoveOutlinedIcon
                                      // <NorthSharpIcon
                                      color="textPrimary"
                                      fontSize="large"
                                      sx={{
                                        p: 0,
                                        m: 0,
                                      }}
                                    />
                                  ) : (
                                    // <NorthSharpIcon
                                    //   color="secondary"
                                    //   fontSize="large"
                                    //   sx={{
                                    //     p: 0,
                                    //     m: 0,
                                    //   }}
                                    // />
                                    <AddOutlinedIcon
                                      // <SouthSharpIcon
                                      color="textPrimary"
                                      fontSize="large"
                                      sx={{
                                        p: 0,
                                        m: 0,
                                      }}
                                    />
                                  )}
                                </ExpandMore>

                                {/* </ScrollTo> */}
                              </Stack>
                            </CardContent>
                          </Collapse>
                          {/* </Stack> */}
                          {/* </CardContent> */}
                        </Card>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
                {loaded2 && instanceRef2.current && (
                  <>
                    <KeenSliderArrows
                      currentSlide={currentSlide2}
                      sliderInstance={instanceRef2}
                    />
                  </>
                )}

                {loaded2 && instanceRef2.current && (
                  <Box className="dots" sx={{ bgcolor: "common.compbg" }}>
                    {[
                      ...Array(
                        instanceRef2.current.track.details.slides.length
                      ).keys(),
                    ].map((idx) => {
                      return (
                        <button
                          key={idx}
                          // onClick={() => instanceRef2.current?.moveToIdx(idx)}
                          onClick={() => {
                            console.log(
                              "instanceRef2.current?.options?.slides.perView",
                              instanceRef2.current?.options?.slides.perView
                            );
                            console.log("currentSlide2:", currentSlide2);
                            instanceRef2.current?.moveToIdx(idx);
                            // + instanceRef2.current?.options?.slides.perView
                          }}
                          className={
                            "dot" +
                            (currentSlide2 === idx
                              ? // idx + instanceRef2.current?.options?.slides.perView
                                " active"
                              : "") +
                            (UseTokenStore.getState().colorMode === "dark"
                              ? " dark"
                              : " light")
                          }
                        ></button>
                      );
                    })}
                  </Box>
                )}
              </Stack>
            ) : (
              <CircularProgress size={indSize} color="secondary" />
            )}
          </Stack>
          <Stack
            direction="row"
            // useFlexGap
            sx={{
              // mt: -1,
              // mb: 1,
              m: 1,
              p: 0,
              // bgcolor: "common.compbg",
              alignItems: "stretch",
              justifyContent: "center",
              // borderRadius: "20px 20px 20px 20px",
              // bgcolor: "primary.main",
              display: "flex",
            }}
          >
            <Stack
              sx={{
                // mb: 2,
                // mt: -1,
                py: 0.5,
                px: 0,
                my: 0,
                // width: "60%",
                // pb: 5,
                // mx: 20,
                // border: 1,
                borderRadius: "20px 20px 20px 20px",
                bgcolor: "primary.main",
                justifyContent: "center",
                alignItems: "center",
                // display: "flex",
              }}
              direction="row"
            >
              {/* Label */}
              <Stack
                sx={{
                  m: 0,
                  px: 1,
                  py: 0,

                  // border: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    typography: "h5",
                    color: "textSecondary",
                    fontWeight: "medium",
                  }}
                >
                  # of Users:
                </Box>
              </Stack>
              {!added ? (
                <StyledButton
                  onClick={() => setAdded(true)}
                  sx={{
                    borderRadius: "20px 20px 20px 20px",
                    ml: 2,
                    mr: -2,
                    my: -1,
                  }}
                >
                  <Box
                    sx={{
                      typography: "h5",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {coreITcart[0] ? <>{coreITcart[0].quantity}</> : <>Add +</>}
                  </Box>
                </StyledButton>
              ) : (
                // Field
                <>
                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      // border: 1,
                      m: 0,
                      p: 0,
                    }}
                  >
                    {coreITcart.map((field, m) => (
                      <FormProvider key={m} {...methods}>
                        <form>
                          <SignUpCusTxtFld
                            key={m}
                            required={
                              field.ui_data.required == "Y" ? true : false
                            }
                            item_id={field.item_id}
                            allprops={field.ui_data}
                            validationType={field.ui_data.validationType}
                            value={field.quantity}
                            onfieldChg={(data) => {
                              addUpdCartItem(field.item_id, data);
                              console.log("cart in overview:", cart);
                            }}
                          />
                        </form>
                      </FormProvider>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
            {mode !== "W" && coreITcart[0] && coreITcart[0].quantity > 0 && (
              // <Box
              //   sx={{
              //     typography: "h4",
              //     position: "absolute",
              //     // bottom: 0,
              //     top: 0,
              //     right: 0,
              //     // width: "100%",
              //     bgcolor: "primary.main",
              //     color: "secondary.main",
              //     padding: "0px",
              //   }}
              // >
              <Tooltip title="Assign To Users">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    navigate("/dashboard/team", {
                      state: { title: "Assign Svc -> Users" },
                    });
                  }}
                >
                  <LaunchSharpIcon
                    // <NorthSharpIcon
                    // color="secondary.main"
                    // fontSize="large"
                    sx={{
                      ...menuIconSx,
                    }}
                  />
                </IconButton>
              </Tooltip>
              // </Box>
            )}
          </Stack>
          <Stack
            direction="row"
            sx={{
              m: 0,
              p: 0,
              // border: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              useFlexGap
              direction="row"
              sx={{
                flexWrap: "wrap",
                mx: 0,
                my: 0,
                // border: 1,
                borderRadius: "20px 20px 20px 20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  m: 0,
                  mr: 0.5,
                  p: 0,
                  // border: 1,
                  alignItems: { xs: "center", sm: "center" },
                }}
              >
                {/* price/user + Base Fee */}
                {coreITcart.map((cartRow) => (
                  <Box
                    sx={{
                      typography: "subtitle1",
                      color: "textSecondary",
                      fontWeight: "medium",
                    }}
                  >
                    {cartRow.price_curr}/{cartRow.disp_props.unit}/
                    {cartRow.disp_props.freq}
                    {cartRow.base_price > 0 && (
                      <>
                        {" "}
                        + {cartRow.base_price_curr} Base Fee/
                        {cartRow.disp_props.freq}
                      </>
                    )}
                  </Box>
                ))}
                {/* setup fee */}
                {coreITcart.map((cartRow) => (
                  <Box
                    sx={{
                      typography: "subtitle1",
                      color: "textSecondary",
                      fontWeight: "medium",
                    }}
                  >
                    {cartRow.setup_fee > 0 && (
                      <>
                        {cartRow.setup_fee_curr} Setup Fee /
                        {cartRow.disp_props.unit}/Onetime
                      </>
                    )}
                  </Box>
                ))}
              </Stack>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  display: { xs: "block", sm: "block" },
                  p: 0,
                  m: 0,
                  bgcolor: "secondary.main",
                }}
              />
              <Stack
                // direction="column"
                sx={{
                  m: 0,
                  ml: 0.5,
                  p: 0,
                  alignItems: "center",
                }}
              >
                {/* commitment duration */}
                {coreITcart.map((cartRow) => (
                  <Box
                    sx={{
                      typography: "caption",
                      color: "textSecondary",
                      fontWeight: "medium",
                    }}
                  >
                    {cartRow.subsc_type && <>({cartRow.subsc_type})</>}
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </StyledSection>
      </Stack>
    </Suspense>
  );
}

// export async function OverviewLoader() {
//   try {
//     console.log("in overview loader");
//     const [tilesData, slidesData] = await Promise.all([
//       myAxios.get(`/ui/tiles`, {}),
//       myAxios.get(`/ui/slides`, {}),
//     ]);
//     return [tilesData, slidesData];
//     // updateslides(slidesData.data);
//     // updatetiles(tilesData.data);
//   } catch (e) {
//     // throw Error("getAlldata3 Error");
//     throw e;
//   }
// }

function Arrow(props) {
  // const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <>
      {props.left && (
        // <ArrowBackIosNewOutlinedIcon
        <ArrowCircleLeftOutlinedIcon
          color={props.disabled ? "disabled" : "secondary"}
          // color={props.disabled ? "disabled" : "textPrimary"}
          onClick={props.onClick}
          fontSize="large"
          sx={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            top: "120px",
            left: "-12px",
            m: 0,
            // mr: 2,
          }}
        />
      )}

      {!props.left && (
        // <ArrowForwardIosIcon
        <ArrowCircleRightOutlinedIcon
          color={props.disabled ? "disabled" : "secondary"}
          // color={props.disabled ? "disabled" : "textPrimary"}
          onClick={props.onClick}
          fontSize="large"
          sx={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            top: "120px",
            right: "-12px",
            m: 0,
            // ml: 2,
          }}
        />
      )}
    </>
  );
}

function KeenSliderArrows(props) {
  return (
    <>
      <Arrow
        left
        onClick={(e) =>
          e.stopPropagation() || props.sliderInstance.current?.prev()
        }
        disabled={props.currentSlide === 0}
      />

      <Arrow
        onClick={(e) =>
          e.stopPropagation() || props.sliderInstance.current?.next()
        }
        disabled={
          props.currentSlide ===
          props.sliderInstance.current.track.details.slides.length -
            props.sliderInstance.current?.options?.slides.perView
        }
      />
    </>
  );
}
// export default Overview;
