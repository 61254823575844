import myAxios from "../MyAxios";
import { BlockRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UseTokenStore } from "./zuTokenStore";
export const callUICrud = async (ui_obj, ui_action, idata) => {
  console.log("in callUICrud::ui_obj:", ui_obj);
  console.log("in callUICrud::ui_action:", ui_action);
  console.log("in callUICrud::idata:", idata);
  const controller = new AbortController();
  try {
    const res = await myAxios.post(`/assets/handleCrud`, {
      data: idata,
      // crud_json: { obj_type: "asset_type", crud_action: "R" },
      // crud_json: { obj_type: `${ui_obj}`, crud_action: `${ui_action}` },
      crud_json: { obj_type: ui_obj, crud_action: ui_action },
    });
    if (res.status === 200) {
      console.log("response from callUICrud:", res);
      const data = await res.data;
      console.log("callUICrud:", data);
      return data;
    }
  } catch (err) {
    throw err;
  }
  controller.abort();
};

export const menuListLoader = async (ffrows) => {
  // console.log("getData5 :: filteredData2 ::: ", filteredData2);

  var menuListJSON = {};

  for (const ffrow of ffrows) {
    const temp = await callUICrud(ffrow.ui_data.menulist_name, "R", {
      svc_pkg_id: UseTokenStore.getState().svc_pkg_id,
      menulist_name: ffrow.ui_data.menulist_name,
      list: 1,
    });
    if (temp.length > 0) {
      menuListJSON = { ...menuListJSON, [ffrow.ui_data.menulist_name]: temp };
    }
    console.log("post loop iteration :: menuListJSON :::", menuListJSON);
  }

  return menuListJSON;
};
// export const addAssetType = async (inputdata) => {
//   console.log("in addAssetType action:", inputdata);
//   const controller = new AbortController();
//   try {
//     const res = await myAxios.post(`/assets/handleCrud`, {
//       data: inputdata,
//       crud_json: { obj_type: "asset_type", crud_action: "C" },
//     });
//     // const res = await myAxios.post(`/assets/addAssetType`, req)
//     if (res.status === 200) {
//       console.log("response from /asset/addAssetType", res);
//       const data = await res;
//       console.log("addAssetType", data);
//       return data;
//     }
//   } catch (err) {
//     throw err;
//   }
//   controller.abort();
// };

// export const deleteAssetType = async (inputdata) => {
//   console.log("in deleteAssetType action:", inputdata);
//   const controller = new AbortController();
//   try {
//     const res = await myAxios.post(`/assets/handleCrud`, {
//       data: inputdata,
//       crud_json: { obj_type: "asset_type", crud_action: "D" },
//     });
//     if (res.status === 200) {
//       console.log("response from /asset/deleteAssetType", res);
//       const data = await res.data;
//       console.log("delAssetType", data);
//       return data;
//     }
//   } catch (err) {
//     throw err;
//   }
//   controller.abort();
// };

// export const editAssetType = async (inputdata) => {
//   console.log("in editAssetType action:");
//   const controller = new AbortController();
//   try {
//     const res = await myAxios.post(`/assets/handleCrud`, {
//       data: inputdata,
//       crud_json: { obj_type: "asset_type", crud_action: "U" },
//     });
//     if (res.status === 200) {
//       console.log("response from /asset/editAssetType", res);
//       const data = await res;
//       console.log("editAssetType", data);
//       return data;
//     }
//   } catch (err) {
//     throw err;
//   }
//   controller.abort();
// };
