import React, { useState, useEffect } from "react";

import myAxios from "../MyAxios";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import StyledButton from "../shared/StyledButton";

import * as yup from "yup";
import { createYupSchema } from "../utils/jsFuncions";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SignUpCust from "./SignUpCust";
import SignUpStep1 from "./SignUpStep1";
import SignUpStep1Sum from "./SignUpStep1Sum";
import StyledHeader from "../shared/StyledHeader";

import Divider from "@mui/material/Divider";

import { useCustStore } from "../store/zuCustStore";
import { useCartStore } from "../store/zuCartStore";
import { refreshCust } from "../store/zuCustActions";
// import { UseTokenStore } from "../store/zuTokenStore";
import { sendMail } from "../store/zuCustActions";
// import useTheme from "@mui/material/styles/useTheme";

import TermsnConditions from "./TermsnConditions";
import FinishScreen from "../FinishScreen";
import Receipt from "./Receipt";
import { refresh } from "aos";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import { addNewUserInfo } from "../store/zuCustActions";
import StyledSection from "../shared/StyledSection";
import { custUI } from "../store/custUIFormFields";
function getStepContent(step, totalpages) {
  // console.log(step + " " + maxpagenum);
  if (step === totalpages) return <Receipt />;
  if (step === totalpages - 1) return <TermsnConditions />;
  if (step === totalpages - 2) return <SignUpCust currStep={1} />;
  return <SignUpStep1 currStep={step + 1} />;
}
function getStepContentSum(step) {
  switch (step) {
    case 0:
      return <SignUpStep1Sum />;
    case 1:
      return <SignUpStep1Sum />;
    case 2:
      return <SignUpStep1Sum />;
    case 3:
    default:
      return "Order Recorded. Please wait for email notification :)";
  }
}

let renderCount = 0;

export function SignUp(props) {
  const cart = useCartStore((state) => state.cart);
  const cust = useCustStore((state) => state.cust);
  // const custUI = useCustStore((state) => state.custUI);
  // const emailChk = useCustStore((state) => state.emailChk);
  // const token = UseTokenStore.getState().token;
  const [activeStep, setActiveStep] = useState(0);
  // const [pgWidth, setPgWidth] = useState("50%");
  const [display, setDisplay] = useState("block");
  const accepted = useCartStore((state) => state.accepted);

  const data2 = useLoaderData();
  // console.log("data 2 sign up ", data2.data2.data);

  const navigate = useNavigate();

  // const {
  //   register,
  //   setError,
  //   setValue,
  //   clearErrors,
  //   formState: { errors },
  // } = useForm();

  const findConfigPgNum = (cart, configpgnum) => {
    cart.map((field) => {
      if (field.form_pg_num > configpgnum) {
        configpgnum = field.form_pg_num;
      }
    });
    return configpgnum + 1;
  };
  //these variables will control the stepper component with configuration pages mapped from db in configpgct and additional ones factored into totalpgct.
  const configpgct = findConfigPgNum(cart, 0);
  // const totalpgct = configpgct + 2;

  // function getStepContent2(step, configpages) {
  //   if (step === configpages + 2) {
  //     return <Receipt />;
  //   }
  //   if (step === configpages + 1) {
  //     return <TermsnConditions />;
  //   }
  //   if (step === configpages) {
  //     return <SignUpCust />;
  //   }
  //   return <SignUpStep1 currStep={step + 1} />;
  // }

  function getStepContent(step, configpages) {
    console.log("data2.data.data", data2.data2.data);
    console.log("configpage", configpages);
    switch (step) {
      case configpages:
        return <SignUpCust formfields={data2.data2.data} />;
      case configpages + 1:
        return <TermsnConditions />;
      case configpages + 2:
        return <Receipt />;
      default:
        return <SignUpStep1 currStep={step + 1} />;
    }
  }

  function getStepContentSum(step) {
    switch (step) {
      case 0:
        return <SignUpStep1Sum />;
      case 1:
        return <SignUpStep1Sum />;
      case 2:
        return <SignUpStep1Sum />;
      case 3:
      default:
        return "Order Recorded. Please wait for email notification :)";
    }
  }

  let renderCount = 0;

  // const createYupSchema = (schema, config) => {
  //   // console.log("config.ui_data in " + JSON.stringify(config.ui_data));
  //   const {
  //     colname,
  //     validationType,
  //     // typeError,
  //     validations = [],
  //   } = config.ui_data;
  //   // console.log("colname " + colname);
  //   // console.log("validationtype: " + validationType);
  //   // console.log("validatoins : " + JSON.stringify(validations));
  //   // console.log(yup[validationType]);

  //   if (!yup[validationType]) {
  //     return schema;
  //   }
  //   let validator = yup[validationType]();
  //   // if (typeError) {
  //   //   let validator = yup[validationType]()(
  //   //     typeError ? typeError : "test error"
  //   //   );
  //   // }
  //   // console.log("validator: ", validator);
  //   validations.forEach((validation) => {
  //     const { params, type } = validation;
  //     // console.log("params: ", params);
  //     // console.log("type: ", type);
  //     // console.log("validation: ", validation);
  //     if (!validator[type]) {
  //       return;
  //     }
  //     // console.log("validator1: ", validator);

  //     validator = validator[type](...params);
  //     // console.log("validator2: ", validator);
  //     // validator = validator[type](...transform);
  //     // console.log("validator3: ", validator);
  //   });
  //   schema[colname] = validator;
  //   return schema;
  // };

  function findTotal() {
    var total = 0;
    cart.map((field) => {
      total += field.quantity * field.price;
    });
    return total;
  }
  function getYep() {
    var yepSchema;
    if (activeStep === configpgct) {
      yepSchema = custUI.reduce(createYupSchema, {});
      // console.log("yep schema built", yepSchema);
    } else
      yepSchema = cart
        .filter((page) => page.ui_data.formpage == activeStep + 1)
        .reduce(createYupSchema, {});
    // .filter((page) => parseInt(page.form_pg_num) === activeStep + 1)
    console.log("yep", yepSchema);
    return yepSchema;
  }

  // const yepSchema = getYep();
  const validationSchema = yup.object().shape(getYep());
  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    // mode: "all",
  });
  // console.log("methods in sign up ", methods);
  const {
    reset,
    isValid,
    formState: { errors },
  } = methods;

  const [click, setClick] = useState();

  // useEffect(() => {
  //   console.log("useEffect", click);
  //   // setClick(false);
  // }, [click]);

  // useEffect(() => {
  //   // console.log("emailChk in useeffect in signup:", emailChk);
  //   if (emailChk.verifyStatus === 3) {
  //     // console.log(
  //     //   " in verifyStatus === 3 in useeffect in signup:",
  //     //   emailChk.verifyStatus
  //     // );
  //     methods.setValue("everify1", "");
  //   }
  // }, [emailChk]);
  // const custReset = useCustStore((state) => state.reset);
  // const cartReset = useCartStore((state) => state.reset);
  // useEffect(() => {
  //   custReset();
  //   cartReset();
  // }, []);

  const steps = [];

  function populateSteps() {
    console.log("configpgct", configpgct);
    for (let i = 0; i < configpgct + 3; ++i) {
      var newStep;
      if (i <= configpgct) {
        if (i == 0) {
          newStep = {
            name: "Config " + (i + 1),
            desc: "Step" + (i + 1),
            isOptional: false,
            title: "Core Services",
            pgWidth: "50%",
            cdisplay: "block",
          };
        } else {
          newStep = {
            name: "Config " + (i + 1),
            desc: "Step" + (i + 1),
            isOptional: false,
            title: "Add-Ons",
            pgWidth: "50%",
            cdisplay: "block",
          };
        }
      }
      if (i === configpgct) {
        newStep = {
          name: "Info",
          desc: "Step" + (i + 1),
          isOptional: false,
          title: "Company Info",
          pgWidth: "100%",
          cdisplay: "none",
        };
      }
      if (i === configpgct + 1) {
        newStep = {
          name: "Terms",
          desc: "Step" + (i + 1),
          isOptional: false,
          title: "Terms and Conditions",
          pgWidth: "100%",
          cdisplay: "none",
        };
      }
      if (i === configpgct + 2) {
        newStep = {
          name: "Review",
          desc: "Step" + (i + 1),
          isOptional: false,
          title: "Order Review",
          pgWidth: "100%",
          cdisplay: "none",
        };
      }

      steps.push(newStep);
    }
  }
  populateSteps();
  console.log(steps);
  console.log("active step", activeStep);

  const [completed, setCompleted] = React.useState(new Set());
  const validatedUser = useCustStore((state) => state.validatedUser);
  const currentUser = useCustStore((state) => state.currentUser);

  const onSubmit = async (data) => {
    console.log("next line", click);
    // use quantity from Core-Svc's row only to create temp users in usr
    const coreItemId_row = cart.filter(
      (cartRow) => cartRow.item_id == cust.coreItemId
    );

    console.log("SignUP:onSubmit:coreItemId_row", coreItemId_row);
    // const sendcust = {
    //   ...cust,
    //   cust_id: cart[0].cust_id,
    //   quant: cart[0].quantity,
    //   location: "signupconf",
    // };
    const sendcust = {
      ...cust,
      cust_id: coreItemId_row.cust_id,
      quant: coreItemId_row.quantity,
      location: "signupconf",
    };

    console.log("SignUP:onSubmit:sendcust", sendcust);
    refreshCust(sendcust);

    const qbflow = await myAxios.post("/ui/qbsignupflow", null, {
      params: { cust_id: cart[0].cust_id, crInvoice: false },
    });
    console.log("qbcust", qbflow);
    if (qbflow.data.status === 400) {
      console.log("invalid");
      setClick("error");
    }

    console.log("isSubmitSuccessful:" + cust.login);

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log("activeStep" + activeStep);

    addNewUserInfo("login", cust.login);
    console.log("cust: ", cust, "currentUser ", currentUser);
    sendMail(cust);

    setClick("success");
    console.log("click onsubmit", click);
    // navigate("/dashboard");
  };

  const handleNext = async (e) => {
    console.log("handleNext:currentUser", currentUser);
    console.log(
      "isSubmitSuccessful.1.formState.errors:",
      methods.formState.errors
    );
    // if (activeStep >= 1) {
    //   // setPgWidth(steps[activeStep].pgWidth);
    // }
    // console.log("isSubmitSuccessful1:", methods.formState);
    const isStepValid = await methods.trigger();
    // const isStepValid = true;
    // console.log("isSubmitSuccessful2:", methods.formState);
    console.log("is step valid", isStepValid);
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    methods.reset({});

    setActiveStep(0);
  };

  // const watchemail = methods.watch("dup_email_check");
  // console.log("Signup:cart", cart);

  renderCount++;

  return (
    <>
      {" "}
      {validatedUser ? (
        <>
          <StyledSection>
            <Stack
              sx={{
                // bgcolor: "common.compbg",
                alignItems: "center",
                justifyContent: "center",
                m: 0,
                p: 0,
              }}
            >
              <Box
                sx={{
                  py: 1,
                  typography: "h6",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "secondary.main",
                }}
              >
                You are already logged in. Sign Up is only for new Users. To
                alter your Breazy plan email support@reliancetechnologies.com
              </Box>
              <Box sx={{ py: 2 }}>
                <StyledButton
                  onClick={() => {
                    useCustStore.setState(() => ({ validatedUser: null }));
                  }}
                >
                  Log Out
                </StyledButton>
              </Box>
            </Stack>
          </StyledSection>
        </>
      ) : (
        <>
          <Stack
            sx={
              {
                // mb: 3,
              }
            }
          >
            {/* <Stack
          sx={{
            m: 0,
            p: 0.5,
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "Center",
            bgcolor: "common.compbg",
          }}
        > */}
            {/* {activeStep === steps.length ? (
              <StyledHeader text="Order Confirmation" />
            ) : (
              <StyledHeader text="Simple Sign Up" />
            )} */}

            {/* <Divider
          sx={{
            width: "100%",
          }}
        /> */}
            {/* </Stack> */}
            <StyledSection headerText="Simple Sign Up">
              {activeStep === steps.length ? (
                <FinishScreen />
              ) : (
                <Stack
                  sx={{
                    width: "100%",
                    // m: 0.25,
                    m: 0,
                    mt: -1,
                    p: 0.25,
                    borderRadius: "20px 20px 20px 20px",
                    bgcolor: "primary.main",
                  }}
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  spacing={0.25}
                >
                  <Stack
                    sx={{
                      // border: 1,
                      m: 0,
                      px: 0.25,
                      py: 1,
                      // pb: 2,
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: `${steps[activeStep].pgWidth}`,
                      },
                      borderRadius: "20px 20px 20px 20px",
                      bgcolor: "common.compbg",
                      // border: 1,
                    }}
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Stack>
                      <Stack
                        sx={{
                          // border: 1,
                          m: 0,
                          p: 0,
                          width: "100%",
                        }}
                      >
                        {/* Sub-header */}
                        <Stack
                          sx={{
                            m: 0,
                            p: 0,
                            width: "100%",
                            borderColor: "secondary.main",
                            borderRadius: "10px 10px 10px 10px",
                            // bgcolor: "common.compbg",
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              color: "textPrimary",
                              fontWeight: "bold",
                              pb: 0.5,
                            }}
                          >
                            {steps[activeStep].title}
                          </Typography>
                          <Divider
                            sx={{
                              width: "100%",
                            }}
                          />
                        </Stack>
                        <FormProvider {...methods}>
                          <form>
                            <Stack
                              sx={{
                                m: 0,
                                p: 0,
                                // width: "100%",
                                // border: 1,
                              }}
                              justifyContent="center"
                              alignItems="center"
                            >
                              {getStepContent(activeStep, configpgct)}
                            </Stack>
                          </form>
                        </FormProvider>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack
                        sx={{
                          m: 0,
                          p: 0,
                          width: "100%",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Divider sx={{ m: 1, width: "100%" }} />
                        <Stepper
                          activeStep={activeStep}
                          sx={{
                            padding: (theme) => theme.spacing(0.5),
                            justifyContent: "left!important",
                            // backgroundColor: "common.compbg",
                            "&$horizontal": {
                              justifyContent: "left!important",
                            },
                          }}
                        >
                          {steps.map((cstep, index) => {
                            const buttonProps = {};
                            const stepProps = {};
                            const labelProps = {};
                            if (cstep.isOptional) {
                              buttonProps.optional = (
                                <Typography variant="caption">
                                  Optional
                                </Typography>
                              );
                            }

                            return (
                              <Step key={cstep.name}>
                                <StepLabel
                                  sx={{
                                    py: 1,
                                    // icon default color
                                    "& .MuiStepLabel-iconContainer .MuiStepIcon-root":
                                      {
                                        color: "secondary.main",
                                      },
                                    //icon active color
                                    "& .MuiStepLabel-iconContainer .Mui-active":
                                      {
                                        color: "secondary.main",
                                        opacity: 0.5,
                                      },

                                    //default/disabled label text
                                    "& .MuiStepLabel-labelContainer": {
                                      color: "secondary.main",
                                    },
                                    //active label text
                                    "& .MuiStepLabel-labelContainer .Mui-active":
                                      {
                                        color: "secondary.main",
                                        opacity: 0.5,
                                      },
                                    //completed label text
                                    "& .MuiStepLabel-labelContainer .Mui-completed":
                                      {
                                        color: "secondary.main",
                                      },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      display: {
                                        xs: "none",
                                        s: "none",
                                        md: "none",
                                        lg: "block",
                                      },
                                    }}
                                  >
                                    {cstep.name}
                                  </Typography>
                                </StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                        <Stack
                          sx={{
                            m: 1,
                            p: 0,
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            // bgcolor: "common.compbg",
                          }}
                          direction={{ xs: "row", sm: "row", md: "row" }}
                        >
                          {click === "success" ? (
                            <>
                              <Stack>
                                <Typography>
                                  Sign Up Successful. Go to Dashboard:
                                </Typography>
                                <StyledButton
                                  onClick={() => {
                                    useCustStore.setState(() => ({
                                      validatedUser: true,
                                    }));
                                    navigate("/dashboard");
                                  }}
                                >
                                  Navigate to Dashboard
                                </StyledButton>
                              </Stack>
                            </>
                          ) : (
                            <>
                              <Stack>
                                {click === "error" ?? (
                                  <Typography>Error with Signup</Typography>
                                )}
                                <ButtonGroup
                                  disableElevation
                                  variant="outlined"
                                  aria-label="outlined primary button group"
                                >
                                  <StyledButton
                                    // color="primary"
                                    // variant="contained"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    // sx={{
                                    //   marginRight: (theme) => theme.spacing(1),
                                    //   color: "secondary.main",
                                    // }}
                                  >
                                    Back
                                  </StyledButton>
                                  <Divider
                                    orientation="vertical"
                                    sx={{ m: 0.1 }}
                                  />
                                  {activeStep === steps.length - 1 ? (
                                    <>
                                      <StyledButton
                                        type="submit"
                                        // variant="contained"
                                        // color="primary"
                                        disabled={!accepted}
                                        onClick={methods.handleSubmit(onSubmit)}
                                        // sx={{
                                        //   marginRight: (theme) => theme.spacing(1),
                                        //   color: "secondary.main",
                                        // }}
                                      >
                                        Confirm
                                      </StyledButton>
                                    </>
                                  ) : (
                                    <StyledButton
                                      disabled={
                                        activeStep === steps.length - 2 &&
                                        !accepted
                                      }
                                      // variant="contained"
                                      // color="primary"
                                      onClick={methods.handleSubmit(handleNext)}
                                      // sx={{
                                      //   marginRight: (theme) => theme.spacing(1),
                                      //   color: "secondary.main",
                                      // }}
                                    >
                                      Next
                                    </StyledButton>
                                  )}
                                </ButtonGroup>
                              </Stack>
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  {/* Summary */}
                  <Stack
                    sx={{
                      // border: 1,
                      m: 0,
                      px: 0.25,
                      py: 1,
                      // pb: 2,
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: `${steps[activeStep].pgWidth}`,
                      },
                      borderRadius: "20px 20px 20px 20px",

                      // width: { xs: "100%", sm: "100%", md: "50%" },
                      bgcolor: "common.compbg",
                      // borderRadius: "20px 20px 20px 20px",
                      display: `${steps[activeStep].cdisplay}`,
                      // border: 1,
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="column" width="100%">
                      <Stack
                        sx={{
                          m: 0,
                          p: 0,
                          width: "100%",
                          borderRadius: "10px 10px 10px 10px",
                          // bgcolor: "common.compbg",
                          // border: 0,
                          borderColor: "secondary.main",
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant={"h4"}
                          sx={{
                            // color: "secondary.main",
                            color: "textPrimary",
                            fontWeight: "bold",
                            pb: 0.5,
                          }}
                        >
                          Summary
                        </Typography>
                        <Divider
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Stack>
                      {/* <Stack
                display="flex"
                direction="column"
                justifyContent="space-between"
                alignItems="baseline"
                spacing={0}
                sx={{ border: 1, borderColor: "secondary.main" }}
              > */}
                      <Stack
                        sx={{
                          m: 1,
                          p: 0,
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {getStepContentSum(activeStep)}
                      </Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      sx={{
                        m: 0,
                        p: 0,
                        pb: 1,
                        width: { xs: "100%", sm: "100%", md: "100%" },
                        // bgcolor: "common.compbg",
                        // border: 1,
                        // borderColor: "secondary.main",
                      }}
                      // justifyContent="flex-start"
                      // alignItems="flex-start"
                    >
                      <Stack
                        sx={{
                          m: 0,
                          mb: 0,
                          p: 0,
                          width: "100%",
                          // bgcolor: "common.compbg",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                        direction={"row"}
                      >
                        <Stack
                          sx={{
                            m: 0,
                            pt: 0,
                            width: "60%",

                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant={"h5"}
                            sx={{
                              color: "textSecondary",
                              fontWeight: "bold",
                              pl: 0.5,
                              pr: 0,
                              ml: 2,
                            }}
                          >
                            Total per Month:
                          </Typography>
                        </Stack>

                        <Stack
                          sx={{
                            m: 0,
                            mb: 0,
                            pt: 0,
                            width: "40%",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            variant={"h5"}
                            sx={{
                              mr: 2,
                              color: "secondary.main",
                              fontWeight: "bold",
                              p: 0,
                              // pl: 0.5,
                              pr: 0,
                            }}
                          >
                            ${findTotal()}.00
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </StyledSection>
          </Stack>
        </>
      )}
    </>
  );
}

// export default SignUp;
