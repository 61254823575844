// import Link from "@mui/material/Link";
// import { Link as RouterLink } from "react-router-dom";
// import myAxios from "../MyAxios";
// import { dupEmailcheck } from "./zuCustActions";
// import { dupEmailcheck2 } from "./zuCustActions";
// import { checkZip } from "./zuCustActions";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const initialState = {
  svc_pkg: 1,
  cust: {},
  validatedUser: null,
  currentUser: {
    id: 0,
    cust_id: 0,
    login: "",
    pwd: "",
    startdate: "",
    fname: "",
    lname: "",
    role: "",
  },

  emailChk: {
    status: false,
    msg: "",
    verifyStatus: 0,
    verifyCode: 0,
    verifiedEmail: "",
    verifiedZip: "",
    helperText: "",
  },
  valroutes: [],

  // emailChk2: {
  //   status: false,
  //   msg: "",
  //   verifyStatus: 0,
  //   verifyCode: 0,
  //   verifiedEmail: "",
  //   verifiedZip: "",
  //   codeStatus: 0,
  // },
};

const useCustStore = create(
  persist(
    (set) => ({
      ...initialState,
      reset: () => {
        set(initialState);
      },
      resetEmailChk: () => {
        set({ emailChk: initialState.emailChk });
      },
    }),
    {
      name: "zu_cust",
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        // cust: state.cust,
        validatedUser: state.validatedUser,
        currentUser: state.currentUser,
      }),
    }
  )
);

export { useCustStore };
