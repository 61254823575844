import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  styled,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
// import { tokens } from "../../../../styling/theme";
// import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
// import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
// import Header from "../../Header";
// import { Edit as EditIcon } from "@mui/icons-material";
// import StyledButton from "../../shared/StyledButton";
import StyledButtonWIcon from "../../shared/StyledButtonWIcon";

// import MUIDataTable, { TableBody } from "mui-datatables";
import { useCartStore } from "../../store/zuCartStore";
import StyledSection from "../../shared/StyledSection";
import { MaterialReactTable } from "material-react-table";
import {
  useOutletContext,
  Link,
  useNavigate,
  useLocation,
  useActionData,
} from "react-router-dom";
import { useLoaderData, useRevalidator } from "react-router-dom";
// import { Delete as DeleteIcon } from "@mui/icons-material";
import myAxios from "../../MyAxios";
import { useConfirm } from "material-ui-confirm";
import { deleteAssetType } from "../../store/crudActions";
import { useFetcher, useSubmit } from "react-router-dom";

const MaterialTableCard = (props) => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!fetcher?.data && fetcher.state === "idle") {
  //     fetcher.load(locData.pathname);
  //   }
  //   // if (fetcher?.data) {
  //   //   console.log("in fetcher reassignment :: ", fetcher.data);
  //   //   props.data1 = fetcher?.data.props.data1;
  //   //   data2 = fetcher?.data.data2;
  //   //   data3 = fetcher?.data.data3;
  //   // }
  //   console.log("useEffect", msg);
  // }, [fetcher]);
  // console.log("fetcher", fetcher);

  console.log("props.data1/data2/data3", props.data1, props.data2, props.data3);

  // ????????????
  // const [{ state }, locData] = useLocation();

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const cart = useCartStore((state) => state.cart);
  var cartdata = [];
  const flattenJSON = (obj = {}, res = {}, extraKey = "") => {
    for (var key in obj) {
      if (typeof obj[key] !== "object") {
        res[extraKey + key] = obj[key];
      } else {
        flattenJSON(obj[key], res, `${extraKey}${key}.`);
      }
    }
    return res;
  };
  var lcolumns = [];

  const fillColumns = () => {
    props.data2.data.map((row, i) => {
      console.log(row.ui_data.colname, row.ui_data.collabel);
      lcolumns = [
        ...lcolumns,
        { accessorKey: row.ui_data.colname, header: row.ui_data.collabel },
      ];
    });
  };
  fillColumns();
  console.log("lcolumns", lcolumns);
  // const MyTableBody = (props) => {
  //   return <TableBody {...props} sx={{ backgroundColor: "yellow" }} />;
  // };
  const datarows = () => {
    cart.map((field, j) => {
      console.log("iteration", field.item_id);
      cartdata = [...cartdata, flattenJSON(cart[j])];

      console.log("ncartdata", cartdata);
    });

    return cartdata;
  };
  const handleRowClick = (rowData, rowMeta) => {
    console.log("rowData:", rowData);
    console.log("rowMeta:", rowMeta);
  };
  const cartdatarows = datarows();
  console.log("cartdatarows", cartdatarows);

  console.log("data1");

  return (
    <>
      <MaterialReactTable
        columns={lcolumns}
        data={props.data1?.data ? props.data1?.data : []}
        // enablePagination={false}
        enableGlobalFilter
        enableRowSelection //enable some features
        muiSelectCheckboxProps={{
          sx: {
            color: "secondary.main",
            "&.Mui-checked": {
              color: "secondary.main",
            },
          },
        }}
        // enableColumnOrdering
        // enableEditing
        // enableRowVirtualization
        positionToolbarAlertBanner="bottom"
        enableRowActions
        renderRowActionMenuItems={({ row }) => [
          <div>
            {props.data3.edit ? (
              <>
                {/* <MenuItem
                  key="edit"
                  onClick={() => {
                    console.log("row", row.original);
                    navigate("edit/" + row.original.id, {
                      state: {
                        data: row.original,
                        title: "state.title",
                      },
                    });
                  }}
                >
                  <>
                    {data3.edit ? (
                      <EditIcon sx={{ color: "secondary.main", mr: 0.25 }} />
                    ) : (
                      <></>
                    )}
                  </>
                  Edit
                </MenuItem> */}
                <MenuItem sx={{ m: 0, px: 1 }}>
                  <StyledButtonWIcon
                    iconName="EditIcon"
                    key="edit"
                    onClick={() => {
                      console.log("row", row.original);
                      navigate("edit/" + row.original.id, {
                        state: {
                          data: row.original,
                          title: "state.title",
                        },
                      });
                    }}
                    sx={{
                      border: 0,
                      textTransform: "capitalize",
                      // fontSize: "medium",
                      ":hover": {
                        boxShadow: 0,
                        bgcolor: "",
                      },
                      // , color: "secondary.main"
                    }}
                    variant="text"
                  >
                    Edit
                  </StyledButtonWIcon>
                </MenuItem>
              </>
            ) : (
              <></>
            )}
          </div>,
          <div>
            {/* // logic which checks current user role against blah blah */}
            {props.data3.delete ? (
              <>
                {/* <MenuItem key="edit" onClick={() => handleDel(row.original)}>
                  {data3.delete ? <DeleteIcon /> : <></>}
                  <DeleteIcon />
                  Delete
                </MenuItem> */}
                <MenuItem sx={{ m: 0, px: 1 }}>
                  <StyledButtonWIcon
                    iconName="DeleteIcon"
                    key="delete"
                    onClick={() => props.handleDel(row.original)}
                    sx={{
                      border: 0,
                      textTransform: "capitalize",
                      // fontSize: "medium",
                      ":hover": {
                        boxShadow: 0,
                        bgcolor: "",
                      },
                      // , color: "secondary.main"
                    }}
                    variant="text"
                  >
                    Delete
                  </StyledButtonWIcon>
                </MenuItem>
              </>
            ) : (
              <></>
            )}
          </div>,

          <div>
            {props.data3.assignsvc ? (
              <>
                <MenuItem
                  key="edit"
                  onClick={() => {
                    console.log(row.original);
                    navigate("/dashboard/svc2usrassign", {
                      state: { rowInfo: row.original },
                    });
                  }}
                >
                  {/* {data3.delete ? <DeleteIcon /> : <></>} */}
                  Assign Services
                </MenuItem>
              </>
            ) : (
              <></>
            )}
          </div>,
          <div>
            {props.data3.assets ? (
              <>
                <MenuItem
                  key="edit"
                  onClick={() => props.handleDel(row.original)}
                >
                  {/* {data3.delete ? <DeleteIcon /> : <></>} */}
                  Assets
                </MenuItem>
              </>
            ) : (
              <></>
            )}
          </div>,
        ]}
        muiTableProps={
          {
            // sx: {},
          }
        }
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            height: "100%",
            // m: "auto",
            // p: 0,
            // borderRadius: "0",
            // border: "1px dashed #e0e0e0",
            // backgroundColor: "#f5f5f5",
          },
        }}
        muiTableBodyProps={{
          sx: {
            // backgroundColor: "yellow",
            //stripe the rows, make odd rows a darker color
            "& tr:nth-of-type(odd)": {
              backgroundColor: theme.palette.background,
              color: theme.palette.textPrimary,
            },
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            console.log("row", row.original);
            navigate("edit/" + row.original.id, {
              state: {
                data: row.original,
                title: "state.title",
              },
            });
          },
          onDoubleClick: (event) => {
            console.log("row", row.original);
            navigate("edit/" + row.original.id, {
              state: {
                data: row.original,
                title: "state.title",
              },
            });
          },
          sx: {
            cursor: "pointer", //you might want to change the cursor too when adding an onClick
          },
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <Stack direction="row">
            {props.data3.add ? (
              <>
                {/* <StyledButton
                  color="secondary"
                  onClick={() =>
                    navigate("create", {
                      state: {
                        custname: props.data1?.data[0]?.cust_name,
                        custid: props.data1?.data[0]?.cust_id,
                      },
                    })
                  }
                  variant="contained"
                  // component={Link}
                  // to="create"
                >
                  +
                </StyledButton> */}

                <StyledButtonWIcon
                  iconName="AddBoxOutlinedIcon"
                  onClick={() =>
                    navigate("create", {
                      state: {
                        custname: props.data1?.data[0]?.cust_name,
                        custid: props.data1?.data[0]?.cust_id,
                      },
                    })
                  }
                  sx={{
                    border: 0,
                    textTransform: "capitalize",
                    // , color: "secondary.main"
                  }}
                  variant="text"
                >
                  Add
                </StyledButtonWIcon>
              </>
            ) : (
              <></>
            )}
            {props.data3.delete ? (
              <>
                {/* <StyledButton
                  color="error"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={() => {
                    table.getSelectedRowModel().rows.map(async (row) => {
                      await myAxios.post(`/assets/handleCrud`, {
                        data: row.original,
                        crud_json: {
                          obj_type:
                            locData.pathname === "/dashboard/team"
                              ? "users"
                              : "asset_type",
                          crud_action: "D",
                        },
                      });
                      navigate(0);
                    });
                  }}
                  variant="contained"
                >
                  <DeleteSweepIcon />
                </StyledButton> */}

                <StyledButtonWIcon
                  iconName="DeleteSweepIcon"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={() => {
                    table.getSelectedRowModel().rows.map(async (row) => {
                      await myAxios.post(`/assets/handleCrud`, {
                        data: row.original,
                        crud_json: {
                          obj_type:
                            locData.pathname === "/dashboard/team"
                              ? "users"
                              : "asset_type",
                          crud_action: "D",
                        },
                      });
                      navigate(0);
                    });
                  }}
                  sx={{
                    border: 0,
                    textTransform: "capitalize",
                  }}
                  variant="text"
                >
                  Delete
                </StyledButtonWIcon>
              </>
            ) : (
              <></>
            )}
            {props.locData.pathname === "/dashboard/team" ? (
              <>
                <StyledButtonWIcon
                  iconName="ForwardToInboxIcon"
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={() => {
                    // submit(table.getSelectedRowModel().rows);

                    table.getSelectedRowModel().rows.map(async (formData) => {
                      console.log(
                        "formData in swemail func",
                        formData.original
                      );
                      var nFormData;
                      const mailer = await myAxios.post(`/mailer/welcome`, {
                        Email: formData.original.login,
                        Subject: "BREazy Order Confirmation",
                      });
                      if (mailer.status === 200) {
                        console.log(Date());
                        nFormData = {
                          ...formData,
                          wemailsenddate: Date(),
                        };
                      } else {
                        nFormData = formData;
                      }
                      navigate(0);
                    });
                  }}
                  sx={{
                    border: 0,
                    textTransform: "capitalize",
                  }}
                  variant="text"
                >
                  Send Email
                </StyledButtonWIcon>
              </>
            ) : (
              <></>
            )}
          </Stack>
        )}
      />
    </>
    // </Box>
  );
};
// export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
//   const [values, setValues] = useState(() =>
//     columns.reduce((acc, column) => {
//       acc[column.accessorKey ?? ""] = "";
//       return acc;
//     }, {})
//   );

const handleSubmit = () => {
  //put your validation logic here
  onSubmit(values);
  onClose();
};

export default MaterialTableCard;
