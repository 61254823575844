import React from "react";
import Undraw from "react-undraw";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import BackupIcon from "@mui/icons-material/Backup";
import EmailIcon from "@mui/icons-material/Email";

import CategoryIcon from "@mui/icons-material/Category";
import {
  HomeOutlined,
  Groups2Outlined,
  SettingsOutlined,
  ShoppingCartOutlined,
  Edit as EditIcon,
  Delete as DeleteIcon,
  DeleteSweep as DeleteSweepIcon,
  ForwardToInbox as ForwardToInboxIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
} from "@mui/icons-material";

// import {
//   SettingsOutlined,
//   ChevronLeft,
//   ChevronRightOutlined,
//   HomeOutlined,
//   ShoppingCartOutlined,
//   Groups2Outlined,
//   ReceiptLongOutlined,
//   PublicOutlined,
//   PointOfSaleOutlined,
//   TodayOutlined,
//   CalendarMonthOutlined,
//   AdminPanelSettingsOutlined,
//   TrendingUpOutlined,
//   PieChartOutlined,
// } from "@mui/icons-material";

//make sure you yarn global add typescript before reinstalling react-undraw to ensure successful build

export function Icon({ type, ...props }) {
  const getIcon = () => {
    switch (type) {
      case "phone":
        return <PhoneIcon {...props} />;
      case "fax":
        return <FaxIcon {...props} />;
      case "backup":
        return <BackupIcon {...props} />;
      case "email":
        return <EmailIcon {...props} />;
      case "EditIcon":
        return <EditIcon {...props} />;
      case "DeleteIcon":
        return <DeleteIcon {...props} />;
      case "HomeOutlined":
        return <HomeOutlined {...props} />;
      case "Groups2Outlined":
        return <Groups2Outlined {...props} />;
      case "SettingsOutlined":
        return <SettingsOutlined {...props} />;
      case "ShoppingCartOutlined":
        return <ShoppingCartOutlined {...props} />;
      case "CategoryIcon":
        return <CategoryIcon {...props} />;
      case "DeleteSweepIcon":
        return <DeleteSweepIcon {...props} />;
      case "ForwardToInboxIcon":
        return <ForwardToInboxIcon {...props} />;
      case "AddBoxOutlinedIcon":
        return <AddBoxOutlinedIcon {...props} />;
      case "EditNoteOutlinedIcon":
        return <EditNoteOutlinedIcon {...props} />;

      default:
        return <PhoneIcon {...props} />;
    }
  };

  return getIcon();
}
export function Image({ type, ...props }) {
  // const height = "100%";
  const getImage = () => {
    Undraw.defaultProps.primaryColor = `${props.primaryColor}`;
    // console.log("type:", type);toLowerCase()
    switch (type.toLowerCase()) {
      // case "phone":
      //   // return <Undraw name="phonecall" height="150px" />;
      //   return <Undraw name="uc" height={props.height} />;
      // case "email":
      //   return <Undraw name="email" height={props.height} />;
      // case "calendar":
      //   return <Undraw name="calendar" height={props.height} />;
      //   case "cloudfiles":
      //     return <Undraw name="cloudfiles" height={props.height} />;

      //     case "fax":
      //   return <Undraw name="cxds" height={props.height} />;
      // case "backup":
      //   return <Undraw name="cxds" height={props.height} />;
      // case "security":
      //   return <Undraw name="security" height={props.height} />;
      default:
        return <Undraw name={type.toLowerCase()} height={props.height} />;
    }
  };

  return getImage();
}
