import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useOverviewStore = create(
  persist(
    (set, get) => ({
      topbanner: [],
      tiles: [],
      slides: [],
      faqs: [],
      updTopbanner: (ntopbanner) => {
        set(() => ({ topbanner: ntopbanner }));
        // console.log("state.topbanner:", JSON.stringify(get().topbanner));
      },
      updTiles: (ntiles) => {
        // console.log("ntiles",ntiles);
        set(() => ({ tiles: ntiles }));
        // console.log("state.tiles:", JSON.stringify(get().tiles));
      },
      updFaqs: (nfaqs) => {
        // console.log("infaq");
        set(() => ({ faqs: nfaqs }));
        // console.log("state.faqs:", JSON.stringify(get().faqs));
      },
      updSlides: (nslides) => {
        set(() => ({ slides: nslides }));
        // console.log("state.slides:" + JSON.stringify(get().slides));
      },
    }),
    { name: "zu_overview", storage: createJSONStorage(() => localStorage) }
  )
);
export { useOverviewStore };
