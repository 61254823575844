import { Virtuoso } from "react-virtuoso";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import terms from "./tncdata.json";

export default function VirtuosoList() {
  console.log("inside vlist");
  const theme = useTheme();
  const height = useWindowHeight();
  const width = useWindowWidth();

  return (
    <>
      <Virtuoso
        style={{
          width: 0.85 * Math.min(width, theme.breakpoints.values["lg"]),
          height: height / 2,
        }}
        data={terms}
        itemContent={(index, user) => (
          <Stack sx={{ pb: 2 }} direction="row">
            <Box sx={{ pr: 3, fontWeight: "bold" }}>{index + 1}.</Box>
            <Box sx={{ typography: "h6", color: "secondary.main", pr: 1.5 }}>
              {user.a}
            </Box>
          </Stack>
        )}
      />
    </>
  );
}
