import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import StyledButton from "../../shared/StyledButton";
import StyledSection from "../../shared/StyledSection";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";

import { useCustStore } from "../../store/zuCustStore";
import {
  addNewCustInfo,
  addNewUserInfo,
  addTempUserInfo,
} from "../../store/zuCustActions";
import { sendCode } from "../../store/zuCustActions";
import { custUI } from "../../store/custUIFormFields";
import { validationStore } from "../../store/validationStore";
import { refreshCust } from "../../store/zuCustActions";

import * as yup from "yup";
import { createYupSchema } from "../../utils/jsFuncions";

import { FormProvider, useForm } from "react-hook-form";
import { useFieldArray, Controller, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { SignUpCusTxtFld } from "../../home/SignUpCusTxtFld";
import { useLocation } from "react-router-dom";
import {
  useLoaderData,
  useRevalidator,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import myAxios from "../../MyAxios";
import { PassRecovery } from "../../register_login/PassRecovery";
import { useActionData } from "react-router-dom";
import dataProvider from "@refinedev/simple-rest";
import { callUICrud } from "../../store/crudActions";
import { secondaryListItems } from "../listItems";
import MaterialTableCard from "./MrtList copy";

// const ConditionalInput = ({ control, index, field }) => {
//   const value = useWatch({
//     name: "test",
//     control,
//   });

//   return (
//     // <Controller
//     //   control={control}
//     //   name={`test.${index}.firstName`}
//     //   render={({ field }) => <input {...field} />}
//     //   defaultValue={field.firstName}
//     // />

//     <SignUpCusTxtFld
//       disabled={
//         data3.cmode === "edit"
//           ? field.ui_data.editenabled === "N"
//           : field.ui_data.addenabled === "N"
//       }
//       allprops={field.ui_data}
//       required={field.ui_data.required === "Y" ? true : false}
//       type={field.ui_data.type}
//       label={field.ui_data.collabel}
//       name={field.ui_data.colname}
//       value={
//         data1 ? data1[0][field.ui_data.colname] : field.ui_data.defaultValue
//       }
//       validationType={field.ui_data.validationType}
//       menuprops={
//         field.ui_data.type === "menu"
//           ? menuListValues[field.ui_data.menulist_name]
//           : field.ui_data.menuprops
//       }
//       yupVType={field.ui_data.yupVType}
//       mask={field.ui_data.mask}
//       placeholder={field.ui_data.placeholder}
//       menuClickFunc={async (input) => {
//         // methods.reset({ test: [] });
//         console.log("data4", data4);
//         if (field.ui_data?.subtype_name) {
//           remove();
//           const res = await getFFs(field.ui_data.subtype_name, {
//             i_page: "E",
//             i_entity_id: input,
//           });
//           console.log("res", res);
//           for (var i = 0; i < res.data.length; i++) {
//             append({
//               data: res.data[i],
//             });
//           }
//           console.log("fields", fields);
//           remove(1);
//           console.log("fields after remove", fields);
//         }
//       }}
//     />
//   );
// };

export default function DashBdOneStepForm(props) {
  const cust = useCustStore((state) => state.cust);
  const navigate = useNavigate();
  const result = useActionData();
  console.log("result", result);

  const getFFs = async (ent, pg) => {
    // console.log('getcust input', cust);
    // const usrdata = await myAxios.post(`/ui/getuser`, cust);
    // console.log('userdata', usrdata);
    const ffdata = await myAxios.get(`/ui/getFF`, {
      params: {
        //lknlnl
        ientity: ent,
        pgnum: pg,
      },
    });
    // console.log("ffdata", ffdata)
    // const resultset = {userdata : usrdata, ffdata: ffdata}
    return ffdata;
  };
  const { state } = useLocation();
  console.log("state", state);
  // console.log("state", state)

  // useEffect(()=>{
  //   console.log('cust', cust)
  //   addTempUserInfo("cust_id",cust.cust_id)
  // }, [cust.cust_id])

  const {
    data1,
    data2,
    data3,
    menuListValues,
    data4,
    data6,
    // fields,
    // fieldArray,
  } = useLoaderData();

  var fields = data2.data;

  console.log("data1", data1, data4);

  console.log("data2", data2, data6);
  // console.log("menuListValues", menuListValues);

  // const emailChk = useCustStore((state) => state.emailChk);
  // const createYupSchema = (schema, config) => {
  //   // console.log("config.ui_data in " + JSON.stringify(config.ui_data));
  //   const {
  //     yupVType,
  //     validationType,
  //     colname,
  //     // typeError,
  //     // validations = [],
  //   } = config.ui_data;

  //   // console.log(colname);

  //   const validations = validationStore[yupVType];
  //   // console.log(validationStore[colname]);
  //   // console.log(validations);
  //   // console.log("colname " + colname);
  //   // console.log("validationtype: " + validationType);
  //   // console.log("validatoins : " + JSON.stringify(validations));
  //   // console.log(yup[validationType]);

  //   if (!yup[validationType]) {
  //     console.log("invalid type");
  //     return schema;
  //   }

  //   let validator = yup[validationType]();

  //   validations.forEach((validation) => {
  //     const { params, type } = validation;

  //     if (!validator[type]) {
  //       return;
  //     }

  //     validator = validator[type](...params);
  //   });
  //   schema[colname] = validator;
  //   return schema;
  // };
  function getYep() {
    console.log("data2");
    var yepSchema;

    var flatFieldArray = [];

    for (var i = 0; i < data2.data.length; ++i) {
      console.log("in for loop flatfields");
      if (data2.data[i].subtypeFields) {
        for (var j = 0; j < data2.data[i].subtypeFields.length; ++j) {
          flatFieldArray = [...flatFieldArray, data2.data[i].subtypeFields[j]];
        }
      } else {
        flatFieldArray = [...flatFieldArray, data2.data[i]];
      }
    }
    console.log("flatfieldArr", flatFieldArray);
    // if (activeStep === configpgct) {
    yepSchema = flatFieldArray.reduce(createYupSchema, {});
    return yepSchema;
  }

  // const yepSchema = getYep();
  const validationSchema = yup.object().shape(getYep());
  console.log("validationschema", validationSchema);
  const methods = useForm({
    shouldUnregister: false,
    // resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    // reValidateMode: "onChange",
    // mode: "all",
    defaultValues: {
      test: [],
    },
  });

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    isValid,
  } = methods;

  console.log("errors in fieldstate", errors);

  // console.log("fields in code", fields);
  const [stateFields, setStateFields] = useState([]);
  console.log("statefields", stateFields);

  // useEffect(() => {
  //   console.log("useeffect render flag!");
  //   console.log("fields  :: in  ", fields);
  //   console.log("statefields  :: ", stateFields);

  //   // if (fields.length <= 0) {
  //   if (stateFields.length > 0) {
  //     console.log("stateFields.length > 0");
  //     for (var i = 0; i < stateFields.length; i++) {
  //       append({ data: stateFields[i] });
  //     }
  //   } else if (fields.length < 1) {
  //     console.log("stateFields.length = 0");
  //     for (var i = 0; i < data2.data.length; i++) {
  //       if (
  //         data6 &&
  //         data2.data[i].ui_data.type === "subtype" &&
  //         data2.data[i].ui_data?.subtype_trigger_field === ""
  //       ) {
  //         append({ data: { ...data2.data[i], subtypeFields: data6 } });
  //       } else {
  //         append({
  //           data: data2.data[i],
  //         });
  //       }
  //     }
  //     // }
  //   }

  // if (data6) {
  //   for (var i = 0; i < data2.data.length; ++i) {
  //     // console.log("fields[i]", data2.data[i]);
  //     if (
  //       data2.data[i].ui_data.type === "subtype" &&
  //       data2.data[i].ui_data?.subtype_trigger_field === ""
  //     ) {
  //       console.log("fields in data6 loop", fields[i]);
  //       fields[i] = {
  //         ...fields[i],
  //         data: { ...fields[i]?.data, subtypeFields: data6 },
  //       };
  //       console.log("fields[i]", fields[i]);
  //     }
  //   }
  // }

  // if (fields.length > 1) {
  //   console.log("fields useeffect", fields);
  //   for (var i = 0; i < fields.length; ++i) {
  //     if (
  //       fields[i].data.ui_data.type === "subtype" &&
  //       fields[i].data.ui_data?.subtype_trigger_field === ""
  //     ) {
  //       const res = await getFFs(
  //        fields[i].data.ui_data.subtype_name,
  //         {
  //           i_page: "E",
  //           i_entity_id: input,
  //         }
  //       );
  //             }

  //   }
  // }
  // }, []);

  // const {formState : {errors}} = useForm();

  // const { reset, isValid } = methods;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const onErrors = async (error) => {
    console.log("error in form ::", error);
  };

  const submit = useSubmit();
  const onSubmit = async (data) => {
    console.log("data", data);
    // console.log("errors in onsubmit", errors);

    // console.log("useCustStore.getState().tempUser", data);
    var formdata = {
      ...data,
      cust_id: useCustStore.getState().currentUser.cust_id,
      // id: state.data ? state.data.id : "",
    };

    console.log("fields in onsubmit", fields);

    for (var i = 0; i < fields.length; ++i) {
      if (fields[i].ui_data.type === "subtype") {
        delete formdata[fields[i].ui_data.colname];
      }
    }
    console.log("formdata", formdata);

    submit(formdata, { method: "POST" });

    //  myAxios.post(`/ui/addnuser`, useCustStore.getState().tempUser)

    //   // sendMail(cust);

    // navigate(-1);
  };

  const handleNext = async (e) => {
    // console.log("activestep", activeStep);
    if (activeStep >= 1) {
      // setPgWidth(steps[activeStep].pgWidth);
    }

    const isStepValid = await methods.trigger();

    // if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    navigate(-1);
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePwdClick = () => {
    navigate("/changepwd");
    // setOpen(true);
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    methods.reset({});

    // setActiveStep(0);
  };

  return (
    <StyledSection
      headerText={`${data3.title ?? data3.title}`}
      headerText2="Save/Back"
    >
      <Stack
        sx={{
          mt: -1.5,
          mb: 0,
          mx: 0,
          px: 2,
          // width: "100%",
          justifyContent: "space-between",
          // alignItems: "flex-end",
          bgcolor: "common.compbg",
        }}
        direction={{ xs: "row", sm: "row", md: "row" }}
      >
        <Stack
          sx={{
            m: 0,
            p: 0,
          }}
        >
          {result ? (
            <>
              <Box
                sx={{
                  // fontWeight: "bold",
                  // typography: "h6",
                  color:
                    result[0].statuscd === 0 ? "textPrimary" : "error.main",
                }}
              >
                {result[0].msg}
              </Box>
              {/* <StyledButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </StyledButton> */}
            </>
          ) : (
            <></>
          )}
          {/* {!data1.s */}
          {/* // ) : (
            // <> */}
          {/* {state.profile ? (
                <Typography
                  variant={"h5"}
                  sx={{
                    // color: "secondary.main",
                    color: "common.black",
                    fontWeight: "bold",
                    // p: 0.5,
                  }}
                >
                  Profile Page User: {state.data.id}
                </Typography>
              ) : (
                <Typography
                  variant={"h5"}
                  sx={{
                    // color: "secondary.main",
                    color: theme.palette.textPrimary,
                    fontWeight: "bold",
                    // p: 0.5,
                  }}
                >
                  Change Team User ID: {state.data.id}
                </Typography>
              )} */}
          {/* </> */}
          {/* // )} */}
        </Stack>
        <Stack
          sx={{
            m: 0,
            p: 0,
          }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="button group"
            sx={{
              // m: 0,
              // p: 0,
              // justifyContent: "center",
              // alignItems: "center",
              // border: 0.01,
              borderRadius: "10px 10px 10px 10px",
              // borderRadius: 50,
              borderColor: "common.combg",
              bgcolor: "common.compbg",
            }}
          >
            <StyledButton
              // disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </StyledButton>
            {/* {activeStep === steps.length - 1 ? ( */}
            <StyledButton
              // disabled={!accepted}
              onClick={handleSubmit(onSubmit, onErrors)}
            >
              Save
            </StyledButton>
            {/* ) : (
            <StyledButton
              disabled={activeStep === steps.length - 2 && !accepted}
              onClick={methods.handleSubmit(handleNext)}
            >
              Next
            </StyledButton>
          )} */}
          </ButtonGroup>
        </Stack>
      </Stack>
      <Divider
        sx={{
          color: "primary.main",
          mt: 0.5,
        }}
      />
      <FormProvider {...methods}>
        <form>
          <Stack
            useFlexGap
            sx={{
              // pl: 7,
              // m: 2,
              p: 2,
              // width: { xs: "100", sm: "60%" },
              // bgcolor: "primary.main",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              border: "0px",
              borderStyle: "solid",
              borderRadius: "10px 10px 10px 10px",
            }}
            // spacing={2}
            direction="row"
          >
            {/* {data2.pwdChg ? (
              <>
                <StyledButton onClick={handlePwdClick}>
                  Change Password
                </StyledButton>
              </>
            ) : (
              <></>
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{}}>
                <PassRecovery />
              </Box>
            </Modal> */}

            {fields.map((field1, index) => {
              // console.log("field1", field1);
              return (
                <>
                  <Stack
                    key={field1.id}
                    sx={{
                      width: "49%",
                      py: 1,
                      display:
                        field1?.ui_data?.type !== "subtype" &&
                        field1?.ui_data?.type !== "subtype_list"
                          ? "block"
                          : "none",
                    }}
                  >
                    <SignUpCusTxtFld
                      disabled={
                        data3.cmode === "edit"
                          ? field1.ui_data.editenabled === "N"
                          : field1.ui_data.addenabled === "N"
                      }
                      allprops={
                        // field1.ui_data.subtype_name
                        //   ? {
                        //       ...field1.ui_data,
                        //       colname: `${field1.ui_data.subtype_name}-${field1.ui_data.colname}`,
                        //     }
                        //   :
                        field1.ui_data.type === "subtype"
                          ? { ...field1.ui_data }
                          : { ...field1.ui_data, userules: "true" }
                      }
                      required={field1.ui_data.required === "Y" ? true : false}
                      onfieldChg={(dataq) => {}}
                      type={field1.ui_data.type}
                      label={field1.ui_data.collabel}
                      name={field1.ui_data.colname}
                      value={
                        data1
                          ? data1[0][field1.ui_data.colname]
                          : field1.ui_data.defaultValue
                      }
                      validationType={field1.ui_data.validationType}
                      menuprops={
                        field1.ui_data.type === "menu"
                          ? menuListValues[field1.ui_data.menulist_name]
                          : field1.ui_data.menuprops
                      }
                      yupVType={field1.ui_data.yupVType}
                      mask={field1.ui_data.mask}
                      placeholder={field1.ui_data.placeholder}
                      menuClickFunc={async (input) => {
                        // methods.reset({ test: [] });
                        delete fields[index].subtypeFields;
                        setStateFields([]);

                        console.log("data4", fields.length);
                        console.log("field1", fields, "index", index);

                        const res = await getFFs(field1.ui_data.subtype_name, {
                          i_page: "E",
                          i_entity_id: input,
                        });
                        console.log("res :: ", res);

                        if (res.data.length > 0) {
                          field1 = {
                            ...field1,
                            subtypeFields: res.data,
                          };
                          fields[index] = field1;
                          setStateFields(fields);

                          console.log("field1 post res", field1);
                          console.log("fields post res", fields);
                          // append({});
                        }
                      }}
                    />
                  </Stack>

                  {field1?.subtypeFields?.length > 0 &&
                  field1?.ui_data?.type !== "subtype_list" ? (
                    <Stack
                      // component={Paper}
                      // elevation={1}
                      sx={{
                        mx: 0,
                        bgcolor: "common.compbg",
                        p: 1,
                        border: 1,
                        borderColor: "common.secondary5",
                        // borderRadius: "20px 20px 20px 20px",
                        // px: 0.5,
                        // py: 1,
                        width: "100%",
                        // my: 1.5,
                      }}
                    >
                      <Box
                        sx={{
                          typography: "h7",
                          fontWeight: "bold",
                          color: "secondary.main",
                        }}
                      >
                        {field1.ui_data.introline}
                      </Box>
                      <Divider
                        sx={{
                          border: 0.2,
                          borderColor: "common.secondary5",
                          my: 1,
                        }}
                      />
                      <Stack
                        useFlexGap
                        sx={{
                          width: "100%",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                        direction="row"
                      >
                        {field1.subtypeFields.map((subfield, index) => {
                          return (
                            <Stack sx={{ width: "49%", mx: 0.25, my: 1 }}>
                              <SignUpCusTxtFld
                                disabled={
                                  data3.cmode === "edit"
                                    ? subfield.ui_data.editenabled === "N"
                                    : subfield.ui_data.addenabled === "N"
                                }
                                allprops={{
                                  ...subfield.ui_data,
                                  userules: "true",
                                }}
                                required={
                                  subfield.ui_data.required === "Y"
                                    ? true
                                    : false
                                }
                                onfieldChg={(dataq) => {}}
                                type={subfield.ui_data.type}
                                label={subfield.ui_data.collabel}
                                name={subfield.ui_data.colname}
                                value={
                                  data1
                                    ? data1[0][subfield.ui_data.colname]
                                    : subfield.ui_data.defaultValue
                                }
                                validationType={subfield.ui_data.validationType}
                                menuprops={
                                  subfield.ui_data.type === "menu"
                                    ? menuListValues[
                                        subfield.ui_data.menulist_name
                                      ]
                                    : subfield.ui_data.menuprops
                                }
                                yupVType={subfield.ui_data.yupVType}
                                mask={subfield.ui_data.mask}
                                placeholder={subfield.ui_data.placeholder}
                              />
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  {field1?.ui_data?.type === "subtype_list" ? (
                    <>
                      <Stack
                        // component={Paper}
                        // elevation={1}
                        sx={{
                          mx: 0,
                          bgcolor: "common.compbg",
                          p: 1,
                          border: 1,
                          borderColor: "common.secondary5",
                          // borderRadius: "20px 20px 20px 20px",
                          // px: 0.5,
                          // py: 1,
                          width: "100%",
                          // my: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            typography: "h7",
                            fontWeight: "bold",
                            color: "secondary.main",
                          }}
                        >
                          {field1.ui_data.introline}
                        </Box>
                        <Divider
                          sx={{
                            border: 0.2,
                            borderColor: "common.secondary5",
                            my: 1,
                          }}
                        />

                        <MaterialTableCard
                          // columns={}
                          data1={data1 ? data1 : null}
                          data2={{ data: field1.subtypeFields }}
                          data3={data3}
                          locData={state}
                        />
                      </Stack>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}

            {/* <Stack sx={{ m: 0, p: 0, width: "100%" }}>
              {data4 ? (
                <Box
                  sx={{
                    // border: 1,
                    p: 0.5,
                    fontWeight: "bold",
                    typography: "h7",
                    color: "secondary.main",
                  }}
                >
                  {data4.subtype_label}:
                </Box>
              ) : (
                <></>
              )}
              <Stack
                direction="row"
                sx={{
                  m: 0,
                  p: 0,
                  width: "100%",
                  // border: 1,
                }}
              > */}
            {/* {data4 ? (
                  <>
                    {data4.data.map((field, index) => (
                      <Stack
                        sx={{
                          my: 1,
                          p: 0.5,
                          // p: 0.5,
                          // pl: 7,
                          height: "100%",
                          width: field.ui_data.displaywidth
                            ? field.ui_data.displaywidth
                            : "100%",
                          // width: { xs: "100", sm: "50%" },
                          justifyContent: "flex-end",
                          alignItems: "flex-start",
                          // border: 1,
                        }}
                      >
                        <SignUpCusTxtFld
                          disabled={false}
                          allprops={
                            {
                              ...field.ui_data,
                              colname: `${data4.subtype_name}-${field.ui_data.colname}`,
                            }
                            // colname: "asset_props." + field.ui_data.colname,
                          }
                          required={
                            field.ui_data.required === "Y" ? true : false
                          }
                          type={field.ui_data.type}
                          label={field.ui_data.collabel}
                          name={field.ui_data.colname}
                          value={
                            data1
                              ? data1[0][
                                  `${data4.subtype_name}-${field.ui_data.colname}`
                                ]
                              : ""
                          }
                          validationType={field.ui_data.validationType}
                          menuprops={
                            // field.ui_data.type === "menu"
                            // ? menuListValues[data]
                            // :
                            field.ui_data.menuprops
                          }
                          yupVType={field.ui_data.yupVType}
                          mask={field.ui_data.mask}
                          placeholder={field.ui_data.placeholder}
                          onfieldChg={(dataq) => {
                            console.log(
                              "in onfieldchg call ",
                              field.ui_data.colname
                            );
                            addTempUserInfo(dat1a.ui_data.colname, dataq);
                            if (emailChk.verifyStatus === 1) {
                              console.log(
                                "verifyStatus before sendmail:",
                                emailChk.verifyStatus
                              );
                              // sendCode(cust);
                              console.log(
                                "verifyStatus after sendmail:",
                                emailChk.verifyStatus
                              );
                            }
                          }}
                        />
                      </Stack>
                    ))}
                  </>
                ) : (
                  <></>
                )} */}
            {/* </Stack> */}
            {/* </Stack> */}
            {/* {state.pathname === "/dashboard/cust_assets/create" ? (
              <> */}

            {/* </>
            ) : (
              <></> */}
            {/* )} */}
          </Stack>
        </form>
      </FormProvider>
    </StyledSection>
  );
}
