import { useCartStore } from "./zuCartStore";
import { useCustStore } from "./zuCustStore";
import myAxios from "../MyAxios";

// export const addUpdIpCookie2 = (colname, newValue) => {
//   useCartStore.setState((state) => {
//     const newCart = { ...state.cart[0], [colname]: newValue };
//     // console.log("newCart" + JSON.stringify(newCart));
//     state.cart[0] = newCart;
//   });
// };

// export const addUpdIpCookie = (colname, newValue) => {
//   const newCart = {...useCartStore.getState().cart[0], [colname]: newValue };
//   console.log("newCart in addUpdIpCookie:" + JSON.stringify (newCart));
//   // useCartStore.setState((state) => ({cart: newCart}));
//   useCartStore.setState((state) => {state.cart = newCart});
//   console.log("addUpdIpCookie state.cart:" + JSON.stringify (useCartStore.get().cart));
// };
// export const replaceCart = (ncart) => {
//   useCartStore.setState((state) => ({cart: ncart}));
//   // console.log("replaceCart:state.cart :" + JSON.stringify(state.cart));
//   // useCartStore.setState((state) => {
//   //   state.cart = ncart;
//   // });
// };
export const refreshCart = async (ncart) => {
  console.log("ncart in refreshCart:", ncart);
  const controller = new AbortController();
  try {
    const res = await myAxios.post(`/ui/updcart`, ncart, {
      signal: controller.signal,
    });

    console.log("res.data", res.data);

    if (res.status === 200) {
      const data = await res.data;
      console.log("refreshCart-res.data:", res.data);
      if (data && data.length > 0) {
        useCartStore.setState((state) => ({ cart: data }));
        useCustStore.setState((state) => ({
          currentUser: { ...state.currentUser, cust_id: data[0].cust_id },
        }));
        useCustStore.setState({ cust: { cust_id: data[0].cust_id } });
      }
      console.log("refreshed state.cart:", useCartStore.getState().cart);
    }
  } catch (err) {
    throw err;
  }
  controller.abort();
  // console.log("state.cart:" + JSON.stringify(state.cart));
};

export const setAccepted = (bool) => {
  useCartStore.setState((state) => ({ accepted: bool }));
};

export const addUpdCartItem = (item_id, newValue, refresh) => {
  console.log("addUpdCartItem:colname:" + item_id + ", newValue:" + newValue);
  console.log(useCartStore.getState().cart);
  const ncart = useCartStore.getState().cart.map((orderrow) => {
    console.log("orderrow", orderrow);
    if (orderrow.item_id === item_id) {
      console.log(
        "orderrow.item_id:" +
          orderrow.item_id +
          ", orderrow.quantity:" +
          orderrow.quantity +
          ", newValue:" +
          newValue
      );
      return { ...orderrow, quantity: newValue };
    } else {
      return orderrow;
    }
  });
  console.log("ncart in addupdCartItem:", ncart);
  console.log("new state val", useCartStore.getState().cart);

  refreshCart(ncart);

  // console.log("refreshed cart in addUpdCartItem:" + JSON.stringify (useCartStore.getState().cart));

  // useCartStore.setState(
  //   (state) => {
  //     const ncart = state.cart.map((orderrow) => {
  //       if (orderrow.item_id === item_id) {
  //         // console.log(
  //         //   "orderrow.item_id:" +
  //         //     orderrow.item_id +
  //         //     ", orderrow.quantity:" +
  //         //     orderrow.quantity
  //         // );
  //         return { ...orderrow, quantity: newValue };
  //       } else {
  //         return orderrow;
  //       }
  //     });
  //     // console.log("ncart in addupdCartItem:" + JSON.stringify(ncart));
  //     refreshCart(ncart);
  //     // console.log(
  //     // "state.cart in addupdCartItem:" + JSON.stringify(state.cart)
  //     // );
  //     // set({ cart: ncart });
  //     // state.cart = ncart;
  //   }
  //   // console.log("state.cart in addupdCartItem:" + JSON.stringify(cart));
  // );
};
