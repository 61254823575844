import React from "react";
import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Timeline from "./Timeline";
import StyledHeader from "../shared/StyledHeader";
import StyledSection from "../shared/StyledSection";
import { useOverviewStore } from "../store/zustandOverviewStore";
import { useLoaderData } from "react-router-dom";
import myAxios from "../MyAxios";
// import Lottie from "lottie-react";
// import Lottie from "react-lottie-light";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import Lottie from "@rookino/react-lottie-light";
import animation from "./z2egOhedJy.json";

export function HowItWorks(props) {
  const data1 = useLoaderData();
  const faq = useOverviewStore((state) => state.faqs);
  // console.log("faq", faq);
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,

    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice",
    // },
  };
  const theme = useTheme();
  // const AccordionStyle = {
  //   p: 0,
  //   mx: 0,
  //   my: 0.2,
  //   py: 0,
  //   bgcolor: "secondary.main",
  //   borderRadius: "10px 10px 10px 10px",
  //   border: 1,
  // };
  const AccordionStyle = {
    p: 0,
    mx: 0,
    my: 0.2,
    py: 0.5,
    bgcolor: "primary.main",
    borderRadius: "10px 10px 10px 10px",
  };
  const AccordionSumStyle = {
    mx: 0,
    my: 0,
    px: 1,
    py: 0,
    flexDirection: "row",
    bgcolor: "primary.main",
    "& .MuiAccordionSummary-content": {
      mx: 0,
      my: 0,
      px: 1,
      py: 0,
    },
  };
  const SumText = {
    typography: "h5",
    fontWeight: "medium",
    color: "textSecondary",
  };
  const AccordionDtlStyle = {
    my: 0,
    px: 3,
    py: 0,
    bgcolor: "primary.main",
  };
  const DtlText = {
    typography: "h6",
    fontWeight: "light",
    color: "textPrimary",
  };
  return (
    <Stack direction={{ xs: "column", sm: "column", md: "column" }}>
      {/* <StyledHeader text="SignUp + 48 Hrs >>> Onboard " bgcolor="common.compbg" /> */}
      {/* <StyledSection headerText="How it Works" bgcolor="common.compbg"> */}
      <StyledSection
        headerText="SignUp + 48 Hrs  >>>  Onboard"
        bgcolor="common.compbg"
      >
        {/* <Stack
            sx={{
              mx: 0,
              p: 0,
              width: "100%",
              bgcolor: "common.compbg",
            }}
            direction="column"
          > */}
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          sx={{ alignItems: "center", justifyContent: "center", m: 0, p: 0 }}
        >
          {/* <Stack
              sx={{
                m: 0,
                p: 0,
                justifyContent: "center",
                alignItems: "space-between",
              }}
              direction={{ xs: "column", sm: "column", md: "row" }}
            > */}
          <Stack
            direction="row"
            sx={{
              // width: "45%",
              // bgcolor: "common.compbg",
              m: 0,
              p: 0,
              // alignItems: "center",
              // justifyContent: "center",
              // width: { xs: "200", sm: "400" },
              // height: { xs: "200", sm: "400" },
            }}
          >
            {/* <Lottie data={animation} loop={true} /> */}
            <Lottie
              options={lottieOptions}
              // height={300}
              // width={300}
              // height={useMediaQuery(theme.breakpoints.up("xs")) ? "200" : "300"}
              // width={useMediaQuery(theme.breakpoints.up("xs")) ? "200" : "300"}
              // isStopped={true}
              // isPaused={false}
            />
          </Stack>
          <Stack>
            <Timeline />
          </Stack>
        </Stack>
        {/* </Stack> */}
        {/* </Stack> */}
      </StyledSection>
      <StyledSection
        headerText="Common Questions Answered"
        // bgcolor="common.compbg"
      >
        {/* <Stack
          sx={{
            mx: 1,
            my: 0.5,
            p: 0,
            // bgcolor: "primary.main",
          }}
          direction="column"
        > */}
        {data1.map((q, j) => (
          //   <Accordion
          //   square="false"
          //   sx={{
          //     ...AccordionStyle,
          //   }}
          //   key={i2 * 100 + k2}
          //   elevation={0}
          //   disableGutters
          //   TransitionProps={{ unmountOnExit: true }}
          // >
          <Accordion
            square="false"
            sx={{
              ...AccordionStyle,
              // bgcolor: "secondary.main",
              // borderRadius: "10px 10px 10px 10px",
            }}
            key={j}
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownCircleOutlinedIcon color="secondary" />}
              aria-controls="panel1c-content"
              id="panel1c-header"
              sx={{
                ...AccordionSumStyle,
              }}
            >
              <Box
                sx={{
                  ...SumText,
                }}
              >
                {q.uidata.header}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                ...AccordionDtlStyle,
              }}
            >
              <Box
                sx={{
                  ...DtlText,
                }}
              >
                {q.uidata.line}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
        {/* </Stack> */}
      </StyledSection>
    </Stack>
  );
}

export async function HIWLoader() {
  try {
    const res = await myAxios.get(`/ui/faqs`, {
      // signal: controller.signal,
    });

    if (res.status === 200) {
      const data = await res.data;
      if (data && data.length > 0) {
        // updateFAQS(data);
        return data;
        setAlertopen(true);
      } else {
        setAlertopen(false);
      }
    }
  } catch (err) {
    throw err;
  }
}
// export default HowItWorks;
