import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import styled from "@mui/material/styles/styled";
import { Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import { useOverviewStore } from "../store/zustandOverviewStore";
import { useWindowWidth } from "@react-hook/window-size";
import CardActions from "@mui/material/CardActions";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import open from "../pictures/open.png";
import { useCartStore } from "../store/zuCartStore";
// import { FormProvider, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { SignUpCusTxtFld } from "./SignUpCusTxtFld";
import { addUpdCartItem } from "../store/zuCartActions";
import { Link } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import StyledButton from "../shared/StyledButton";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";

import StraightSharpIcon from "@mui/icons-material/StraightSharp";

import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import IconButton from "@mui/material/IconButton";
import ItemCard from "./ItemCard";
function TabPanel(props) {
  const { classes, children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalAddons(props) {
  const [added, setAdded] = React.useState(false);

  // useEffect(() => {
  //   setAdded(false);
  // }, []);

  const { classes, width } = props;
  const slides = props.slides;
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  // const methods = useForm({
  //   shouldUnregister: false,
  //   mode: "onBlur",
  // });

  const AccordionStyle = {
    m: 0,
    p: 0,
    bgcolor: "primary.main",
    borderRadius: "10px 10px 10px 10px",
  };
  const AccordionSumStyle = {
    mx: 0,
    my: 0,
    px: 0.2,
    py: 0,

    flexDirection: "row",
    "& .MuiAccordionSummary-content": {
      mx: 0,
      my: 0,
      px: 0.2,
      py: 0,
    },
  };
  const SumText = {
    typography: "h5",
    color: "textSecondary",
    fontWeight: "medium",
    textAlign: "left",
  };
  const AccordionDtlStyle = {
    m: 0,
    px: 0.2,
    py: 0,
  };
  const DtlText = {
    color: "textPrimary",
    typography: "h6",
    fontWeight: "light",
    textAlign: "left",
  };

  const handleChange = (event, newValue) => {
    // console.log("value in handlechange:", value);
    // console.log("newValue:", newValue);
    setValue(newValue);
    setAdded(false);
  };
  const handleIndexChange = (index) => {
    // console.log("value in handlechange:", value);
    // console.log("index:", index);
    setValue(index);
    setAdded(false);
  };
  // const slides = useOverviewStore((state) => state.slides);
  const wwidth = useWindowWidth();

  const addons = slides.filter(
    (feature) => feature.ui_data.pricetype.toLowerCase() == "addon"
  );

  console.log("VerticalAddOns::addons:", addons);
  const [expanded, setExpanded] = React.useState(false);

  const cart = useCartStore((state) => state.cart);
  const useScroll = () => {
    const aoRef = useRef(null);
    const executeScrollAO = () =>
      aoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

    return [executeScrollAO, aoRef];
  };
  // console.log(cart);

  const [executeScrollAO, aoRef] = useScroll();
  // console.log("value:", value);
  const handleExpandClick = () => {
    if (expanded) {
      executeScrollAO();
    }
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    // return <> {...other}</>;
  })(({ theme, expand }) => ({
    // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    // transform: !expand ? "`${...other}` +" : "`${...other}` -",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const StyledIconButton = styled((props) => {
    const { slotProps, ...other } = props;
    return <IconButton {...other} />;
    // return <> {...other}</>;
  });

  // const StyledIconButton = styled(IconButton)`
  //   left: ${(props) => (props.isLeft ? "0" : "none")};
  //   right: ${(props) => (props.isLeft ? "none" : "0")};

  //   height: 32px;
  //   width: 32px;
  //   position: absolute;
  //   border-radius: 16px;
  //   border: 1px solid gray;
  //   //top: 33%;
  //   background-color: white;
  //   color: rgba(0, 83, 229, 1);
  //   border-color: rgba(0, 83, 229, 0.12);

  //   z-index: 1;
  //   opacity: 1;
  //   margin: 20px;

  //   :hover {
  //     box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  //       0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  //     border-color: white;
  //     background-color: inherit;
  //   }
  // `;

  return (
    <>
      <div ref={aoRef}></div>
      {/* <Stack
        sx={{
          bgcolor: "common.compbg",
          alignItems: "center",
          border: 1,
        }}
      > */}
      {/* <Stack
        direction="column"
        sx={{
          bgcolor: "common.compbg",
          // display: "flex",
          // width: "100%",
          border: 1,
          m: 0,
          p: 0,
          alignItems: "center",
          justifyContent: "center",
          // borderRadius: "20px 20px 20px 20px",
        }}
      > */}
      <Stack
        // useFlexGap
        // flexWrap="wrap"
        direction="row"
        sx={{
          // border: 1,
          m: 0,
          p: 0,
          alignItems: "center",
          // borderRadius: "20px 20px 20px 20px",
          justifyContent: "center",
          // bgcolor: "common.compbg",
        }}
      >
        <Tabs
          id="back-to-top-anchor"
          // selectionFollowsFocus
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          // variant="fullWidth"
          // orientation="vertical"
          scrollButtons="auto"
          // scrollButtons
          allowScrollButtonsMobile
          ScrollButtonComponent={(props) => {
            // console.log("ScrollButtonComponent:props", props);
            // slopProps cannot be passed to IconButton, pass rest of props
            const { slotProps, ...newprops } = props;
            // console.log("ScrollButtonComponent:newprops", newprops);
            return (
              <>
                {props.direction == "right" && !props.disabled ? (
                  <IconButton
                    {...newprops}
                    sx={{
                      // mt: -1.5,
                      ":hover": {
                        bgcolor: "common.compbg",
                      },
                      ".MuiTouchRipple-ripple .MuiTouchRipple-child": {
                        borderRadius: 0,
                        bgcolor: "common.compbg",
                      },
                    }}
                  >
                    {/* <ArrowForwardIosIcon */}
                    <ArrowCircleRightOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: props.disabled ? "disabled" : "secondary.main",
                      }}
                    />
                  </IconButton>
                ) : props.direction == "left" && !props.disabled ? (
                  <IconButton
                    {...newprops}
                    sx={{
                      //   m: 0,
                      //   p: 0,
                      // mt: -1.5,
                      ":hover": {
                        bgcolor: "common.compbg",
                      },
                      ".MuiTouchRipple-ripple .MuiTouchRipple-child": {
                        borderRadius: 0,
                        bgcolor: "common.compbg",
                      },
                    }}
                  >
                    {/* <ArrowBackIosNewOutlinedIcon */}
                    <ArrowCircleLeftOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: props.disabled ? "disabled" : "secondary.main",
                      }}
                    />
                  </IconButton>
                ) : (
                  <></>
                )}
              </>
            );
          }}
          sx={{
            minHeight: "35px",
            height: "35px",
            "& .MuiTabs-scroller": {
              alignItems: "center",
              justifyContent: "center",
            },
            "& .MuiTab-root": {
              // height: "40px",
              // alignItems: "center",
              // justifyContent: "center",
            },
            "& .MuiButtonBase-root.MuiTab-root": {
              color: "primary.main",
              // bgcolor: "secondary.main",
              fontSize: { xs: "h6.fontSize", sm: "h6.fontSize" },
              fontWeight: "bold",
              minHeight: "35px",
              height: "35px",
              textTransform: "none",
              alignItems: "center",
              justifyContent: "center",
              // px: { xs: 1, sm: 1 },
              m: 0,
              p: 0,
              mr: { xs: 0.2, sm: 0.2 },
              my: 0,
              px: { xs: 1, sm: 1 },
              // py: { xs: 0.5, sm: 0.2 },

              // borderRadius: "20px 20px 0px 20px",
              // borderColor: "primary.main",
              // borderBottomColor: "common.compbg",
              // borderColor: "common.compbg",
              borderRadius: "20px 20px 20px 20px",
            },
            // "& .MuiButtonBase-root.MuiTab-root:hover": {
            "& .MuiButtonBase-root:hover": {
              bgcolor: "common.secondary7",
              // color: "secondary.main",
              // color: "common.compbg",
              // borderBottom: 0,
              // border: 1,
              borderColor: "primary.main",
            },
            "& .MuiButtonBase-root.Mui-selected": {
              bgcolor: "common.compbg",
              color: "secondary.main",
              border: 1,
              borderWidth: "3px",
              // borderBottom: 1,
              borderColor: "primary.main",
              borderBottomColor: "common.compbg",
              borderRadius: "20px 20px 20px 0px",
              boxShadow: 3,
            },
          }}
          TabIndicatorProps={{
            // display: "none",
            sx: { bgcolor: "common.compbg" },
          }}
        >
          {addons.map(
            (addon, index) =>
              addon.ui_data.pricetype != "Included" && (
                <Tooltip
                  followCursor
                  key={index}
                  title={addon.ui_data.introline}
                  sx={{ backgroundColor: "primary.main" }}
                >
                  <Tab
                    sx={{
                      bgcolor: "secondary.main",
                    }}
                    aria-label={addon.ui_data.introline}
                    label={
                      // wwidth <= theme.breakpoints.values["md"]
                      // ? ""
                      // :
                      addon.ui_data.introline
                    }
                    // icon={
                    //   wwidth <= theme.breakpoints.values["md"] ? (
                    //     <AddIcon />
                    //   ) : (
                    //     <></>
                    //   )
                    // }
                    {...a11yProps(index)}
                  />
                </Tooltip>
              )
          )}
        </Tabs>
      </Stack>
      <SwipeableViews
        // axis={theme.direction === "ltr" ? "x-reverse" : "x"}
        onChangeIndex={handleIndexChange}
        index={value}
      >
        {addons.map(
          (addon, index) =>
            addon.ui_data.pricetype.toLowerCase() == "addon" && (
              <TabPanel
                key={index}
                sx={{
                  p: 0,
                  m: 0,
                  // m: 0.5,
                  // border: 1,
                  // p: 0,

                  // width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 1,
                  // "& .MuiBox-root": {
                  //   p: 0,
                  //   m: 0,
                  //   width: "100%",
                  //   // border: 1,
                  // },
                }}
                // classes={classes}
                value={value}
                index={index}
              >
                <ItemCard
                  addon={addon}
                  cart={cart}
                  index={index}
                  scrollInViewFn={executeScrollAO}
                  mode="W"
                />
              </TabPanel>
            )
        )}
      </SwipeableViews>
      {/* </Stack> */}
      {/* </Stack> */}
    </>
  );
}

VerticalAddons.propTypes = {
  //   classes: PropTypes.any.isRequired,
};

export default VerticalAddons;
