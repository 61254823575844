import React from "react";
import { SignUpCusTxtFld } from "./SignUpCusTxtFld";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import { useCartStore } from "../store/zuCartStore";
import { addUpdCartItem } from "../store/zuCartActions";
import Box from "@mui/material/Box";
import { useOverviewStore } from "../store/zustandOverviewStore";
import { useRouteLoaderData } from "react-router-dom";
import { VariableSizeList as VList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export default function FSignUpStep1(props) {
  const { currStep } = props;

  const { data2, data3 } = useRouteLoaderData("beast");
  const slides = data3.data;

  const cart = useCartStore((state) => state.cart);
  // console.log("cart", cart);

  console.log("currentStep", currStep);

  var currentPage = [];
  if (currStep == 1) {
    // console.log("in if case");
    // console.log(cart.filter((page) => page.item_id == 3));
    currentPage = cart.filter((page) => page.ui_data.formpage == 1);
  } else {
    currentPage = cart.filter((page) => page.ui_data.formpage > 1);
  }
  // const currentPage = cart.filter(
  //   (page) => parseInt(page.form_pg_num) === currStep
  // );

  const DtlText = {
    typography: "h6",
    fontWeight: "light",
    textAlign: "left",
    // pl: 2.5,
    color: "textPrimary",
  };
  console.log("currentPage", currentPage);
  return (
    <Stack
      sx={{
        m: 0,
        p: 1,
        // m: 1,
        // p: 1,
        width: "100%",
        // justifyContent: "flex-start",
        // alignItems: "flex-start",
      }}
      direction={"column"}
    >
      {/* <AutoSizer>
        {({ height, width }) => (
          <VList
            border={1}
            height={500}
            itemCount={currentPage.length}
            itemSize={() => 100}
            width={500}
            // itemData={items}
          > */}
      {currentPage.map((field, j) => (
        <React.Fragment key={currStep * 10 + j}>
          {/* Really important to keep key unique accross all fields on all pages */}
          <Stack
            sx={{
              p: 1.5,
              m: 0.2,
              // p: 0,
              // width: "100%",
              border: "0.0px",
              borderStyle: "solid",
              borderRadius: "10px 10px 10px 10px",
              bgcolor: "primary.main",
            }}
            direction={"column"}
          >
            {/* Row 1 - Label + Field */}
            <Stack
              sx={{
                m: 0.5,
                p: 0,
                // width: "100%",
                // bgcolor: "common.compbg",
                // border: 1,
                // bgcolor: "primary.main",
                // justifyContent: "space-between",
                // alignItems: "center",
              }}
              // direction={"row"}
              direction={{ xs: "column", sm: "row" }}
            >
              {/* Label */}
              <Stack
                sx={{
                  m: 0,
                  p: 0,
                  // width: "60%",
                  width: { xs: "100%", sm: "60%" },
                  alignItems: { xs: "center", sm: "flex-start" },
                  mb: { xs: 0.5, sm: 0 },
                  // border: 1,
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    color: "textSecondary",
                    typography: "h5",
                    fontWeight: "bold",
                  }}
                >
                  {field.ui_data.introline}
                </Box>
              </Stack>
              {/* Field */}
              <Stack
                key={j}
                sx={{
                  // width: "40%",
                  width: { xs: "100%", sm: "40%" },
                  alignItems: { xs: "center", sm: "flex-end" },
                  m: 0,
                  p: 0,
                  // border: 1,
                  justifyContent: "flex-start",
                  // alignItems: "flex-end",
                }}
              >
                <SignUpCusTxtFld
                  allprops={field.ui_data}
                  required={field.ui_data.required == "Y" ? true : false}
                  item_id={field.item_id}
                  type={field.ui_data.type}
                  label={field.ui_data.collabel}
                  name={field.ui_data.colname}
                  validationType={field.ui_data.validationType}
                  mask={field.ui_data.mask}
                  placeholder={field.ui_data.placeholder}
                  inputWidth={field.ui_data.inputWidth}
                  // helpertext={field.ui_data.introline}
                  value={field.quantity}
                  onfieldChg={(data) => {
                    addUpdCartItem(field.item_id, data, true);
                    console.log("field.item_id:", field.item_id);
                    console.log("data:", data);
                  }}
                />
              </Stack>
            </Stack>
            {/* Row 2 - Detail Expansion */}
            <Stack
              sx={{
                mx: 0,
                mt: { xs: -0.5, sm: -0.5 },
                mb: 0,
                p: 0,
                // width: "100%",
                // bgcolor: "common.compbg",
                bgcolor: "transparent",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                  // m: -1.5,
                  mx: 0,
                  my: 0,
                  mb: -1.4,
                  p: 0,
                  backgroundColor: "transparent",
                  // bgcolor: "primary.main",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownCircleOutlinedIcon
                      // color="secondary"
                      sx={{
                        // bgcolor: "common.black",
                        // borderRadius: "50%",
                        color: "secondary.main",
                      }}
                    />
                  }
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                  sx={{
                    flexDirection: "row-reverse",
                    m: 0,
                    my: 0,
                    py: 0,
                    p: 0,
                    // bgcolor: "primary.main",
                  }}
                >
                  <Typography
                    variant={"h6"}
                    sx={{
                      // color: "secondary.main",
                      color: "textSecondary",
                      fontWeight: "medium",
                      pl: 2,
                      pr: 0,
                      m: 0,
                    }}
                  >
                    {/* (${field.price}.00/{field.ui_data.content.unit}/
                    {field.ui_data.content.freq}), {field.ui_data.content.desc} */}
                    ({field.price_curr}/
                    {field.disp_props ? field.disp_props.unit : ""}/
                    {field.disp_props ? field.disp_props.freq : ""}),{" "}
                    {field.disp_props ? field.disp_props.desc : ""}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    mx: 0,
                    my: 0,
                    p: 0,
                    // bgcolor: "primary.main",
                  }}
                >
                  <List
                    dense={true}
                    sx={{
                      listStyleType: "disc",
                      m: 0,
                      p: 0,
                      // pl: 2.5,
                      ml: 2.5,
                    }}
                  >
                    {slides
                      .filter((slide) => slide.item_id === field.item_id)
                      .map((slide, index) => (
                        <Stack key={index} direction="row">
                          <Stack>
                            {/* <Box
                              sx={{
                                color: "secondary.main",
                                fontWeight: "bold",
                                typography: "h6",
                                m: 0,
                                p: 0,
                                ml: -2.5,
                              }}
                            >
                              {slide.ui_data.introline}
                            </Box> */}

                            <ListItem
                              sx={{
                                ...DtlText,

                                // color: "common.black ",
                                // p: 0.5,
                                display: "list-item",
                              }}
                            >
                              {slide.ui_data.content.desc}
                            </ListItem>
                          </Stack>
                        </Stack>

                        // <ListItemText
                        //   sx={{
                        //     m: 0,
                        //     p: 0,
                        //     alignItems: "left",
                        //   }}
                        //   primary=
                        //   primaryTypographyProps={{
                        //     color: "secondary.main",
                        //     variant: "caption",
                        //     fontWeight: "medium",
                        //   }}
                        // />
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Stack>
          {/* <Divider sx={{ my: 2, width: "100%" }} /> */}
        </React.Fragment>
      ))}
      {/* </VList>
        )}
      </AutoSizer> */}
    </Stack>
  );
}
